import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Tooltip } from '@mui/material';
import Tab from '@mui/material/Tab/Tab';
import { useTheme as useMuiTheme } from '@mui/material/styles';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useHistory } from 'react-router-dom';
import AreaSelection from '../../@types/AreaSelection';
import PointClassStyles from '../../@types/PointClassStyles';
import Tree from '../../@types/Tree';
import { Finished, ProgressControls } from '../../components';
import ArchiveTreeModal from '../../components/ArchiveTreeModal/ArchiveTreeModal';
import Attributes from '../../components/Icon/Attributes';
import TabPanel from '../../components/Microclimate/Attributes/TabPanel';
import AttributesTab from '../../components/SemanticsValidation/AttributesTab';
import BadSegmentationModal from '../../components/SemanticsValidation/BadSegmentationModal/BadSegmentationModal';
import CanopyCard from '../../components/SemanticsValidation/Cards/CanopyCard';
import CrownHeightCard from '../../components/SemanticsValidation/Cards/CrownHeightCard';
import FirstBifurcationCard from '../../components/SemanticsValidation/Cards/FirstBifurcationCard';
import FirstBifurcationMidpointCard from '../../components/SemanticsValidation/Cards/FirstBifurcationMidpointCard';
import TreeHeightCard from '../../components/SemanticsValidation/Cards/TreeHeightCard';
import { DEFAULT_ELLIPSE } from '../../components/SemanticsValidation/Cards/TrunkDetailsCard/Constants';
import TrunkDetailsCard from '../../components/SemanticsValidation/Cards/TrunkDetailsCard/TrunkDetailsCard';
import ViewGrid from '../../components/SemanticsValidation/Views/ViewGrid';
import ViewWrapper from '../../components/SemanticsValidation/Views/ViewWrapper';
import useLoadLaz from '../../components/SemanticsValidation/hooks/useLoadLaz';
import usePanorama from '../../components/SemanticsValidation/hooks/usePanorama';
import useTreeActions from '../../components/SemanticsValidation/hooks/useTreeActions';
import useValidationUndo from '../../components/SemanticsValidation/hooks/useValidationUndo';
import { Button } from '../../components/inputs';
import IconButton from '../../components/inputs/IconButton';
import { AlertModal } from '../../components/modals';
import { validationActions } from '../../core/progressActions';
import { PointCloudViewType } from '../../enums/PointCloudViewType';
import TreeFlowStatus from '../../enums/TreeFlowStatus';
import TreeStatus from '../../enums/TreeStatus';
import { useBetaTree } from '../../hooks/betaHooks/beta-use-tree';
import { useBetaTreeIndex } from '../../hooks/betaHooks/beta-use-tree-index';
import { default as LoaderWrapper } from '../../layout/Loader';
import HOTKEYS from '../../providers/hotkeys';
import { useModal } from '../../providers/modal';
import useStore from '../../store/useStore';
import { useBetaManagedAreaContext } from '../../hooks/betaHooks/beta-managed-area-context';

export interface AttributeCardAlert {
  name: string;
  message: string;
}

export const getDefaultGirth = () => {
  return {
    local_id: Date.now().toString(36) + Math.random().toString(36).substring(2),
    ...DEFAULT_ELLIPSE,
  };
};

const attributesTabIndex = 0;

const a11yProps = (name: string, index: number) => {
  return {
    id: `${name}-tab-${index}`,
    'aria-controls': `${name}-tabpanel-${index}`,
  };
};

const SemanticValidation = ({
  selection,
  setCurrentTreeId,
  startPostValidation,
  pendingUpdateTree,
  loaded,
  match,
  contextsLoadingState,
  treeId,
  trees,
  managedArea,
  reloadTrees,
  reFetchManagedAreaAndTrees,
}: {
  match: any;
  trees: Tree[];
  loaded: boolean;
  pendingUpdateTree: boolean;
  selection: AreaSelection[];
  setCurrentTreeId: (id: string) => void;
  startPostValidation: ({ code, id }: { code: string; id: string }, step: string) => void;
  contextsLoadingState: boolean;
  treeId: string;
  managedArea: any;
  reloadTrees: () => Promise<any[] | undefined>;
  reFetchManagedAreaAndTrees: () => Promise<
    | {
      trees: any[];
      managedArea: any;
      pipeline: any;
    }
    | undefined
  >;
}) => {
  const {
    tree: cTree,
    loading: btLoading,
    fetch: reloadTree,
    updateTree,
    updateTreeWithId,
    syncTrunks,
    addComment,
    removeComment,
    setTMSCategory,
  } = useBetaTree({
    treeId: match.params?.tree,
    managedAreaId: managedArea?.id,
    managedAreaCode: managedArea?.code,
  });
  const currentTree = cTree;

  const betaManagedAreaContext = useBetaManagedAreaContext();

  const muiTheme = useMuiTheme();
  const pointCloud = useStore((s) => s.pointCloud.laz);
  const { tree, actions: treeAction } = useStore((s) => s.tree);
  const toasts = useStore((s) => s.toast.toasts);
  const history = useHistory();
  const [finished, setFinished] = useState(false);

  const { areAllActionsCompleted } = useStore((s) => s.validation);
  const { setAllActionsTo, setActionCompleted } = useStore((s) => s.validation.actions);
  const activeTool = useStore((s) => s.actions.activeTool);
  const [isMaFinished, setIsMaFinished] = useState(false);

  const style = useStore((s) => s.pointCloud.pointClassStyles);
  const setClassColor = useStore((s) => s.pointCloud.actions.setClassColor);

  const [configItems, setConfigItems] = useState<{ [key: string]: string }>({
    canopy: style.canopy.color,
    trunk: style.trunk.color,
    environment: style.environment.color,
    otherCanopy: style.otherCanopy.color,
    otherTrunk: style.otherTrunk.color,
  });

  const [numberOfStems, setNumberOfStems] = useState<number | null>(null);

  const toDos = useMemo(
    () =>
      trees
        .filter((tree) => tree.tree_flow_status === TreeFlowStatus.MeasurementValidationQueued)
        .map((t, index) => ({ ...t, index })),
    [trees]
  );

  const { setCurrentIndex, moveToNeighbour, selectTree } = useBetaTreeIndex({
    trees,
    selectedTreeId: treeId,
    todoStatuses: [TreeFlowStatus.MeasurementValidationQueued],
    setTree: (tree) => {
      history.push(`/validation/${managedArea?.id}/semantics/${(tree as any)?.id}`);
    },
  });

  useEffect(() => {
    if (!cTree) return;

    treeAction.setTree({
      ...cTree,
      location: JSON.parse(cTree?.location as any),
    } as Tree);
  }, [treeAction, cTree?.id]);

  useLoadLaz(setCurrentTreeId, managedArea?.code);
  usePanorama();

  const { onAction } = useValidationUndo(async (data) => {
    // await updateTree(data);
    // await betaManagedAreaContext.fetchTrees();
    // await reloadTree();
    // TODO -> Kideríteni hogy egyáltalán használja e ezt bármi
  });

  const isLoading = () => {
    return (!pointCloud || !currentTree?.location || btLoading || contextsLoadingState) && !isMaFinished;
  };

  useEffect(() => {
    if (isLoading()) return;

    const configItems = localStorage.getItem('pointcloud-colors');

    if (configItems) {
      const parsedConfigItems = JSON.parse(configItems);
      setConfigItems((prev) => ({ ...prev, ...parsedConfigItems }));
      const items = Object.entries(parsedConfigItems);

      !!pointCloud?.geometry && items.forEach((item) => setClassColor(item[0] as keyof PointClassStyles, item[1] as string));
    }
  }, [pointCloud?.geometry]);

  const { handleResume, handleComplete, handleSkipAndRemove, handleBadSegmentation } = useTreeActions(async (id, data) => {
    if (data.trunks?.length) {
      await syncTrunks(data.trunks || []);
    }

    delete data.trunks;
    await updateTree(data);

    await betaManagedAreaContext.fetchTrees();
    await reloadTree();
  }, onAction);

  const _handleComment = async (tree: any, comment: any) => {
    await addComment({
      comment: comment.value,
      comment_type: comment.comment_type,
      isUndoAction: false,
    });
    await reloadTree();
  };

  const _removeComment = async (commentId: string) => {
    await removeComment(commentId);
    await reloadTree();
  };

  const _handleTSEJob = () => {
    startPostValidation(selection[0], 'semantic_validation');
    setFinished(true);
  };
  const [isBadSegmentationModalVisible, setIsBadSegmentationModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [alerts, setAlerts] = useState<AttributeCardAlert[]>([]);
  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);

  const areAttributesCompleted = areAllActionsCompleted();

  useHotkeys(
    HOTKEYS.semantics.MARK_ATTRIBUTE_COMPLETE,
    () => {
      activeTool && setActionCompleted(activeTool);
    },
    [activeTool]
  );

  const setTreeStatus = useCallback(async () => {
    if (!tree?.id) return;

    if (tree?.tree_flow_status === TreeFlowStatus.MeasurementValidationDone) {
      await handleResume(tree);
    } else {
      await handleComplete(tree);
      await updateTree({ number_of_stems: numberOfStems });

      const nextTree = toDos.find(
        (t) => t.tree_flow_status === TreeFlowStatus.MeasurementValidationQueued && String(t.id) !== String(tree.id)
      );

      if (!nextTree) {
        setIsMaFinished(true);
      } else {
        history.push(`/validation/${managedArea?.id}/semantics/${nextTree?.id}`);
      }
    }
  }, [handleComplete, handleResume, history, tree?.id, trees, toDos, managedArea]);

  const handleApproveAll = () => setAllActionsTo(true);
  const handleDeselectAll = () => setAllActionsTo(null);

  const handleCompleteClick = () => {
    if (alerts.length > 0) {
      setAlertModalIsOpen(true);
      return;
    }

    setTreeStatus();
  };

  const renderMemoizedButton = useMemo(() => {
    return tree?.tree_flow_status === TreeFlowStatus.MeasurementValidationDone ? (
      <Button
        label={'Resume Editing'}
        onClick={setTreeStatus}
        style={{ backgroundColor: '#6B6B6B', padding: '4px 29px', width: 'fit-content' }}
        leadingIcon={'resume'}
      />
    ) : areAttributesCompleted && !!currentTree?.tms_category ? (
      <Button
        label={'Mark Complete'}
        onClick={handleCompleteClick}
        style={{ backgroundColor: '#058B62', padding: '4px 29px', width: 'fit-content' }}
        leadingIcon={'checkmark'}
      />
    ) : (
      <Button
        label={'Incomplete'}
        onClick={() => { }}
        style={{ backgroundColor: '#DB200B', padding: '4px 29px', width: 'fit-content', cursor: 'not-allowed' }}
        leadingIcon={'exclamation'}
      />
    );
  }, [areAttributesCompleted, setTreeStatus, tree?.tree_flow_status]);

  // Getting modal methods
  const { presentModal, dismissModal } = useModal();

  const defaultTrunk = {
    tree_id: tree?.id,
    local_id: Date.now().toString(36) + Math.random().toString(36).substring(2),
    girths: [getDefaultGirth()],
  };

  const handleCompletedSemanticTrees = useCallback(async () => {
    if (toDos.length > 0) return null;
  
    await betaManagedAreaContext.moveTreesFromSemanticToMicroclimate(managedArea?.code)
  }, [toDos]);

  return (
    <LoaderWrapper loading={!!pendingUpdateTree || !currentTree?.id || isLoading()}>
      <Finished
        finished={toDos?.length === 0}
        onStart={_handleTSEJob}
        done={finished}
        managedAreaId={managedArea?.id}
        reloadManagedAreas={reFetchManagedAreaAndTrees}
        handleCompletedSemanticTrees={handleCompletedSemanticTrees}
      >
        {Boolean(toasts.length) && (
          <div className='toast-wrapper left'>
            {toasts.map((toast, index) => (
              <div key={index} className={`toast ${toast.type} ${toasts.length - 1 === index ? 'last' : ''}`}>
                {toast.message}
              </div>
            ))}
          </div>
        )}
        <div className='semantics-editor-wrapper validation-phase'>
          <div className='validation-layout'>
            <div className='viewers'>
              <ViewGrid layout='vertical-right-halved'>
                {pointCloud && (
                  <Fragment>
                    <ViewWrapper
                      initialView={PointCloudViewType.PERSPECTIVE}
                      disabled={tree?.tree_flow_status === TreeFlowStatus.MeasurementValidationDone}
                      configItems={configItems}
                      setConfigItems={setConfigItems}
                    />
                    <ViewWrapper
                      initialView={PointCloudViewType.TOP_DOWN}
                      disabled={tree?.tree_flow_status === TreeFlowStatus.MeasurementValidationDone}
                      configItems={configItems}
                      setConfigItems={setConfigItems}
                    />
                    <ViewWrapper
                      initialView={PointCloudViewType.SECTION}
                      disabled={tree?.tree_flow_status === TreeFlowStatus.MeasurementValidationDone}
                      configItems={configItems}
                      setConfigItems={setConfigItems}
                    />
                  </Fragment>
                )}
              </ViewGrid>
            </div>
            <div className='semantic-progress-controls'>
              <ProgressControls
                max={trees?.length}
                value={trees.length - toDos.length}
                hideProgressBar={false}
                disabled={false}
                actions={validationActions({
                  onUndo: undefined,
                  isUndoAvailable: false,
                  tree: currentTree,
                  updateTree: async (status: TreeStatus, tree_flow_status: TreeFlowStatus) => {
                    await updateTree({ status, tree_flow_status });
                    await betaManagedAreaContext.fetchTrees();
                    await moveToNeighbour(1);
                  },
                  showTodoAction: false,
                  commentTree: undefined,
                  removeTreeComment: undefined,
                  removeTree: undefined,
                  sendToField: () => alert('Unimplemented feature'),
                  onSkip: undefined,
                  onRotation: undefined,
                  numberOfTrees: toDos.length,
                  validationTool: undefined,
                  changeTool: undefined,
                  dismissModal,
                  presentModal,
                  showComment: false,
                  isSemantics: false,
                  showDeleteConfirmation: true,
                  deleteHidden: true,
                  saveDisabled: false,
                  disabled: false,
                  onSetTmsCategory: async (category: any) => {
                    if (category === 'l3') {
                      await updateTree({
                        tms_category: 'l3',
                        status: TreeStatus.SentToField,
                        tree_flow_status: TreeFlowStatus.SentToField,
                      });
                    } else {
                      await setTMSCategory(category);
                    }
                    await betaManagedAreaContext.fetchTrees();

                    if (category === 'l3') {
                      moveToNeighbour(1);
                    }
                  },
                  showTmsButtons: true,
                  showTmsL3Button: true,
                  showNavigationArrows: false,
                })}
              />
            </div>
          </div>

          <div className='tight-side-wrapper'>
            <div className={`attributes-wrapper`} style={{ backgroundColor: '#202324' }}>
              <div className={`attributes-container`} style={{ backgroundColor: '#202324' }}>
                <div className='tabs-wrapper'>
                  <AttributesTab value={attributesTabIndex} aria-label='microclimate attribute tabs' variant='fullWidth'>
                    <Tab
                      icon={<Attributes color={muiTheme.palette.text.primary} />}
                      iconPosition='start'
                      label={
                        <span className={'title'} style={{ color: muiTheme.palette.text.primary }}>
                          Attributes
                        </span>
                      }
                      {...a11yProps('attributes', 0)}
                    />
                  </AttributesTab>
                </div>
                <TabPanel value={0} index={0} name='attributes' style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                  <div className='attributes-panel' style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <TreeHeightCard
                      disabled={tree?.tree_flow_status === TreeFlowStatus.MeasurementValidationDone}
                      alerts={alerts}
                      setAlerts={setAlerts}
                    />
                    <CrownHeightCard
                      disabled={tree?.tree_flow_status === TreeFlowStatus.MeasurementValidationDone}
                      alerts={alerts}
                      setAlerts={setAlerts}
                    />
                    <FirstBifurcationCard
                      disabled={tree?.tree_flow_status === TreeFlowStatus.MeasurementValidationDone}
                      alerts={alerts}
                      setAlerts={setAlerts}
                    />
                    <FirstBifurcationMidpointCard
                      disabled={tree?.tree_flow_status === TreeFlowStatus.MeasurementValidationDone}
                      alerts={alerts}
                      setAlerts={setAlerts}
                    />
                    <CanopyCard
                      disabled={tree?.tree_flow_status === TreeFlowStatus.MeasurementValidationDone}
                      alerts={alerts}
                      setAlerts={setAlerts}
                      tree={currentTree}
                      numberOfStems={numberOfStems}
                      setNumberOfStems={setNumberOfStems}
                    />
                    {/* <LeanAngleCard disabled={tree?.tree_flow_status === TreeFlowStatus.MeasurementValidationDone} /> */}
                    <TrunkDetailsCard
                      defaultTrunk={defaultTrunk}
                      disabled={tree?.tree_flow_status === TreeFlowStatus.MeasurementValidationDone}
                      alerts={alerts}
                      setAlerts={setAlerts}
                      tree={currentTree}
                      numberOfStems={numberOfStems}
                      setNumberOfStems={setNumberOfStems}
                    />
                    <div style={{ display: 'flex', gap: '16px', justifyContent: 'center' }}>
                      <Button
                        label={'Approve all'}
                        onClick={handleApproveAll}
                        leadingIcon={'approve'}
                        style={{
                          color: '#818385',
                          padding: '4px 14px',
                          backgroundColor: 'transparent',
                          border: 'solid 1px #818385',
                          margin: 0,
                        }}
                      />
                      <Button
                        label={'Deselect all'}
                        onClick={handleDeselectAll}
                        leadingIcon={'deselect'}
                        style={{
                          color: '#818385',
                          padding: '4px 14px',
                          backgroundColor: 'transparent',
                          border: 'solid 1px #818385',
                          margin: 0,
                        }}
                      />
                    </div>
                    <div className='helperText'>
                      {!areAttributesCompleted && <p>You have to complete all Attributes!</p>}
                      {!currentTree?.tms_category && <p>You have to set tree's TMS Category!</p>}
                    </div>
                  </div>
                </TabPanel>
              </div>
              <div
                className={'attributes-actions-wrapper'}
                style={{
                  display: 'flex',
                  padding: '12px 36px',
                  gap: '20px',
                  alignItems: 'baseline',
                  justifyContent: 'center',
                  borderTop: 'solid 1px #6B6B6B',
                }}
              >
                {alerts?.length > 0 && (
                  <Tooltip title='There are some suspicious attributes'>
                    <WarningAmberOutlinedIcon color='warning' />
                  </Tooltip>
                )}
                {renderMemoizedButton}
                <IconButton
                  icon={'bad-segmentation'}
                  onClick={() => setIsBadSegmentationModalVisible(true)}
                  tooltip='Send to correction'
                  tooltipDirection='top'
                />
                <IconButton
                  icon={'archive'}
                  style={{ color: '#E8220C' }}
                  onClick={() => setModalVisible(true)}
                  tooltip='Delete tree'
                  tooltipDirection='top'
                />
              </div>
            </div>
          </div>

          {isBadSegmentationModalVisible && (
            <BadSegmentationModal
              setVisible={setIsBadSegmentationModalVisible}
              onSave={async (tree: any, s: any) => {
                await handleBadSegmentation(tree, s);
                await reFetchManagedAreaAndTrees();
                await moveToNeighbour(1);
              }}
              handleComment={_handleComment}
              removeComment={_removeComment}
            />
          )}
          <AlertModal
            isOpen={alertModalIsOpen}
            alerts={alerts}
            handleClose={() => setAlertModalIsOpen(false)}
            handleCompleteClick={setTreeStatus}
          />
          <ArchiveTreeModal
            visible={modalVisible}
            setVisible={setModalVisible}
            handleArchive={async () => {
              tree && (await handleSkipAndRemove(tree));
              await reFetchManagedAreaAndTrees();
              await moveToNeighbour(1);
              setModalVisible(false);
            }}
          />
        </div>
      </Finished>
    </LoaderWrapper>
  );
};

export default SemanticValidation;
