import { useCallback, useEffect, useMemo, useRef } from 'react';
import { LoadingManager, Vector3 } from 'three';
import { degToRad } from 'three/src/math/MathUtils';
import Number3 from '../../../@types/Number3';
import TreePointCloud from '../../../@types/TreePointCloud';
import useApi from '../../../hooks/api';
import useStore from '../../../store/useStore';
import LASLoader from '../../../utils/LASLoader';
import { projectLocal } from '../ValidationUtils';

const treeLazLoader = new LASLoader(new LoadingManager());
const environmentLazLoader = new LASLoader(new LoadingManager());

const getVectorInCoordinateSystem = (position: Number3, deltas: Number3) =>
  new Vector3(position[0] - (deltas?.[0] || 0), position[1] - (deltas?.[1] || 0), position[2] - (deltas?.[2] || 0));

const useLoadLaz = (setTreeId: (id: string) => void, MACode: string) => {
  const init = useRef(false);
  const { requestHeader, getUrl } = useApi();

  const pcActions = useStore((s) => s.pointCloud.actions);
  const { laz, isEnvVisible, pointClassStyles, envLaz } = useStore((s) => s.pointCloud);
  const validationActions = useStore((s) => s.actions);
  const validationCardActions = useStore((s) => s.validation.actions);
  const { tree, location } = useStore((s) => s.tree);
  const { setTreeTrunks } = useStore((s) => s.tree.actions.trunkActions);
  const setSectionControlsVisible = useStore((s) => s.pointCloud.actions.section.setVisible);

  const { setLocation, setFirstBifurcation, setViewerPosition, setLeaningVector, setDefaultDepth } = useStore((s) => ({
    setLocation: s.tree.actions.setLocation,
    setTreeIndex: s.tree.actions.setTreeIndex,
    setFirstBifurcation: s.actions.firstBifurcationPoint.setState,
    setViewerPosition: s.tree.actions.setViewerPosition,
    setLeaningVector: s.actions.leaningVector.setState,
    setDefaultDepth: s.pointCloud.actions.section.setDefaultDepth,
  }));

  const lazUrl = useMemo(() => {
    return {
      tree: getUrl(`/v1/proxy_v2/tree-data?url=${(tree as any)?.tree_segmentation_laz}`),
      env: getUrl(`/v1/proxy_v2/tree-data?url=${(tree as any)?.tree_segmentation_environment_laz}`),
    };
  }, [MACode, tree?.id, getUrl, (tree as any)?.tree_segmentation_laz, (tree as any)?.tree_segmentation_environment_laz]);

  const loadLaz = useCallback(() => {
    pcActions.setLaz(null);
    treeLazLoader.setRequestHeader(requestHeader);
    treeLazLoader.setProcessorColorClassPreference(pointClassStyles);

    treeLazLoader.load(lazUrl.tree, (pointcloud: TreePointCloud) => {
      pcActions.setOriginalLaz(pointcloud);
      pcActions.setLaz(pointcloud);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazUrl.tree, pcActions, requestHeader]);

  const loadEnvLaz = useCallback(() => {
    environmentLazLoader.setRequestHeader(requestHeader);
    environmentLazLoader.setProcessorColorClassPreference(pointClassStyles);

    environmentLazLoader.load(
      lazUrl.env,
      (pointcloud: TreePointCloud) => pcActions.setEnvLaz(pointcloud),
      () => {},
      () => pcActions.setEnvNotFound(true)
    );
  }, [requestHeader, pointClassStyles, lazUrl.env, pcActions]);

  const onTreeLoaded = useCallback(() => {
    validationCardActions.setHasLoadedParams(false);

    if (!tree) return;
    validationActions.resetToolVisibility();
    setDefaultDepth();
    setSectionControlsVisible(false);

    validationCardActions.setAllActionsTo(null);
    validationActions.setActiveTool(null);

    const crownExcentricity = tree.crown_excentricity
      ? Array.isArray(tree.crown_excentricity)
        ? tree.crown_excentricity
        : JSON.parse(tree.crown_excentricity).coordinates
      : [0, 0];

    validationActions.canopy.setState({
      dX: crownExcentricity[0],
      dY: crownExcentricity[1],
      rX: tree.canopy_ellipse_a || 1.8,
      rY: tree.canopy_ellipse_b || 2.2,
      rotation: degToRad(tree.canopy_ellipse_direction || 0),
      diameter: null,
      normal: new Vector3(0, 0, 1),
      height: 1,
    });
    validationActions.canopy.setPrev(validationActions.canopy.state);

    validationActions.leaningVector.setState({
      startVector: validationActions.leaningVector.state.startVector,
      leanVector: new Vector3(...(tree.trunk_leaning_vector ?? [0, 0, 0])),
    });
    validationActions.leaningVector.setPrev(validationActions.leaningVector.state);

    setTreeTrunks(tree.tree_trunks);
    validationCardActions.setHasLoadedParams(true);
  }, [setDefaultDepth, setSectionControlsVisible, setTreeTrunks, tree, validationActions, validationCardActions]);

  useEffect(() => {
    if (tree?.id) setTreeId(tree.id);
    if (!MACode) return;
    if (tree?.id && !init.current) init.current = true;

    if (tree?.location) {
      const location = tree?.location?.coordinates.slice(0);
      const position = projectLocal(location);
      setLocation(position);
    }

    if (tree?.id) {
      loadLaz();
      pcActions.setEnvVisible(false);
      pcActions.setEnvLaz(null);
      pcActions.setEnvNotFound(false);
      onTreeLoaded();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tree?.id, MACode, loadLaz]);

  useEffect(() => {
    if (!isEnvVisible || envLaz) return;
    loadEnvLaz();
  }, [envLaz, isEnvVisible, loadEnvLaz]);

  // Init tree location coordinates
  useEffect(() => {
    if (!location || !laz || !tree) return;
    validationCardActions.setHasLoadedDeltaCorrection(false);

    const treeLocationCoordinate = getVectorInCoordinateSystem(location, laz.pc.mins);

    setViewerPosition(treeLocationCoordinate);
    setLeaningVector({
      leanVector: null,
      startVector: new Vector3().copy(treeLocationCoordinate),
    });

    validationActions.height.setState(tree?.height + treeLocationCoordinate.z ?? 0);
    validationActions.height.setPrev(validationActions.height.state);

    validationActions.canopyHeight.setState(tree?.crown_height ?? 0);
    validationActions.canopyHeight.setPrev(validationActions.canopyHeight.state);

    validationCardActions.setHasLoadedDeltaCorrection(true);
    // Load values with delta correction required
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laz, location, setLeaningVector, setViewerPosition, tree?.id]);

  // Add first bif coordinate with delta values
  useEffect(() => {
    if (!tree?.first_bifurcation_point || !laz) return;
    validationCardActions.setHasLoadedBif(false);

    const firstBif = JSON.parse(tree.first_bifurcation_point as unknown as string);
    const firstBifPointsAfterDeltaReduction = getVectorInCoordinateSystem(firstBif.coordinates, laz.pc.mins);
    setFirstBifurcation(firstBifPointsAfterDeltaReduction);

    pcActions.section.setDefaultTarget(firstBifPointsAfterDeltaReduction);
    pcActions.section.setTarget(firstBifPointsAfterDeltaReduction);
    pcActions.section.setDefaultNormal(new Vector3(0, 0, 1));
    pcActions.section.setNormal(new Vector3(0, 0, 1));

    validationCardActions.setAzimuthAngle(0);

    validationCardActions.setHasLoadedBif(true);
    // Load firstBif and load as section target
    // Reset default section target and normal with new first bifurcation
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laz, pcActions.section, setFirstBifurcation, tree?.id, validationCardActions]);
};

export default useLoadLaz;
