import proj4 from 'proj4';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import * as THREE from 'three';
import { Icon, Panorama } from '..';
import { LoaderWrapper } from '../../layout';
import { getPointLabelDefaultHexColor, useConfig } from '../../providers/config';
import { getCapturePoint } from '../../providers/data';
import { RECLASS_TOOLS } from '../../routes/Validation/SegmentationValidation';
import { Button, Color, DropdownInput, Slider, Toggle } from '../inputs';
import ClassOption, { ClassOptionConfig } from './ClassOption';
import SemanticMover, { VIEW } from './SemanticMover';
import { useUser } from '../../providers/user';
import ValidationTool from '../../enums/ValidationTool';
import ClickableBadge from './ClickableBadge';
import { Vector3 } from 'three';
import useStore from '../../store/useStore';
import ViewWrapper from './Views/ViewWrapper';
import { PointCloudViewType } from '../../enums/PointCloudViewType';
import TreeStatus from '../../enums/TreeStatus';
import ValidationAction from '../../enums/ValidationAction';
import TreeFlowStatus from '../../enums/TreeFlowStatus';

/* const generateUrl = (id) =>
  `${window.location.pathname.split("/").slice(0, 3).join("/")}/location/${id}`; */
const SHOWN_FIRST_BIFURCATION_TOAST = 'hasShownFirstBifurcationToast';

const indexToAxisMap = {
  0: 'x',
  1: 'y',
  2: 'z',
};

export const exactFirstBifurcationRangeOffset = 0.2;

/**
 * @typedef {Object} ExactFirstBifurcationUpdatePayload
 * @property {number | undefined} min
 * @property {number | undefined} max
 * @property {number | undefined} x
 * @property {number | undefined} y
 * @property {number | undefined} z
 */

const SegmentationEditor = ({
  position,
  onPositionChange = () => {},
  pointcloud = {},
  treeLoaded = false,
  loading,
  tree,
  selection,
  onTreeAdd,
  environmentToggleProps,
  onFirstBifurcationPointChange,
  firstBifurcationPoint,
  actionMode,
  brushRadius,
  selectTree,
  setPanoramicLoading,
  layerSources,
  ...props
}) => {
  const { toast, ...restProps } = props;
  const hasSeenFirstBifurcationToast = sessionStorage.getItem(SHOWN_FIRST_BIFURCATION_TOAST) === 'true';
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const view = searchParams.get('view') ?? VIEW.DEFAULT;
  const { getConfig } = useConfig();
  const [isPanoramic, setIsPanoramic] = useState(true);
  const [capturePoint, setCapturePoint] = useState({
    position: { coordinates: [0, 0, 0] },
  });
  const [localPosition, setPosition] = useState({
    hasChanged: false,
    coords: [],
  });
  const [colors, setColors] = useState({
    canopy: getPointLabelDefaultHexColor('canopy'),
    trunk: getPointLabelDefaultHexColor('trunk'),
  });

  const [opacities, setOpacities] = useState({ canopy: 1, trunk: 1 });

  const [pointSize, setPointSize] = useState(0.5);
  const [azimuthAngle, setAzimuthAngle] = useState(0);
  const [activeEditor, setActiveEditor] = useState('');
  const [reclassifySource, setReclassifySource] = useState(20);
  const [reclassifyTarget, setReclassifyTarget] = useState(21);
  const [showMeasurementEllipse, setShowMeasurementEllipse] = useState(false);

  const girth = useStore((s) => s.actions.girth);
  const trunks = useStore((s) => s.tree.treeTrunks);
  const viewerPosition = useStore((s) => s.tree.viewerPosition);
  const { setActiveTool } = useStore((s) => ({ setActiveTool: s.actions.setActiveTool }));
  const setDefaultDepth = useStore((s) => s.pointCloud.actions.section.setDefaultDepth);
  const { setSectionTarget, setSectionNormal } = useStore((s) => ({
    setSectionTarget: s.pointCloud.actions.section.setTarget,
    setSectionNormal: s.pointCloud.actions.section.setNormal,
  }));
  const pc = useStore((s) => s.pointCloud.laz);
  const setClassColor = useStore((s) => s.pointCloud.actions.setClassColor);

  const [selectedTrunk, setSelectedTrunk] = useState(trunks[0] ?? null);
  const [actualTreePosition, setActualTreePosition] = useState(tree?.location_local ? position?.coords : null);

  const { token } = useUser();

  const viewPanorama = useCallback(async () => {
    const panoramaImages = await getCapturePoint(tree, token);

    setCapturePoint({
      position: { coordinates: panoramaImages?.[0]?.origin.coordinates },
    });

    const location = JSON.parse(tree?.location)?.coordinates.slice(0);
    let position = proj4('EPSG:4326', 'localProjection', location || []);
    setActualTreePosition(tree?.location_local ? position?.coords : null);

    if (tree.location_local !== undefined) {
      position = tree.location_local;
    }

    setPosition({ coords: position });
  }, [tree?.id]);

  useEffect(() => {
    if (!tree?.id && loading) return;
    setActiveTool(null);
    setShowMeasurementEllipse(false);
    girth.setState(null);
    setSelectedTrunk(trunks[0] ?? null);

    const pointcloudColors = localStorage.getItem('pointcloud-colors');

    if (pointcloudColors) {
      const colors = JSON.parse(pointcloudColors);
      setColors((prev) => ({ ...prev, ...colors }));

      const items = Object.entries(colors);

      if (pc?.geometry && items.length) {
        items.forEach((item) => setClassColor(item[0], item[1]));
      }
    }

    const pointcloudOpacities = localStorage.getItem('pointcloud-opacities');

    if (pointcloudOpacities) {
      const opacities = JSON.parse(pointcloudOpacities);
      setOpacities((prev) => ({ ...prev, ...opacities }));
    }
  }, [pc?.geometry]);

  useEffect(() => {
    if (!isPanoramic) return;
    if (tree?.id) viewPanorama();
  }, [viewPanorama, tree?.id, isPanoramic]);

  const reclassSaveDisabled = useMemo(
    () => actionMode !== ValidationTool.Reclassify || !props.reclassStates.length,
    [actionMode, props.reclassStates.length]
  );

  // Same as setPosition in SemanticValidation
  const changePosition = (position) => onPositionChange(position.map((pos, i) => pos + (pointcloud?.pc?.mins?.[i] || 0)));

  const pos = useMemo(
    () =>
      new THREE.Vector3(
        position[0] - (pointcloud?.pc?.mins?.[0] || 0),
        position[1] - (pointcloud?.pc?.mins?.[1] || 0),
        position[2] - (pointcloud?.pc?.mins?.[2] || 0)
      ),
    [position, pointcloud]
  );

  const currentFirstBifurcation = useMemo(() => {
    const parsedPoint = JSON.parse(tree?.first_bifurcation_point ?? '{}');
    return parsedPoint?.coordinates ? parsedPoint.coordinates : position;
  }, [tree?.first_bifurcation_point, position]);

  const firstBifurcationDelta = useMemo(
    () =>
      new THREE.Vector3(
        currentFirstBifurcation[0] - (pointcloud?.pc?.mins?.[0] || 0),
        currentFirstBifurcation[1] - (pointcloud?.pc?.mins?.[1] || 0),
        currentFirstBifurcation[2] - (pointcloud?.pc?.mins?.[2] || 0)
      ),
    [currentFirstBifurcation, pointcloud]
  );

  /*   const addTree = useCallback(
    async (e) => {
      const managedArea = selection[0];
      const newTree = await onTreeAdd(e, managedArea);
      history.push(generateUrl("new/" + newTree?.id));
    },
    [history, onTreeAdd, selection]
  ); */

  const onColorChange = ({ name, value }) => {
    setColors((old) => {
      const newColors = Object.assign({}, old);
      newColors[name] = value;

      localStorage.setItem('pointcloud-colors', JSON.stringify(newColors));

      return newColors;
    });
  };

  const onColorVisibilityChange = ({ name, value }) => {
    setOpacities((old) => {
      const newOpacities = Object.assign({}, old);
      newOpacities[name] = value ? 1 : 0;

      localStorage.setItem('pointcloud-opacities', JSON.stringify(newOpacities));

      return newOpacities;
    });
  };

  /*   const onFirstBifurcationViewChange = ({ value }) => {
    const updatedSearchParams = new URLSearchParams(window.location.search);
    updatedSearchParams.set(
      "view",
      value ? VIEW.FIRST_BIFURCATION : VIEW.DEFAULT
    );
    history.push(
      `${window.location.pathname}?${updatedSearchParams.toString()}`
    );
  }; */

  const [exactFirstBifurcation, setExactFirstBifurcation] = useState({
    min: 0.8,
    max: 1.2,
    x: 0,
    y: 0,
    z: 1,
  });

  useEffect(() => {
    const firstBifurcation = JSON.parse(firstBifurcationPoint);
    const isFirstBifurcationSet = !firstBifurcation.isInitial;
    const isDeltaNotSet = [firstBifurcationDelta.x, firstBifurcationDelta.y, firstBifurcationDelta.z].some((coord) => isNaN(coord));

    if (isDeltaNotSet) return;

    if (isFirstBifurcationSet) {
      const [x, y, z] = firstBifurcation.coordinates.map((coordinate, index) => {
        return coordinate - currentFirstBifurcation[index] + firstBifurcationDelta[indexToAxisMap[index]];
      });

      setExactFirstBifurcation((prevState) => ({ ...prevState, x, y, z }));
      return;
    }

    setExactFirstBifurcation((prevState) => ({
      x: firstBifurcationDelta.x,
      y: firstBifurcationDelta.y,
      z: firstBifurcationDelta.z,
      min: firstBifurcationDelta.z - exactFirstBifurcationRangeOffset,
      max: firstBifurcationDelta.z + exactFirstBifurcationRangeOffset,
    }));
  }, [firstBifurcationPoint, position, pos, tree, currentFirstBifurcation, firstBifurcationDelta]);

  /**
   * @param {ExactFirstBifurcationUpdatePayload} payload
   */
  const onExactFirstBifurcationChange = (payload) =>
    setExactFirstBifurcation((prevState) => {
      const state = { ...prevState, ...payload };

      const coordinates = [state.x, state.y, state.z].map((coordinate, index) => {
        if (!payload.z && index === 2) return restProps.trunkHeight + position[2];
        return coordinate + currentFirstBifurcation[index] - firstBifurcationDelta[indexToAxisMap[index]];
      });

      const firstBifab = JSON.stringify({ ...JSON.parse(firstBifurcationPoint), coordinates, isInitial: false });
      onFirstBifurcationPointChange(firstBifab);
      return state;
    });

  useEffect(() => {
    if (hasSeenFirstBifurcationToast || view !== VIEW.FIRST_BIFURCATION) return;
    sessionStorage.setItem(SHOWN_FIRST_BIFURCATION_TOAST, 'true');
  }, [hasSeenFirstBifurcationToast, view]);

  const setEllipse = (ellipse) => {
    girth.setState(null);
    setTimeout(() => {
      if (!ellipse) return;

      setSectionTarget({ x: viewerPosition.x + ellipse.dX, y: viewerPosition.y + ellipse.dY, z: viewerPosition.z + ellipse.height });
      setSectionNormal(ellipse?.normal);
      girth.setState(ellipse);
    }, 1);
  };

  const setSelectedTrunkAndFirstGirth = (trunk) => {
    setSelectedTrunk(trunk ? trunk : null);
    if (!trunk?.girths.length) {
      setSectionTarget({ x: viewerPosition.x, y: viewerPosition.y, z: viewerPosition.z + 1 });
      setSectionNormal({ x: 0, y: 0, z: 1 });
    } else {
      setEllipse(trunk ? trunk.girths[0] : null);
    }
  };

  const toggleMeasurementEllipse = () => {
    if (!showMeasurementEllipse) {
      setDefaultDepth();
      setActiveTool(ValidationAction.Girth);

      if (!trunks?.length) {
        setSectionTarget({ x: viewerPosition.x, y: viewerPosition.y, z: viewerPosition.z + 1 });
        setSectionNormal({ x: 0, y: 0, z: 1 });
      } else if (!selectedTrunk) {
        setSelectedTrunkAndFirstGirth(trunks[0]);
      } else {
        setSelectedTrunkAndFirstGirth(selectedTrunk);
      }
    } else {
      setActiveTool(null);
    }
    setShowMeasurementEllipse((prevState) => !prevState);
  };

  // --- minimap handling
    const mapRef = useRef(null);

    const maLayer = {
      id: 'mas',
      source: 'mas',
      type: 'fill',
      color: '#082',
      opacity: 0.32,
    };
  
    const treeLayers = [
      {
        id: TreeFlowStatus.SegmentationValidationQueued,
        source: 'trees',
        type: 'circle',
        filter: ['==', ['get', 'status'], TreeFlowStatus.SegmentationValidationQueued],
        color: '#ff0000',
      },
      {
        id: TreeFlowStatus.SegmentationValidationDone,
        source: 'trees',
        type: 'circle',
        filter: ['==', ['get', 'status'], TreeFlowStatus.SegmentationValidationDone],
        color: '#AFFF14',
      },
      {
        id: TreeFlowStatus.SegmentationValidationDeleted,
        source: 'trees',
        type: 'circle',
        filter: ['==', ['get', 'status'], TreeFlowStatus.SegmentationValidationDeleted],
        color: '#666',
      },
      {
        id: TreeFlowStatus.SentToField,
        source: 'trees',
        type: 'circle',
        filter: ['==', ['get', 'status'], TreeFlowStatus.SentToField],
        color: '#ffff00',
      },
    ].map((layer) => {
      const config = getConfig(`statuses.${layer.id}`);
      return {
        ...layer,
        onClick: (centre, feature, id) => {
          selectTree(feature.id);
        },
        label: config?.label || 'Unnamed',
        color: getConfig(`colors.${config?.color}`) || '#08f',
      };
    });

/*     console.log("maLayer", maLayer);
    console.log("treeLayers", treeLayers); */
  
    const layers = [maLayer, ...treeLayers];

  // --- minimap handling

  return (
    <LoaderWrapper loading={loading}>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          position: 'relative',
        }}
      >
        {view === VIEW.FIRST_BIFURCATION && !hasSeenFirstBifurcationToast && (
          <div className='toast'>Click anywhere to place the first bifurcation point</div>
        )}

        {Boolean(props.toasts.length) && (
          <div className='toast-wrapper'>
            {props.toasts.map((toast, index) => (
              <div key={index} className={`toast ${toast.type} ${props.toasts.length - 1 === index ? 'last' : ''}`}>
                {toast.msg}
              </div>
            ))}
          </div>
        )}

        {(pointcloud || pointcloud === false) && (
          <Fragment>
            {actionMode === ValidationTool.Inspection_View && (
              <div className='semantic-upper-toolbar inspection-view'>
                <span>
                  <b>Inspection View</b>
                </span>
              </div>
            )}
            {actionMode === ValidationTool.Reclassify && (
              <div className='semantic-upper-toolbar reclassify'>
                <div className='tools-wrapper'>
                  <span>Tool</span>
                  <ClickableBadge
                    icon={'paint-brush'}
                    activeColor={'green'}
                    selected={props.reclassTool === RECLASS_TOOLS.BRUSH}
                    onClick={() => props.changeReclassTool(RECLASS_TOOLS.BRUSH)}
                  />
                  <ClickableBadge
                    icon={'vector-square'}
                    activeColor={'green'}
                    selected={props.reclassTool === RECLASS_TOOLS.POLYGON}
                    onClick={() => props.changeReclassTool(RECLASS_TOOLS.POLYGON)}
                  />
                </div>
                <span>Source class</span>
                <DropdownInput
                  options={ClassOptionConfig}
                  value={reclassifySource}
                  onPureChange={setReclassifySource}
                  optionComponent={ClassOption}
                  small
                />
                <span>Target class</span>
                <DropdownInput
                  options={ClassOptionConfig.filter((o) => o.value !== reclassifySource)}
                  value={reclassifyTarget}
                  onPureChange={setReclassifyTarget}
                  optionComponent={ClassOption}
                  small
                />
                {props.reclassTool === RECLASS_TOOLS.BRUSH && (
                  <div className='brush-size-input-wrapper'>
                    <span>Brush</span>
                    <Slider
                      valueFormatter={(val) => val?.toFixed(0) ?? '-'}
                      label='Brush size'
                      value={brushRadius}
                      onPureChange={props.setBrushRadius}
                      min={12}
                      max={60}
                      step={12}
                    />
                  </div>
                )}
                <Button label='Save changes' disabled={reclassSaveDisabled} onClick={props.saveLAZ} />
              </div>
            )}
            <div className='semantic-panorama-toggle'>
              <Toggle {...environmentToggleProps} />
              <Toggle value={isPanoramic} onPureChange={() => setIsPanoramic((old) => !old)} label='Toggle Panorama' />
            </div>
            <div className='segmentation-views-container'>
              <SemanticMover
                {...restProps}
                useMouseLeftButtonForEdit
                view={view}
                tree={tree}
                // Not needed
                pointcloud={pointcloud}
                actionMode={actionMode}
                editorName='height' // Will become obsolete, panels will be identified via ID
                // -> Moved to ValidationSlice
                pointSize={pointSize}
                colors={colors}
                opacities={opacities}
                azimuthAngle={azimuthAngle}
                setAzimuthAngle={setAzimuthAngle}
                setActiveEditor={setActiveEditor} // Renamed to setActivePanel, will use ID instead of name
                activeEditor={activeEditor} // Renamed to activePanelId, will use numerical ID instead of name
                // -> Moved to ReclassSlice
                brushRadius={brushRadius}
                reclassifySource={reclassifySource}
                reclassifyTarget={reclassifyTarget}
                // Not needed, will be rewrote
                firstBifurcationDelta={firstBifurcationDelta}
                position={pos}
                setPosition={changePosition}
                exactFirstBifurcation={exactFirstBifurcation}
                onExactFirstBifurcationChange={onExactFirstBifurcationChange}
              />
              <div className='grid-right-side'>
                {isPanoramic ? (
                  <Panorama
                    onTreeChange={selectTree}
                    treeId={tree?.id}
                    managedAreaCode={selection?.[0]?.code}
                    optionalPosition={actualTreePosition ? new Vector3().fromArray(actualTreePosition) : null}
                    enableAddTree={false}
                    setLoading={setPanoramicLoading}
                    mapSources={layerSources}
                    mapLayers={layers}  
                    enabledMiniMap={true}
                    mapRef={mapRef}
                    enableGoogleMapsButton={true}
                  />
                ) : (
                  <>
                    <SemanticMover
                      position={pos}
                      setPosition={changePosition}
                      pointcloud={pointcloud}
                      {...restProps}
                      editing='canopy'
                      isUp
                      useMouseLeftButtonForEdit
                      colors={colors}
                      pointSize={pointSize}
                      azimuthAngle={azimuthAngle}
                      setAzimuthAngle={setAzimuthAngle}
                      activeEditor={activeEditor}
                      setActiveEditor={setActiveEditor}
                      editorName='canopy'
                      opacities={opacities}
                      view={view}
                      presentModal={props.presentModal}
                      dismissModal={props.dismissModal}
                      tree={tree}
                      actionMode={actionMode}
                    />
                  </>
                )}
                {pointcloud && (
                  <div className='girth-section-container'>
                    <ViewWrapper
                      hideTabSelector={true}
                      initialView={PointCloudViewType.SECTION}
                      disabled={tree?.tree_flow_status === TreeFlowStatus.MeasurementValidationQueued}
                      minZoom={40}
                      maxZoom={800}
                    />
                    <div className='measurement-ellipse-toggle'>
                      <Toggle value={showMeasurementEllipse} onPureChange={toggleMeasurementEllipse} label='Show Ellipse' />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        )}
        <div className='semantic-pc-controls'>
          <div className='semantic-pc-colors'>
            <Color label='Canopy' name='canopy' onChange={onColorChange} value={colors.canopy} key='canopy' />
            <Toggle name='canopy' onChange={onColorVisibilityChange} label='' value={!!opacities.canopy} />
            <Color label='Trunk' name='trunk' onChange={onColorChange} value={colors.trunk} key='trunk' />
            <Toggle name='trunk' onChange={onColorVisibilityChange} label='' value={!!opacities.trunk} />
            <span>Point size</span>
            <Slider
              valueFormatter={(val) => val?.toFixed(2) ?? '-'}
              label='Point size'
              value={pointSize}
              onPureChange={setPointSize}
              min={0.5}
              max={3}
              step={0.5}
            />
          </div>
          {props.lockView && <Icon className='error-lock' icon='lock' />}
        </div>
      </div>
    </LoaderWrapper>
  );
};

export default SegmentationEditor;
