import { Canvas } from '@react-three/fiber';
import { Suspense, useMemo } from 'react';
import { Color, MathUtils, Vector2, Vector3 } from 'three';
import ValidationAction from '../../../enums/ValidationAction';
import useStore from '../../../store/useStore';
import { convertValueBetweenRanges } from '../../../utils/mathUtils';
import { AxesHelper } from '../../ThreeHelperObjects/HelperObjects';
import DragPoint from '../Gizmos/DragPoint';
import EllipseGizmo from '../Gizmos/EllipseGizmo';
import SemanticActionsColorMap from '../SemanticActionsColorMap';
import TreePointCloud from '../TreePointCloud/TreePointCloud';
import ValidationControls from '../ValidationControls/ValidationControls';
import { calculateGirth, parseCanopy } from '../ValidationUtils';
import ViewBackground from '../ViewBackground/ViewBackground';
import config from '../config';
import useDefaultZoom from '../hooks/useDefaultZoom';

const CANOPY_ELLIPSE_COLORS = {
  dragPoint: new Color('#222D31'),
  dragPointHover: new Color(SemanticActionsColorMap[ValidationAction.Canopy]),
  dragPointBorder: new Color('#ffffff'),
  ellipseBody: new Color(SemanticActionsColorMap[ValidationAction.Canopy]),
};

const MIN_MAX_ZOOM = {
  min: 10,
  max: 300,
};

const CanopyView = ({ disabled }: { disabled: boolean }) => {
  const zoom = useDefaultZoom();
  const actions = useStore((s) => s.actions);
  const isCanopyCompleted = useStore((s) => s.validation.isCanopyCompleted);
  const tree = useStore((s) => s.tree.tree);
  const viewerPosition = useStore((s) => s.tree.viewerPosition);
  const updateRadius = useStore((s) => s.actions.updateRadius);
  const updateCenter = useStore((s) => s.actions.updateCenter);
  const updateRotation = useStore((s) => s.actions.updateRotation);

  const scaledZoom = useMemo(() => {
    if (!tree) return zoom * 4;
    const canopy = calculateGirth(parseCanopy(tree));
    const scaledZoom = convertValueBetweenRanges({ min: 0, max: canopy + 1 }, { min: MIN_MAX_ZOOM.min, max: MIN_MAX_ZOOM.max }, canopy);
    return MathUtils.clamp(scaledZoom, MIN_MAX_ZOOM.min, MIN_MAX_ZOOM.max);
  }, [tree, zoom]);

  const updateCanopyRadius = (axis: 'rX' | 'rY', radius: number) => updateRadius(ValidationAction.Canopy, axis, radius);

  const updateCanopyCenter = (vector: Vector2) => updateCenter(ValidationAction.Canopy, vector);

  const updateCanopyRotation = (rotation: number) => updateRotation(ValidationAction.Canopy, rotation);

  return (
    <div style={{ display: 'flex', flex: 1, position: 'relative' }}>
      <Canvas
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
        }}
        orthographic
        linear
        camera={{
          near: config.nearPlane,
          up: new Vector3(0, 0, 1),
          far: config.farPlane,
          zoom: scaledZoom,
        }}
      >
        <ViewBackground />
        {config.isAxesHelperVisible && <AxesHelper size={15} />}

        <Suspense>
          <TreePointCloud />
          <DragPoint
            name={'tree-location-point'}
            initialPosition={viewerPosition}
            color={new Color('red')}
            draggable={false}
            visibility={true}
            handleSize={0.1}
          />
          {actions.canopy.state && (
            <EllipseGizmo
              name={ValidationAction.Canopy}
              ellipse={actions.canopy.state}
              colors={CANOPY_ELLIPSE_COLORS}
              visible={actions.canopy.visibility}
              disabled={disabled || !!isCanopyCompleted}
              handleSize={0.15}
              updates={{
                updateRadius: updateCanopyRadius,
                updateCenter: updateCanopyCenter,
                updateRotation: updateCanopyRotation,
              }}
            />
          )}
        </Suspense>

        <ValidationControls minPolarAngle={0} maxPolarAngle={0} minZoom={MIN_MAX_ZOOM.min} maxZoom={MIN_MAX_ZOOM.max} name='canopy' />
      </Canvas>
    </div>
  );
};

export default CanopyView;
