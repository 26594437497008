import { ThreeEvent, useThree } from '@react-three/fiber';
import { useEffect, useMemo, useRef, useState } from 'react';
import { BufferGeometry, Color, EllipseCurve, Euler, Shape, ShapeGeometry, Vector2, Vector3 } from 'three';
import Ellipse from '../../../../@types/Ellipse';
import ThreeJsEventMouseButton from '../../../../enums/ThreeJsEventMouseButton';
import ValidationAction from '../../../../enums/ValidationAction';
import { EllipseWithNormalAndHeight } from '../../../../store/ActionSlices';
import useStore from '../../../../store/useStore';
import EllipseBody from './EllipseBody';
import EllipseDragPoint from './EllipseDragPoint';

type Props = {
  name: ValidationAction;
  ellipse: EllipseWithNormalAndHeight;
  colors?: { dragPoint?: Color; dragPointBorder?: Color; dragPointHover?: Color; ellipseBody?: Color; ellipseOutline?: Color };
  visible: boolean;
  disabled: boolean;
  onChange?: (ellipse: Ellipse, adjustPosition?: boolean) => void;
  handleSize?: number;
  updates: {
    updateRadius: (axis: 'rX' | 'rY', newRadius: number) => EllipseWithNormalAndHeight;
    updateCenter: (newCenter: Vector2) => void;
    updateRotation: (newRotation: number) => void;
  };
};

const generateGeometry = (ellipse: Ellipse | null) => {
  const path = new Shape();
  path.absellipse(0, 0, ellipse?.rX ?? 5, ellipse?.rY ?? 5, 0, 2 * Math.PI, false, 0);

  const geometry = new ShapeGeometry(path, 100);

  const outline = new EllipseCurve(0, 0, ellipse?.rX ?? 5, ellipse?.rY ?? 5, 0, 2 * Math.PI, false, 0);
  const outlinePoints = outline.getPoints(50);
  const outlineGeometry = new BufferGeometry().setFromPoints(outlinePoints);

  return { body: geometry, outline: outlineGeometry };
};

const EllipseGizmo = ({ name, ellipse, colors, visible, disabled, handleSize, updates }: Props) => {
  const ref = useRef<any>(null);
  const ellipseRef = useRef<EllipseWithNormalAndHeight | null>(ellipse);
  const { camera, raycaster, mouse, scene } = useThree();

  const ELLIPSE_CENTER_DRAG_POINT_NAME = `${name}-center`;

  useEffect(() => {
    onRadiusChange('rX', ellipse.rX, false);
    onRadiusChange('rY', ellipse.rY, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ellipse.rX, ellipse.rY]);

  const viewerPosition = useStore((s) => s.tree.viewerPosition);
  const setActiveTool = useStore((s) => s.actions.setActiveTool);

  const [rotationStartAngle, setRotationStartAngle] = useState(0);
  const [isRotate, setRotate] = useState(false);

  const onRadiusChange = (axis: 'rX' | 'rY', newRadius: number, isDragEnd: boolean) => {
    if (!ref?.current?.body || !ref?.current?.outline || !ellipseRef.current) return;

    setActiveTool(name);
    ellipseRef.current = { ...ellipseRef.current, [axis]: newRadius };
    if (isDragEnd) ellipseRef.current = updates.updateRadius(axis, newRadius) as EllipseWithNormalAndHeight;

    const { body, outline } = generateGeometry(ellipseRef.current);
    ref.current.body.geometry = body;
    ref.current.outline.geometry = outline;
  };

  const onCenterChange = (pos: Vector2, isDragEnd: boolean) => {
    if (!ref?.current?.body || !ref?.current?.outline) return;

    setActiveTool(name);
    if (isDragEnd) {
      const deltas = new Vector2().set(pos.x - viewerPosition.x, pos.y - viewerPosition.y);
      updates.updateCenter(deltas);
    }

    ref.current.body.position.x = pos.x;
    ref.current.body.position.y = pos.y;
    ref.current.outline.position.x = pos.x;
    ref.current.outline.position.y = pos.y;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onPointerDown = (e: ThreeEvent<PointerEvent>) => {
    const clickedOtherControl = e.intersections.length > 1;
    const center = ref?.current?.body?.position;
    const mousePosition = e.point;
    if (!center || clickedOtherControl || disabled || e.button === ThreeJsEventMouseButton.RIGHT) return;

    setRotate(true);
    const directionVector = {
      x: mousePosition.x - center.x,
      y: mousePosition.y - center.y,
    };
    const startAngle = Math.atan2(directionVector.y, directionVector.x);
    setRotationStartAngle(startAngle);
  };

  const onPointerMove = (e: ThreeEvent<PointerEvent>) => {
    const center = ref.current?.body?.position;
    const mousePosition = e.point;
    if (!isRotate || !ref.current.body || !ref.current.outline || !center) return;
    if (isRotate) setActiveTool(name);

    const directionVector = {
      x: mousePosition.x - center.x,
      y: mousePosition.y - center.y,
    };
    const angle = Math.atan2(directionVector.y, directionVector.x);
    const deltaAngle = angle - rotationStartAngle;
    const rotation = ellipse.rotation + deltaAngle;
    ref.current.body.rotation.z = rotation;
    ref.current.outline.rotation.z = rotation;
  };

  const onPointerStop = () => {
    if (!ref?.current?.body || !isRotate) return;
    updates.updateRotation(ref.current.body.rotation.z);
    setRotate(false);
  };

  // Memoize initial vectors to avoid rerenders on prop change
  // Unfortunately this is necessary, component is controlled by event handlers
  const initialPosition = useMemo(
    () => {
      return new Vector3(viewerPosition.x + ellipse.dX, viewerPosition.y + ellipse.dY, 0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewerPosition]
  );

  useEffect(() => {
    if (!ref.current.body || !ref.current.outline || !ellipseRef.current) return;
    const { body, outline } = generateGeometry(ellipse);
    ref.current.body.geometry = body;
    ref.current.outline.geometry = outline;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const traverseDescendants = (object: THREE.Object3D<THREE.Event>): THREE.Object3D<THREE.Event>[] => {
    const draggablePoints: THREE.Object3D<THREE.Event>[] = [];
    object.traverse(function (child: any) {
      if (child.geometry?.userData?.isDraggable) {
        draggablePoints.push(child);
      }
    });
    return draggablePoints;
  };

  const isChangeForPointEnabled = (pointName: string) => {
    raycaster.setFromCamera(mouse, camera);
    const objectByName = scene.getObjectByName(name);
    if (!objectByName) return false;

    const intersects = raycaster.intersectObjects(traverseDescendants(objectByName));
    if (intersects.length < 1) return false;
    if (intersects.length === 1) return true;
    return pointName === ELLIPSE_CENTER_DRAG_POINT_NAME;
  };

  return (
    <group visible={visible} name={name}>
      <EllipseDragPoint
        name={`${name}-center`}
        onChange={onCenterChange}
        position0={initialPosition}
        disabled={disabled}
        colors={{ point: colors?.dragPoint, hover: colors?.dragPointHover, border: colors?.dragPointBorder }}
        handleSize={handleSize}
        isChangeForPointEnabled={isChangeForPointEnabled}
      />
      <EllipseBody
        ref={ref}
        name={name}
        colors={{ bodyColor: colors?.ellipseBody, outlineColor: colors?.ellipseOutline }}
        position={initialPosition}
        rotation={new Euler(0, 0, ellipse.rotation)}
        onPointerDown={onPointerDown}
        onPointerMove={onPointerMove}
        onPointerUp={onPointerStop}
        onPointerOut={onPointerStop}
      >
        <EllipseDragPoint
          name={`${name}-radiusX`}
          onChange={(pos, isDragEnd) => onRadiusChange('rX', pos.x, isDragEnd)}
          constrict={{ x: [0.01, 100], y: [0, 0] }}
          position0={new Vector3(ellipse.rX, 0, 0)}
          disabled={disabled}
          colors={{ point: colors?.dragPoint, hover: colors?.dragPointHover, border: colors?.dragPointBorder }}
          handleSize={handleSize}
          isChangeForPointEnabled={isChangeForPointEnabled}
        />
        <EllipseDragPoint
          name={`${name}-radiusY`}
          onChange={(pos, isDragEnd) => onRadiusChange('rY', pos.y, isDragEnd)}
          constrict={{ x: [0, 0], y: [0.01, 100] }}
          position0={new Vector3(0, ellipse.rY, 0)}
          disabled={disabled}
          colors={{ point: colors?.dragPoint, hover: colors?.dragPointHover, border: colors?.dragPointBorder }}
          handleSize={handleSize}
          isChangeForPointEnabled={isChangeForPointEnabled}
        />
      </EllipseBody>
    </group>
  );
};

export default EllipseGizmo;
