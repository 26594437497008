import { PureComponent } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import { cloneDeep } from "lodash";
import { CSS2DRenderer } from "three/examples/jsm/renderers/CSS2DRenderer";
import { exactFirstBifurcationRangeOffset } from ".";
import { getPointClassLabel, getRgbColorByClassification } from "../../providers/config";
import { ACTION_MODES, ERROR_TOOLS, RECLASS_TOOLS } from "../../routes/Validation/SegmentationValidation";
import { areVectorsEqual, isLastLineIntersectingPreviousLines, isPointInPolygon } from "../../utils/geometryUtils";
import { convertValueBetweenRanges } from "../../utils/mathUtils";
import {
  createCircle,
  createTargetPointObject,
  projectPointsToPlane,
  transformPointsToTwoDimensionalPlane,
} from "../../utils/threeJsHelper";
import { CommentTree } from "../modals";
import {
  ClippingMaskControl,
  EllipseControl,
  HeightControl,
  SinglePointControl,
} from "./controls";
import EditorInput from "./EditorInput";
import RadiusInput from "./RadiusInput";
import ValidationTool from "../../enums/ValidationTool";

const GeometryError = {
  InterSectionError: "intersectionError",
};

const girthViewDefaultCameraZoom = 3;
const girthViewDefaultZ = 150;

const exactFirstBifurcationViewDefaultCameraZoom = 1.5;

const polygonToolMinSnapThreshold = 0.025;
const polygonToolMaxSnapThreshold = 0.2;

const grabAbleControls = ["height", "trunkHeight", "clippingMask", "canopyHeight"];

class SemanticMover extends PureComponent {
  constructor(props) {
    super(props);

    this.moveStart = new THREE.Vector2();
    this.moveEnd = new THREE.Vector2();
    this.moveDelta = new THREE.Vector2();
    this.moveSpeed = 0.003;

    this.scene = new THREE.Scene();
    this.scene.name = props.editorName;
    this.scene.up.set(0, 0, 1);
    this.scene.background = new THREE.Color(this.props.background || 0x000000);

    this.raycaster = new THREE.Raycaster();

    this.sphere = new THREE.Mesh(
      new THREE.SphereGeometry(SemanticMover.sphereSize, 32, 32),
      new THREE.MeshBasicMaterial({ color: SemanticMover.sphereColor })
    );
    this.sphere.visible = props.editorName === 'height';
    this.scene.add(this.sphere);
    this.renderCount = 0;
    this.userActivity = false;

    window.addEventListener("resize", () => this.updateRatio());

    this.resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        this.updateRatio();
      }
    });

    this.state = {
      moving: false,
      reclassification: {
        loading: false,
        mousePos: {
          x: 0,
          y: 0,
        },
      },
      actionMode: this.props.actionMode,
      errorPoints: [],
      reclassPoints: [],
    };

    this.centerHeight = 0;

    this.firstBifurcationDelta = props.firstBifurcationDelta;

    this.girthViewClippingPlane = this._calculateGirthViewClippingPlanes(this.props.position.z);
  }

  static frustumSize = 12;
  static sphereSize = 0.2;
  static sphereColor = 0xff0000;
  static minZoom = 0.2;
  static maxZoom = 7;
  static heightCircleRadius = 50;
  static trunkCircleRadius = 50;
  static canopyCircleRadius = 50;
  /** @type {ClippingMaskControl | null} */
  _firstBifurcationClippingMaskControl = null;
  /** @type {CSS2DRenderer} */
  _htmlRenderer;
  static _GIRTH_MIN_VERTICAL_POINT = 0.8;
  static _GIRTH_MAX_VERTICAL_POINT = 1.2;
  static _GIRTH_VIEW_DEFAULT_CLIPPING_PLANES = {
    top: SemanticMover._GIRTH_MAX_VERTICAL_POINT,
    bottom: SemanticMover._GIRTH_MIN_VERTICAL_POINT
  };

  static errorLineHexColor = 0xff0000;
  static reclassifyLineHexcolor = 0xf2c200;

  cameras = {
    ORTHOGRAPHIC: new THREE.OrthographicCamera(
      (0.5 * SemanticMover.frustumSize * 5) / 2,
      (0.5 * SemanticMover.frustumSize * 5) / 2,
      SemanticMover.frustumSize / 2,
      SemanticMover.frustumSize / -2,
      0.1,
      10000
    ),
  };
  /** @type {SemanticControl[]} */
  semanticControls = [];
  /** @type {SinglePointControl} */
  _firstBifurcationPositionControl;

  componentDidMount = () => {
    window.addEventListener("keydown", this.handleKeyPress);
    this.resizeObserver.observe(this.container);

    Object.values(this.cameras).forEach((camera) => {
      camera.up.set(0, 0, 1);
      camera.updateProjectionMatrix();
    });

    this.camera = this.cameras.ORTHOGRAPHIC;

    const controlProps = {
      scene: this.scene,
      camera: this.camera,
      container: this.container,
      points: this.points,
      repaint: this.repaint,
      getConfig: this.props.getConfig,
      isDark: this.props.isDark,
      moveWithCtrl: !this.props.useMouseLeftButtonForEdit,
      onMovingChange: (moving) => {
        this.setState({ ...this.state, moving });
      },
    };

    this.setFilter(true);

    if (!this.props.isUp) {
      this.props.setRotationHandler(() => this.handleRotation);
      this._createControlsForSemantics(controlProps);
      this.semanticControls.push(
        this.heightControl,
        this.trunkHeightControl,
        this.canopyHeightControl,
        this._firstBifurcationClippingMaskControl
      );
    } else {
      if (this.props.editing === "girth1") {
        this._createControlsForGirth1();
      }

      if (this.props.editing === "canopy") {
        /* this._createControlsForCanopy(controlProps); */
      }
    }

    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      canvas: this.canvas,
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.localClippingEnabled = true;

    this._htmlRenderer = new CSS2DRenderer();
    this._htmlRenderer.domElement.style.position = "absolute";
    this.container.appendChild(this._htmlRenderer.domElement);

    this.updateRatio();

    this.controls = new OrbitControls(
      this.camera,
      this._htmlRenderer.domElement
    );
    this.controls.enablePan = true;
    this.controls.rotateSpeed = 1.0;
    this.controls.minZoom = SemanticMover.minZoom;
    this.controls.maxZoom = ["girth1", "firstBifurcation"].includes(this.props.editing) ? 30 : SemanticMover.maxZoom;

    this.controls.addEventListener("change", () => {
      if(this._firstBifurcationPositionControl) this._firstBifurcationPositionControl.rescaleObject(); 
      this.repaint()
    });

    this.controls.addEventListener("start", () => {
      this.userActivity = true;
    });

    // if (this.props.isUp) {
    //   this.controls.minPolarAngle = 0;
    //   this.controls.maxPolarAngle = 0;
    // } else {
    //   this.controls.minPolarAngle = Math.PI / 2;
    //   this.controls.maxPolarAngle = Math.PI / 2;
    // }

    if (this.props.useMouseLeftButtonForEdit) {
      this.controls.mouseButtons = {
        LEFT: null,
        MIDDLE: THREE.MOUSE.DOLLY,
        RIGHT: THREE.MOUSE.ROTATE,
      };
    }

    // this.controls.minAzimuthAngle = this.props.azimuthAngle.toFixed(2);
    // this.controls.maxAzimuthAngle = this.props.azimuthAngle.toFixed(2);

    const editorName = this.props.editorName;
    if (editorName === "girth" || editorName === "firstBifurcation") {
      this.camera.zoom = this.props.editing === "girth1" ? girthViewDefaultCameraZoom : exactFirstBifurcationViewDefaultCameraZoom;
      this.camera.updateProjectionMatrix();
    }

    this.controls.update();
    this.repaint();

    this.updateFromProps({});
  };

  _calculateGirthViewClippingPlanes(treePositionZ) {
    const adjustedClippingPlanes = {
      top: SemanticMover._GIRTH_MAX_VERTICAL_POINT + treePositionZ,
      bottom: SemanticMover._GIRTH_MIN_VERTICAL_POINT + treePositionZ,
    };
    /*PRD-335*/
    /*const isClippingPlanePartiallyBelowPointcloud = adjustedClippingPlanes.bottom < 0;

    if (isClippingPlanePartiallyBelowPointcloud) {
      return SemanticMover._GIRTH_VIEW_DEFAULT_CLIPPING_PLANES;
    }*/
    return adjustedClippingPlanes;
  }

  _createControlsForSemantics(controlProps) {
    this.heightControl = new HeightControl({
      ...controlProps,
      onHeightChange: (verticalPosition) => {
        if (!this._firstBifurcationClippingMaskControl._isResizing) {
          this.props.onHeightChange(verticalPosition)
        }
      },
      radius: SemanticMover.heightCircleRadius,
      zoom: 0.5,
      color: "red",
      name: "height",
      treePosition: this.props.position
    });
    this.trunkHeightControl = new HeightControl({
      ...controlProps,
      onHeightChange: (verticalPosition, coordinatePositionZ) => {
        let min = verticalPosition - 0.2;
        let max = verticalPosition + 0.2;
        if (this._firstBifurcationClippingMaskControl) {
          const { min: mi, max: ma } = this._firstBifurcationClippingMaskControl.getBounds();
          min = mi;
          max = ma;
        }
        this.props.onExactFirstBifurcationChange({
          z: coordinatePositionZ,
          min,
          max,
        });
        if (this._firstBifurcationClippingMaskControl._isResizing) return;
        this.props.onTrunkHeightChange(verticalPosition);
      },
      radius: SemanticMover.trunkCircleRadius,
      zoom: 0.6,
      color: "blue",
      name: "trunkHeight",
      treePosition: this.props.position
    });
    this.canopyHeightControl = new HeightControl({
      ...controlProps,
      onHeightChange: (verticalPosition) => {
        if (!this._firstBifurcationClippingMaskControl._isResizing) {
          this.props.onCanopyHeightChange(verticalPosition)
        }
      },
      radius: SemanticMover.canopyCircleRadius,
      inverse: true,
      zoom: 0.45,
      color: "magenta",
      name: "canopyHeight",
      treePosition: this.props.position
    });
    this._firstBifurcationClippingMaskControl = new ClippingMaskControl({
      treePosition: this.props.position,
      name: "clippingMask",
      color: 0x0000ff,
      scene: this.scene,
      camera: this.camera,
      container: this.container,
      repaint: this.repaint.bind(this),
      isDark: this.props.isDark,
      zoom: 0.5,
      onMovingChange: (moving) => this.setState({ ...this.state, moving }),
      points: this.points,
      min: 0,
      max: 50,
      initialPosition: new THREE.Vector4(this.firstBifurcationDelta.x, this.firstBifurcationDelta.y, 0, Math.PI / 4),
      onChange: (min, max) => {
        if (this.props.view !== VIEW.FIRST_BIFURCATION) return;
        const trunkHeight = (min + max) / 2;
        this.props.onExactFirstBifurcationChange({
          min,
          max,
          z: trunkHeight,
        });
        this.props.onTrunkHeightChange(trunkHeight);
      },
      onResize: (min, max) => {
        if (this.props.view !== VIEW.FIRST_BIFURCATION) return;
        this.props.onExactFirstBifurcationChange({
          min,
          max,
        });
      },
    });
  }

  _createControlsForCanopy() {
    // TODO: validate if guard is needed: if (this.canopyControl) return;
    this.canopyControl = new EllipseControl({
      scene: this.scene,
      camera: this.camera,
      container: this.container,
      points: this.points,
      repaint: this.repaint,
      getConfig: this.props.getConfig,
      isDark: this.props.isDark,
      moveWithCtrl: !this.props.useMouseLeftButtonForEdit,
      onMovingChange: (moving) => this.setState({ ...this.state, moving }),
      onChange: this.props.onCanopyChange,
      height: 0,
      filter: false,
      zoom: 0.85,
      name: "canopy",
    });
    this.semanticControls.push(this.canopyControl);
  }

  _createControlsForGirth1() {
    if (this.girth1Control) return;
    const zoomValue = (this.props.calculateGirth(this.props.girth1)/10) > 1 ?
    1.8 / (this.props.calculateGirth(this.props.girth1)/ 10):
    1.5 / (this.props.calculateGirth(this.props.girth1)/ 10);
    this.girth1Control = new EllipseControl({
      scene: this.scene,
      camera: this.camera,
      container: this.container,
      points: this.points,
      repaint: this.repaint,
      getConfig: this.props.getConfig,
      isDark: this.props.isDark,
      moveWithCtrl: !this.props.useMouseLeftButtonForEdit,
      onMovingChange: (moving) => this.setState({ ...this.state, moving }),
      onChange: this.props.onGirth1Change,
      height: 1,
      pointSize: 5,
      zoom: zoomValue,
      name: "girth1",
      girthClippingPlane: this.girthViewClippingPlane
    });
    this.girth1Control.setPosition(this.props.position, this.props.girth1);
    this.semanticControls.push(this.girth1Control);
  }

  updateRatio = () => {
    const container =
      this.container || document.getElementById(this.scene.uuid);

    if (!this.container)
      return console.warn(
        "this.container is undefined, this is probably due to the hot reloader",
        this
      );
    if (!container)
      return console.error(
        "No container has been found for MovableTree.",
        this
      );

    this.aspectRatio = container.clientWidth / container.clientHeight;
    this.renderer.setSize(container.clientWidth, container.clientHeight);

    this._htmlRenderer.setSize(container.clientWidth, container.clientHeight);

    this.cameras.ORTHOGRAPHIC.left =
      -0.5 * SemanticMover.frustumSize * this.aspectRatio;
    this.cameras.ORTHOGRAPHIC.right =
      0.5 * SemanticMover.frustumSize * this.aspectRatio;
    this.cameras.ORTHOGRAPHIC.top = 0.5 * SemanticMover.frustumSize;
    this.cameras.ORTHOGRAPHIC.bottom = -0.5 * SemanticMover.frustumSize;
    this.cameras.ORTHOGRAPHIC.updateProjectionMatrix?.();

    this.controls?.update();

    this.repaint();
  };

  updateFromProps(props) {
    if (
      this.environmentPoints &&
      (
        props.environmentPointcloud !== this.props.environmentPointcloud ||
        props.isEnvironmentVisible !== this.props.isEnvironmentVisible ||
        this.environmentPoints.visible !== props.isEnvironmentVisible
      )
    ) {
      this.environmentPoints.visible = this.props.isEnvironmentVisible;
      requestAnimationFrame(this.repaint);
    }

    if (props.environmentPointcloud !== this.props.environmentPointcloud) {
      if (this.environmentPoints) {
        this.environmentPoints.geometry.dispose();
        this.environmentPoints.material.dispose();
        this.scene.remove(this.environmentPoints);
      }

      if (this.props.environmentPointcloud) {
        this.environmentPoints = new THREE.Points(
          this.props.environmentPointcloud.geometry,
          this.props.environmentPointcloud.createMaterial(
            THREE,
            this.props.pointSize ?? 0.5
          )
        );
        this.environmentPoints.up.set(0, 0, 1);
        this.scene.add(this.environmentPoints);
      }

      requestAnimationFrame(this.repaint);
    }

    if (props.trunkHeight !== this.props.trunkHeight) {
      this._firstBifurcationClippingMaskControl?.setPosition(this.props.trunkHeight);
    }

    if (
      props.exactFirstBifurcation?.x !== this.props.exactFirstBifurcation?.x ||
      props.exactFirstBifurcation?.y !== this.props.exactFirstBifurcation?.y
    ) {
      this._firstBifurcationClippingMaskControl?.setPosition(this.props.trunkHeight, this.props.exactFirstBifurcation);
    }

    if (props.background !== this.props.background) {
      this.scene.background = new THREE.Color(
        this.props.background || 0x000000
      );
      requestAnimationFrame(this.repaint);
    }

    if (props.pointcloud !== this.props.pointcloud) {
      if (this.points) {
        this.points.geometry.dispose();
        this.points.material.dispose();
        this.scene.remove(this.points);
      }

      let boundingSphere;
      // pointcloud can be false
      if (this.props.pointcloud) {
        this.pointcloud = this.props.pointcloud;
        const points = new THREE.Points(
          this.props.pointcloud.geometry,
          this.props.pointcloud.createMaterial(
            THREE,
            this.props.pointSize ?? 0.5
          )
        );
        this.points = points;
        this.points.up.set(0, 0, 1);
        this.semanticControls.forEach((c) => (c.points = this.points));

        this.points.geometry.computeBoundingBox();

        this.scene.add(this.points);

        boundingSphere = points.geometry.boundingSphere;
      } else {
        boundingSphere = new THREE.Sphere(
          new THREE.Vector3(...this.props.position),
          8
        );
      }

      // Set orbit target and camera position on first pointcloud load
      if (this.controls && !props.pointcloud) {
        this.controls.target.set(
          boundingSphere.center.x,
          boundingSphere.center.y,
          boundingSphere.center.z
        );
        if (this.props.isUp) {
          this.camera.position.z = girthViewDefaultZ;
        } else {
          this.camera.position.set(
            boundingSphere.center.x + boundingSphere.radius * 5,
            boundingSphere.center.y + boundingSphere.radius * 5,
            boundingSphere.center.z
          );
          this.centerHeight = boundingSphere.center.z;
        }
      }
    }

    if (props.editing !== this.props.editing) {
      this.setFilter(true);

      if (props.pointcloud !== this.props.pointcloud) {
        this.setFilter(true);

        this.controls.update();
        requestAnimationFrame(this.repaint);
      }

      if (this.props.editing === "girth1") {
        this._firstBifurcationPositionControl?.remove();
        this._firstBifurcationPositionControl = null;
        this._createControlsForGirth1();
        this._filterPointsOfPointCloud(this.girthViewClippingPlane.bottom, this.girthViewClippingPlane.top);
        requestAnimationFrame(this.repaint);
      }

      if (
        this.props.isUp &&
        !this.userActivity &&
        this.camera?.position &&
        this.points?.geometry
      ) {
        if (this.props.editing === "girth1") {
          this.camera.position.y = this.girth1Control.object.position.y;
          this.camera.position.x = this.girth1Control.object.position.x;

          this.controls.target.set(this.props.position.x, this.props.position.y, girthViewDefaultZ);
        }

        if (this.props.editing === "canopy") {
          this.camera.position.y = this.points.geometry.boundingSphere.center.y;
          this.camera.zoom =
            SemanticMover.frustumSize / this.points.geometry.boundingBox.max.x -
            0.2;
        }

        if (this.props.editing === "firstBifurcation") {
          this.controls.target.set(this.props.position.x, this.props.position.y, girthViewDefaultZ);
        }
      }

      if (this.props.editing === "firstBifurcation") {
        this.girth1Control = null;
        this.semanticControls.forEach((it) => this.scene.remove(it.object));
        this._firstBifurcationPositionControl = new SinglePointControl({
          scene: this.scene,
          camera: this.camera,
          container: this.container,
          onChange: (x, y) =>
            this.props.onExactFirstBifurcationChange({ x, y }),
          onMovingChange: (moving) => this.setState({ ...this.state, moving }),
          repaint: () => this.repaint(),
        });
        this._firstBifurcationPositionControl.setPosition(
          new THREE.Vector3(
            this.props.exactFirstBifurcation.x,
            this.props.exactFirstBifurcation.y,
            this.props.exactFirstBifurcation.z
          )
        );
        this.semanticControls = [this._firstBifurcationPositionControl];

        const parsedTrunkHeight = parseFloat(this.props.trunkHeight); // trunkHeight can sometimes come as string value
        const firstBifurcationPointsfilter = {
          min: props.exactFirstBifurcation?.min ?? parsedTrunkHeight - exactFirstBifurcationRangeOffset,
          max: props.exactFirstBifurcation?.max ?? parsedTrunkHeight + exactFirstBifurcationRangeOffset,
        };
        this._filterPointsOfPointCloud(firstBifurcationPointsfilter.min, firstBifurcationPointsfilter.max);
      }

      requestAnimationFrame(this.repaint);
    }

    // PointCloud updates
    if (
      (props.pointcloud !== this.props.pointcloud ||
        props.environmentPointcloud !== this.props.environmentPointcloud) &&
      this.props.environmentPointcloud &&
      this.pointcloud
    ) {
      const treeMins = new THREE.Vector3(...this.pointcloud.pc.mins);
      const envMins = new THREE.Vector3(
        ...this.props.environmentPointcloud.pc.mins
      );
      const diffBetweenTreeAndEnvironment = envMins.clone().sub(treeMins);

      this.environmentPoints.position.copy(diffBetweenTreeAndEnvironment);

      requestAnimationFrame(this.repaint);
    }

    this._reactToPointSizeChanges(props.pointSize);
    this._reactToOpacityChanges(props.opacities, this.pointcloud);

    // Color updates
    if (props.colors !== this.props.colors) {
      this._reactToColorChanges(this.pointcloud);
    }

    // View updates
    if (this.props.view !== props.view) {
      this._firstBifurcationClippingMaskControl?.toggleVisibility(
        this.props.view === VIEW.FIRST_BIFURCATION
      );
      requestAnimationFrame(this.repaint);
    }

    // ExactBifurcation vertical updates
    const didChangeFirstExactBifurcationVertically =
      this.props.exactFirstBifurcation?.min !==
        props.exactFirstBifurcation?.min ||
      this.props.exactFirstBifurcation?.max !==
        props.exactFirstBifurcation?.max;

    if (
      this.props.editorName === "firstBifurcation" &&
      didChangeFirstExactBifurcationVertically &&
      this.props.view === VIEW.FIRST_BIFURCATION
    ) {
      this._filterPointsOfPointCloud(
        this.props.exactFirstBifurcation.min,
        this.props.exactFirstBifurcation.max
      );
      requestAnimationFrame(this.repaint);
    }

    // ExactBifurcation updates
    const didChangeFirstExactBifurcation =
      this.props.exactFirstBifurcation?.x !== props.exactFirstBifurcation?.x ||
      this.props.exactFirstBifurcation?.y !== props.exactFirstBifurcation?.y ||
      this.props.exactFirstBifurcation?.z !== props.exactFirstBifurcation?.z;

    if (didChangeFirstExactBifurcation) {
      this._firstBifurcationPositionControl?.setPosition(
        new THREE.Vector3(
          this.props.exactFirstBifurcation.x,
          this.props.exactFirstBifurcation.y,
          this.props.exactFirstBifurcation.z
        )
      );
    }

    const newState = { ...this.state };

    // ActionMode updates
    if (props.actionMode !== this.props.actionMode) {
      newState.actionMode = this.props.actionMode;

      const semanticControl = this.getControl();
      this.semanticControls.forEach((control) => {
        if (!this.props.isUp) {
          control.setVisibility(this.props.actionMode === ValidationTool.View);
        } else {
          if (semanticControl === control) {
            control.setVisibility(true);
          }
        }
      });
    }

    // ErrorPoint updates
    if (this.state.errorPoints !== this.props.errorPoints) {
      try {
        this._reactToErrorPointChanges(this.props.errorPoints);
        newState.errorPoints = this.props.errorPoints;
      } catch (err) {
        if (err.message === GeometryError.InterSectionError) {
          this.props.showToast("Point cannot intersect the polygon!", "error");
          this.props.handleErrorUndo();
        }
      }
    }

    // ReclassPoint updates
    if (this.state.reclassPoints !== this.props.reclassPoints) {
      try {
        this._reactToReclassPointChanges(this.props.reclassPoints);
        newState.reclassPoints = this.props.reclassPoints;
      } catch (err) {
        if (err.message === GeometryError.InterSectionError) {
          this.props.showToast("Point cannot intersect the polygon!", "error");
        }
        this.props.updateReclassPoints(this.state.reclassPoints);
      }
    }

    // Do reclassify
    if (this.state.reclassification.loading) {
      let newGeom;

      if (this.props.reclassTool === RECLASS_TOOLS.BRUSH) {
        newGeom = this.reclassify(this.state.reclassification.mousePos);
      } else if (this.props.reclassTool === RECLASS_TOOLS.POLYGON) {
        if (!this.polygonEndTarget) {
          this.props.showToast("Polygon must end in starting point!", "error");
          newState.reclassification.loading = false;
        } else {
          newGeom = this.reclassifyPolygon();
          this.props.updateReclassPoints([]);
          this.handlePolygonToolFinishedCleanUp();
        }
      }

      if (newGeom) {
        this.props.updateGeometry(newGeom);
        newState.reclassification = {
          mousePos: { x: 0, y: 0 },
          loading: false,
        };
      }
    }

    // PointCloud color updates
    if (props.pointcloud !== this.props.pointcloud) {
      this._reactToColorChanges(this.props.pointcloud);
    }

    // Udate state
    this.setState(newState);

    // adjust tree location sphere size
    if (this.props.editorName === 'height') {
      const scale = Math.min(0.4 / this.camera.zoom);
      this.sphere.scale.set(scale, scale, scale);
    }
  }

  _reactToOpacityChanges(previousOpacityPreferences, pc) {
    if (
      !this.props.opacities ||
      this.props.opacities === previousOpacityPreferences
    )
      return;
    const pointCount = pc?.geometry?.attributes?.color?.count;
    if (pointCount) {
      const visibilities = new Float32Array(pointCount);

      const classification = pc.geometry.getAttribute("classification");

      for (let i = 0; i < pointCount; i++) {
        visibilities[i] = this.isPointCanopy(classification.array[i])
          ? this.props.opacities.canopy
          : this.props.opacities.trunk;
      }

      pc.geometry.setAttribute(
        "visibility",
        new THREE.Float32BufferAttribute(visibilities, 1)
      );
      requestAnimationFrame(this.repaint);
    }
  }

  _reactToColorChanges(pc) {
    const pointCount = pc?.pc.pointCount;
    if (pointCount) {
      const classColors = {};

      const colors = new Float32Array(pointCount * 3);
      const classification = pc?.geometry.getAttribute("classification");

      for (const [index, pointClass] of classification.array.entries()) {
        let rgb = [255, 255, 255];
        if (Object.keys(classColors).includes(pointClass)) {
          rgb = classColors[pointClass];
        } else {
          const pointClassLabel = getPointClassLabel(pointClass);
          const hexOverride = this.props.colors[pointClassLabel];
          rgb = getRgbColorByClassification(pointClass, hexOverride) ?? [
            255, 255, 255,
          ];
          classColors[pointClass] = rgb;
        }

        colors[3 * index] = rgb[0] / 255;
        colors[3 * index + 1] = rgb[1] / 255;
        colors[3 * index + 2] = rgb[2] / 255;
      }

      const newColors = new THREE.Float32BufferAttribute(colors, 3);
      this.pointcloud.geometry.setAttribute("color", newColors);
      this.points.geometry.setAttribute("color", newColors);
      requestAnimationFrame(this.repaint);
    }
  }

  _reactToPointSizeChanges(previousPointSize) {
    if (
      !this.pointcloud ||
      !this.props.pointcloud ||
      previousPointSize === this.props.pointSize
    )
      return;
    this.points.material = this.pointcloud.createMaterial(
      THREE,
      this.props.pointSize ?? 0.5
    );

    if (this.props.editing === "girth1") {
      this._filterPointsOfPointCloud(this.girthViewClippingPlane.bottom, this.girthViewClippingPlane.top);
    }

    if (this.props.editing === "firstBifurcation") {
      this._filterPointsOfPointCloud(
        this.props.exactFirstBifurcation.min,
        this.props.exactFirstBifurcation.max
      );
    }

    requestAnimationFrame(this.repaint);
  }

  _reactToPolygonChanges = ({ points, color, plane, setPolygon, setLine }) => {
    this.scene.remove(this.scene.getObjectByName("IntersectionMarker"));
    this.scene.remove(this.scene.getObjectByName("StartPoint"));

    if (!points.length || this.props.isUp) {
      this.scene.remove(this.scene.getObjectByName("LineObject"));
      return;
    }

    if (points.length === 1) {
      this.scene.remove(this.scene.getObjectByName("LineObject"));

      const circle = new THREE.CircleGeometry(2 / this.camera.zoom / 30, 20);
      const point = new THREE.Mesh(
        circle,
        new THREE.MeshBasicMaterial({ color })
      );
      point.rotation.copy(this.camera.rotation);
      point.position.add(points[0].point);
      point.name = "StartPoint";

      this.renderObjectOnTop(point);
      this.scene.add(point);

      requestAnimationFrame(this.repaint);
      return;
    }

    plane = plane ?? this.computeCameraPlane(points[0].point);
    const { poly, line, error } = this.projectPointsToPolygon(points, plane);
    if (error) throw new Error(error);

    const polygonLineObject = new THREE.Line(
      line,
      new THREE.LineBasicMaterial({ color, linewidth: 2 })
    );
    polygonLineObject.name = "LineObject";

    const LineObject = this.scene.getObjectByName("LineObject");
    if (LineObject) this.scene.remove(LineObject);

    this.renderObjectOnTop(polygonLineObject);
    this.scene.add(polygonLineObject);

    setLine(polygonLineObject);
    setPolygon(poly);

    requestAnimationFrame(this.repaint);
  };

  _reactToReclassPointChanges = (points) => {
    const setReclassPoly = (polygon) => (this.reclassPoly = polygon);
    const setReclassLineObject = (line) => (this.reclassLineObject = line);
    this._reactToPolygonChanges({
      points,
      color: SemanticMover.reclassifyLineHexcolor,
      plane: this.reclassPlane,
      setPolygon: setReclassPoly,
      setLine: setReclassLineObject,
    });
  };

  _reactToErrorPointChanges = (points) => {
    this.scene.remove(this.scene.getObjectByName("error"));
    this.scene.remove(this.scene.getObjectByName("error-edges"));

    let obj;

    switch (this.props.errorTool) {
      case ERROR_TOOLS.POINT:
        if (!points.length || this.props.isUp) return;

        const circle = new THREE.CircleGeometry(7 / this.camera.zoom / 30, 20);
        obj = new THREE.Mesh(
          circle,
          new THREE.MeshBasicMaterial({ color: 0xff0000 })
        );
        obj.rotation.copy(this.camera.rotation);
        obj.position.add(points[0].point);

        this.renderObjectOnTop(obj);
        obj.name = "error";
        this.scene.add(obj);
        break;

      case ERROR_TOOLS.LINE:
        if (!points.length || this.props.isUp) return;

        const line = new THREE.BufferGeometry().setFromPoints(
          points.map((p) => p.point)
        );
        obj = new THREE.Line(
          line,
          new THREE.LineBasicMaterial({ color: 0xff0000 })
        );

        this.renderObjectOnTop(obj);
        obj.name = "error";
        this.scene.add(obj);
        break;

      case ERROR_TOOLS.POLYGON:
        const setErrorPoly = (polygon) => (this.errorPoly = polygon);
        const setErrorLineObject = (line) => (this.errorLineObject = line);
        this._reactToPolygonChanges({
          points,
          color: SemanticMover.errorLineHexColor,
          setPolygon: setErrorPoly,
          setLine: setErrorLineObject,
        });
        return;

      default:
    }

    requestAnimationFrame(this.repaint);
  };

  projectPointsToPolygon = (points, plane) => {
    const projectedPoints = projectPointsToPlane(points, plane);

    const line = new THREE.BufferGeometry().setFromPoints(projectedPoints);
    if (points.length < 3) return { line };

    const polygon2DPoints = transformPointsToTwoDimensionalPlane({
      points: projectedPoints,
      plane,
      scene: this.scene,
    });

    if (!this.polygonEndTarget) {
      const intersectionOnPolygonEdges =
        isLastLineIntersectingPreviousLines(polygon2DPoints);
      if (intersectionOnPolygonEdges) {
        console.error(
          "New line is intersecting the polygon",
          intersectionOnPolygonEdges
        );
        return { error: GeometryError.InterSectionError };
      }
    }

    return {
      poly: new THREE.BufferGeometry().setFromPoints(projectedPoints),
      line,
    };
  };

  computeCameraPlane = (point) => {
    const target = new THREE.Vector3();
    const cameraVector = this.camera.getWorldDirection(target);

    return new THREE.Plane().setFromNormalAndCoplanarPoint(
      cameraVector.normalize(),
      point
    );
  };

  renderObjectOnTop = (obj) => {
    obj.renderOrder = 999;
    obj.material.depthTest = false;
    obj.material.depthWrite = false;
    obj.onBeforeRender = function (renderer) {
      renderer.clearDepth();
    };
  };

  /**
   *
   * @param {number} minHeight
   * @param {number} maxHeight
   * @private
   */
  _filterPointsOfPointCloud(minHeight, maxHeight) {
    if (!this.points) return;
    this.points.material.userData.filterPoints({
      minZ: minHeight,
      maxZ: maxHeight,
    });
    this.points.material.needsUpdate = true;
  }

  setZoom = (zoom = 0.5) => {
    const semanticControl = this.getControl();

    if (!semanticControl) {
      if (zoom && this.camera.zoom !== zoom) {
        this.camera.zoom = zoom;
        this.camera.updateProjectionMatrix();
        this.repaint();
      }
    }
  };

  setFilter = (reset) => {
    const semanticControl = this.getControl();

    if (!semanticControl && this.points) {
      let newFilter;
      if (!!(!this.props.isUp && this.props.editing === "girth1")) {
        newFilter = {
          minZ: this.girthViewClippingPlane.bottom,
          maxZ: this.girthViewClippingPlane.top,
        };
      } else if (reset) {
        newFilter = null;
      }

      if (typeof newFilter !== "undefined") {
        this.points.material.userData.filterPoints(newFilter);
        this.points.material.needsUpdate = true;
      }
    }
  };

  componentDidUpdate = (prevProps) => {
    this.updateFromProps(prevProps);
  };

  repaint = () => {
    if (this._firstBifurcationClippingMaskControl && this.controls)
      this._rotateClippingMaskControlsTowardsCamera();
    return requestAnimationFrame(() => this.forceUpdate());
  };

  _rotateClippingMaskControlsTowardsCamera() {
    this._firstBifurcationClippingMaskControl.setRotation(this.camera.rotation);
  }

  getControl() {
    if (!this.semanticControls || this.props.uneditable) {
      return null;
    }

    switch (this.props.editing) {
      case "height":
        return this.heightControl;
      case "trunkHeight":
        return this.trunkHeightControl;
      case "canopyHeight":
        return this.canopyHeightControl;
      case "girth1":
        return this.girth1Control;
      case "canopy":
        return this.canopyControl;
      case "firstBifurcation":
        return this._firstBifurcationPositionControl;
      default:
        return null;
    }
  }

  handleKeyPress = (e) => {
    switch (this.state.actionMode) {
      case ValidationTool.View:
        if (e.key === "r") {
          this.handleRotation(1);
        } else if (e.key === "t") {
          this.handleRotation(-1);
        }

        if (!this.props.isUp) {
          switch (e.key) {
            case "q":
              this.props.setEditing("height");
              break;
            case "w":
              this.props.setEditing("canopyHeight");
              break;
            case "e":
              this.props.setEditing("trunkHeight");
              break;
            default:
          }
        }
        break;

      case ValidationTool.Error:
        switch (e.key) {
          case "Escape":
            this.props.cancelError();
            break;
          default:
        }
        break;

      case ValidationTool.Reclassify:
        switch (e.key) {
          case "1":
          case "2":
          case "3":
          case "4":
          case "5":
            const newBrushRadius = parseInt(e.key) * 12;
            this.props.setBrushRadius(newBrushRadius);
            break;
          case "Enter":
            // if (
            //   this.props.reclassTool === RECLASS_TOOLS.POLYGON &&
            //   this.props.reclassPoints.length > 2 &&
            //   this.props.reclassifySource !== this.props.reclassifyTarget
            // ) {
            //   this.setState({
            //     ...this.state,
            //     reclassification: {
            //       loading: true,
            //       mousePos: {
            //         x: null,
            //         y: null,
            //       },
            //     },
            //   });
            // }
            // This option is not working, removing until the fix will be issued
            // this.props.saveLAZ();
            break;
          case "Escape":
            this.props.updateReclassPoints([]);
            break;
          default:
        }
        break;
      default:
    }
  };

  handleRotation = (direction) => {
    this.props.setActiveEditor("");
    const angle = this.controls.getAzimuthalAngle() + (Math.PI * direction) / 3;
    this.props.setAzimuthAngle(angle);
    this.updateCameraAzimuthAngle(angle);
    this.repaint();
  };

  componentWillUnmount = () => {
    window.removeEventListener("keydown", this.handleKeyPress);
  };

  handlePointerUp = (e, semanticControl) => {
    if (
      e.button === 0 &&
      this.state.actionMode === ValidationTool.Reclassify &&
      this.props.reclassTool === RECLASS_TOOLS.BRUSH
    ) {
      if (this.props.reclassifySource === this.props.reclassifyTarget) return;
      this.props.setActiveEditor(this.props.editorName);
      this.setState({
        ...this.state,
        reclassification: {
          loading: true,
          mousePos: {
            x: e.clientX,
            y: e.clientY,
          },
        },
      });
      return;
    }

    if (semanticControl) {
      semanticControl.handlePointerUp(e);
    }

    this._firstBifurcationClippingMaskControl?.handlePointerUp(e);

    this.props.setEditing("");
  };

  reclassify = (mousePos) => {
    const rect = this.container.getBoundingClientRect();
    const brushOffset = (this.props.brushRadius / rect.width) * 2;
    const mouse = new THREE.Vector2(
      ((mousePos.x - rect.left + this.props.brushRadius) / rect.width) * 2 - 1,
      -((mousePos.y - rect.top + this.props.brushRadius) / rect.height) * 2 + 1
    );

    const brushWorldRadius = this.getBrushWorldRadius(mouse, brushOffset);
    const newGeometry = cloneDeep(this.pointcloud.geometry);
    const classification = newGeometry.getAttribute("classification");
    const positions = newGeometry.getAttribute("position").array;

    const changes = {
      targetClass: this.props.reclassifyTarget,
      indices: [],
    };

    this.raycaster.setFromCamera(mouse, this.camera);

    // Iterate through the indexes of the source points
    for (let i = 0; i < classification.array.length; i++) {
      const pointClass = classification.array[i];
      if (pointClass !== this.props.reclassifySource) continue;

      const point = new THREE.Vector3(
        positions[i * 3],
        positions[i * 3 + 1],
        positions[i * 3 + 2]
      );
      if (this.raycaster.ray.distanceToPoint(point) > brushWorldRadius) {
        continue;
      }

      classification.array[i] = this.props.reclassifyTarget;
      changes.indices.push(i);
    }
    return { geometry: newGeometry, changes };
  };

  reclassifyPolygon = () => {
    const changes = {
      targetClass: this.props.reclassifyTarget,
      indices: [],
    };

    const newGeometry = cloneDeep(this.pointcloud.geometry);
    const classification = newGeometry.getAttribute("classification");
    const positions = newGeometry.getAttribute("position").array;
    const reclassPolyVertices = this.reclassPoly.getAttribute('position').array

    const origo2D = new THREE.Vector3(...reclassPolyVertices.slice(0, 3));
    const pNormal = new THREE.Vector3().copy(origo2D);
    pNormal.add(this.reclassPlane.normal);

    const mRotation = new THREE.Matrix4().lookAt(
      origo2D,
      pNormal,
      new THREE.Vector3(0, 1, 0)
    );

    // Create a new 2D coordinate system in the plane of the polygon
    const plane2D = new THREE.Group();
    this.scene.add(plane2D)
    plane2D.position.copy(origo2D);
    plane2D.quaternion.copy(
      new THREE.Quaternion().setFromRotationMatrix(mRotation)
    );
    plane2D.updateMatrix();

    // Get the inverse of the planes matrix4 - will use it to rebase points
    // into the new coordinate system
    const inverse = new THREE.Matrix4().copy(plane2D.matrix).invert();

    // The polygon's vertices in the new coordinate system
    const polygon2D = []
    for (let i = 0; i < reclassPolyVertices.length; i += 3) {
      const p = new THREE.Vector3(
        reclassPolyVertices[i],
        reclassPolyVertices[i + 1],
        reclassPolyVertices[i + 2]
      );
      p.applyMatrix4(inverse);
      p.set(p.x, p.y, 0);
      polygon2D.push(p);
    }

    let target = new THREE.Vector3();
    for (let i = 0; i < classification.array.length; i++) {
      const pointClass = classification.array[i];
      if (pointClass !== this.props.reclassifySource) continue;

      // Project point to the plane of the polygon
      const point2D = this.reclassPlane.projectPoint(
        new THREE.Vector3(
          positions[i * 3],
          positions[i * 3 + 1],
          positions[i * 3 + 2]
        ),
        target
      );

      // Rebase point vector to the 2D coordinate system of the polygon plane
      point2D.applyMatrix4(inverse);
      point2D.set(point2D.x, point2D.y, 0);
      // If point is in polygon, reclassify
      if (isPointInPolygon(polygon2D, point2D)) {
        classification.array[i] = this.props.reclassifyTarget;
        changes.indices.push(i);
      }
    }

    this.scene.remove(plane2D);
    return { geometry: newGeometry, changes };
  };

  getBrushWorldRadius = (mouse, offset) => {
    const leftPos = { ...mouse, x: mouse.x - offset };
    this.raycaster.setFromCamera(leftPos, this.camera);

    const point1 = new THREE.Vector3();
    point1.addVectors(
      this.raycaster.ray.origin,
      this.raycaster.ray.direction.normalize()
    );

    const rightPos = { ...mouse, x: mouse.x + offset };
    this.raycaster.setFromCamera(rightPos, this.camera);

    const point2 = new THREE.Vector3();
    point2.addVectors(
      this.raycaster.ray.origin,
      this.raycaster.ray.direction.normalize()
    );

    return point1.distanceTo(point2) / 2;
  };

  isPointCanopy = (pointClass) => [21, 23].includes(pointClass);

  getLastPointInPolygon = (event, points) => {
    if (points.length < 3) return this.getMousePoint(event);

    const firstPoint = points[0].point;
    const matchingVectors = points.filter((intersection) =>
      areVectorsEqual(firstPoint, intersection.point, 5)
    );
    if (matchingVectors.length > 1) return null;

    if (!this.polygonEndTarget) return this.getMousePoint(event);

    const lastPoint = points[points.length - 1].point;
    if (lastPoint.equals(this.polygonEndTarget.point)) return null;

    return this.polygonEndTarget;
  };

  handlePointerDown = (e, semanticControl) => {
    if (
      e.button === 0 &&
      this.state.actionMode === ValidationTool.Reclassify &&
      this.props.reclassTool === RECLASS_TOOLS.POLYGON
    ) {
      const point = this.getLastPointInPolygon(e, this.state.reclassPoints);
      if (!point) return;

      const newPoints = [...this.state.reclassPoints, point];
      this.props.updateReclassPoints(newPoints);

      const plane = this.computeCameraPlane(newPoints[0].point);
      this.reclassPlane = plane;
    } else if (
      e.button === 0 &&
      this.state.actionMode === ValidationTool.Error &&
      this.props.errorTool === ERROR_TOOLS.POLYGON
    ) {
      const point = this.getLastPointInPolygon(e, this.state.errorPoints);
      if (!point) return;

      this.props.addErrorPoint(point);
    } else if (e.button === 0 && this.state.actionMode === ValidationTool.Error) {
      const point = this.getMousePoint(e);
      if (!point) return;
      this.props.addErrorPoint(point);
    } else if (this.state.actionMode === ValidationTool.View) {
      this._firstBifurcationClippingMaskControl?.handlePointerDown(e);
    }

    this.userActivity = true;
    this.props.setActiveEditor(this.props.editorName);

    if (semanticControl && !grabAbleControls.includes(semanticControl.name)) {
      if (!semanticControl.object.visible) return;

      semanticControl.handlePointerDown(e);
    } else {
      this.handleGrabbing(e);
    }
  };

  handleGrabbing = (e) => {
    const rect = this.container.getBoundingClientRect();
    const mouse = {
      x: ((e.clientX - rect.left) / rect.width) * 2 - 1,
      y: -((e.clientY - rect.top) / rect.height) * 2 + 1,
    };
    this.raycaster.setFromCamera(mouse, this.camera);
    const intersectables = this.scene.children.filter((child) => child.name !== "IntersectionMarker");
    const intersects = this.raycaster.intersectObjects(intersectables);
    const nonPointObjects = intersects.filter((object) => object.object.type !== "Points");
    this.handleGrabbingControl(e, nonPointObjects);
  };

  handleGrabbingControl = (e, lines) => {
    let semanticControl = null;
    if (lines.length <= 0) return;
    if (!lines[0].object.visible) return;

    this.props.setEditing(lines[0].object.name);
    switch (lines[0].object.name) {
      case "height":
        semanticControl = this.heightControl;
        break;
      case "trunkHeight":
        semanticControl = this.trunkHeightControl;
        break;
      case "canopyHeight":
        semanticControl = this.canopyHeightControl;
        break;
      default:
    }

    if (semanticControl) semanticControl.handlePointerDown(e);
  };

  updateCameraAngle = () => {
    if (this.controls) {
      const angle = parseFloat(this.props.azimuthAngle.toFixed(2));
      const thisAngle = parseFloat(
        this.controls.getAzimuthalAngle().toFixed(2)
      );
      if (
        this.props.activeEditor === this.props.editorName &&
        angle !== thisAngle
      ) {
        this.props.setAzimuthAngle(thisAngle);
      } else {
        this.updateCameraAzimuthAngle(this.props.azimuthAngle);
      }
    }
  };

  updateCameraAzimuthAngle = (angle) => {
    const orig = [this.controls.minAzimuthAngle, this.controls.maxAzimuthAngle];

    this.controls.minAzimuthAngle = angle;
    this.controls.maxAzimuthAngle = angle;
    this.controls.update();

    this.controls.minAzimuthAngle = orig[2];
    this.controls.maxAzimuthAngle = orig[3];

    let constriction = {};

    if (this.props.isUp) {
      constriction.polar = { min: 0, max: 0 };
    } else if (this.props.lockView) {
      const azimuth = this.controls.getAzimuthalAngle();
      const polar = this.controls.getPolarAngle();
      const zoom = this.camera.zoom;
      constriction.polar = { min: polar, max: polar };
      constriction.azimuth = { min: azimuth, max: azimuth };
      constriction.zoom = { min: zoom, max: zoom };
    } else if (this.state.actionMode === ValidationTool.View) {
      constriction.polar = { min: Math.PI / 2, max: Math.PI / 2 };
    }

    this.constrictCamera(constriction);

    this.controls.update();
  };

  constrictCamera = ({ azimuth, polar, zoom }) => {
    this.controls.minAzimuthAngle = azimuth?.min ?? 0;
    this.controls.maxAzimuthAngle = azimuth?.max ?? Infinity;
    this.controls.minPolarAngle = polar?.min ?? 0;
    this.controls.maxPolarAngle = polar?.max ?? Infinity;

    this.controls.minZoom = zoom?.min ?? SemanticMover.minZoom;
    const maxZoomDefault = ["girth", "firstBifurcation"].includes(this.props.editorName) ? 30 : SemanticMover.maxZoom;
    this.controls.maxZoom = zoom?.max ?? maxZoomDefault;
  };

  getMousePoint = (e) => {
    if (e?.detail) return;
    const rect = this.container.getBoundingClientRect();
    const mouse = {
      x: ((e.clientX - rect.left) / rect.width) * 2 - 1,
      y: -((e.clientY - rect.top) / rect.height) * 2 + 1,
    };
    this.raycaster.setFromCamera(mouse, this.camera);

    const intersects = this.raycaster.intersectObjects(this.scene.children);
    const points = intersects.filter(
      (object) => object.object.type === "Points"
    );
    if (points.length <= 0) return;

    const closestPoint = points.reduce((prev, current) =>
      prev.distanceToRay < current.distanceToRay ? prev : current
    );
    return closestPoint;
  };

  handleOnDoubleClick = (e) => {
    if (e.button !== 0 || e.detail !== 2) return;
    if (
      this.state.actionMode === ValidationTool.Error &&
      this.props.errorPoints.length
    ) {
      this.props.presentModal({
        title: "Add comment to error",
        content: (
          <CommentTree
            key={`comment-error-modal-${this.props.tree?.id}`}
            onComment={(comment) => {
              let worldDirection = new THREE.Vector3();
              this.camera.getWorldDirection(worldDirection);
              this.props.saveError(
                comment,
                this.controls.getAzimuthalAngle(),
                this.controls.getPolarAngle(),
                this.camera.zoom,
                this.camera.getWorldPosition(),
                worldDirection.normalize()
              );
            }}
            onCancel={() => {
              this.props.cancelError();
            }}
            tree={this.props.tree}
            isErrorMode={true}
          />
        ),
      });
    } else if (
      this.state.actionMode === ValidationTool.Reclassify &&
      this.props.reclassTool === RECLASS_TOOLS.POLYGON &&
      this.props.reclassPoints.length > 2 &&
      this.props.reclassifySource !== this.props.reclassifyTarget
    ) {
      this.setState({
        ...this.state,
        reclassification: {
          loading: true,
          mousePos: {
            x: null,
            y: null,
          },
        },
      });
    }
  };

  handlePolygonToolFinishedCleanUp = () => {
    if (this.intersectionMarker) {
      this.scene.remove(this.intersectionMarker);
      this.intersectionMarker = null;
    }

    if (this.polygonEndTarget) this.polygonEndTarget = null;

    if (this.reclassLineObject) {
      this.scene.remove(this.reclassLineObject);
      this.reclassLineObject = null;
    }

    if (this.errorLineObject) {
      this.scene.remove(this.errorLineObject);
      this.errorLineObject = null;
    }

    this.scene.remove(this.scene.getObjectByName("StartPoint"));
  };

  handleLineToolStartPointAndPointerIntersectionDetection = (
    pointer,
    points,
    color
  ) => {
    this.polygonEndTarget = null;

    const cameraZoomRange = {
      min: SemanticMover.minZoom,
      max: SemanticMover.maxZoom,
    };
    const polygonToolSnapThresholdRange = {
      min: polygonToolMinSnapThreshold,
      max: polygonToolMaxSnapThreshold,
    };
    const raycastThreshold = convertValueBetweenRanges(cameraZoomRange, polygonToolSnapThresholdRange, this.camera.zoom);

    const raycaster = new THREE.Raycaster();
    raycaster.params.Points.threshold = raycastThreshold;
    raycaster.setFromCamera(pointer, this.camera);

    const firstPointPositions = points.slice(0, 3);
    const targetPoint = new THREE.Vector3(...firstPointPositions);

    if (!this.intersectionMarker) {
      this.intersectionMarker = createCircle({ name: "IntersectionMarker" });
      this.scene.add(this.intersectionMarker);
    }

    const targetObject = createTargetPointObject(targetPoint);
    this.scene.add(targetObject);

    const intersects = raycaster.intersectObject(targetObject);
    if (intersects.length > 0) {
      const target = intersects.find(
        (obj) => obj.object.name === targetObject.name
      );
      if (target) {
        const scale = 0.8 / this.camera.zoom;
        this.intersectionMarker.scale.set(scale, scale, scale);
        this.intersectionMarker.position.copy(targetPoint);
        this.intersectionMarker.material.color.setHex(color);
        this.intersectionMarker.rotation.copy(this.camera.rotation);
        this.intersectionMarker.visible = true;
        this.renderObjectOnTop(this.intersectionMarker);
        this.polygonEndTarget = target;
        this.polygonEndTarget.point = targetPoint;
      }
    } else {
      const sphere = this.scene.getObjectByName(this.intersectionMarker.name);
      if (sphere) {
        this.polygonEndTarget = null;
        this.intersectionMarker = null;
        this.scene.remove(sphere);
      }
    }

    this.scene.remove(targetObject);
    requestAnimationFrame(this.repaint);
  };

  handlePointerPositionDuringPolygonToolUsage = (event) => {
    if (
      (this.reclassLineObject &&
        this.state.actionMode === ValidationTool.Reclassify) ||
      (this.errorLineObject && this.state.actionMode === ValidationTool.Error)
    ) {
      const rect = this.container.getBoundingClientRect();
      const pointer = new THREE.Vector2(
        ((event.clientX - rect.left) / rect.width) * 2 - 1,
        -((event.clientY - rect.top) / rect.height) * 2 + 1
      );

      const points =
        this.state.actionMode === ValidationTool.Reclassify
          ? this.reclassLineObject.geometry.attributes.position.array
          : this.errorLineObject.geometry.attributes.position.array;
      if (points.length < 7) return; // Return until its just a line

      const color =
        this.state.actionMode === ValidationTool.Reclassify
          ? SemanticMover.reclassifyLineHexcolor
          : SemanticMover.errorLineHexColor;
      this.handleLineToolStartPointAndPointerIntersectionDetection(
        pointer,
        points,
        color
      );
    }
  };

  /**
   * @param {PointerEvent<HTMLDivElement>} event
   * @param {SemanticControl | null} semanticControl
   * @private
   */
  _onPointerMove(event, semanticControl) {
    if (semanticControl) semanticControl.handlePointerMove(event);
    this._firstBifurcationClippingMaskControl?.handlePointerMove(event);
    this.handlePointerPositionDuringPolygonToolUsage(event);
  }

  render() {
    let editorInputGroup = null;
    let upLabel = null;
    let style = {
      flex: "1",
      position: "relative",
    };

    if (this.state.actionMode === ValidationTool.Reclassify) {
      if (this.props.reclassTool === RECLASS_TOOLS.BRUSH) {
        style.cursor = this.state.reclassification.loading
          ? "not-allowed"
          : `url(/images/brush-cursor_${this.props.brushRadius}.svg), pointer`;
      } else {
        style.cursor = this.state.moving ? "grabbing" : "crosshair";
      }
    } else if (this.props.actionMode === ValidationTool.View) {
      style.cursor = this.state.moving ? "grabbing" : "grab";
    } else if (this.props.actionMode === ValidationTool.Error) {
      style.cursor = this.state.moving ? "grabbing" : "crosshair";
    }
    this.updateCameraAngle();

    if (!this.props.uneditable) {
      this.sphere?.position.copy(this.props.position);

      if (this.renderer) this.renderer.render(this.scene, this.camera);
      if (this._htmlRenderer)
        this._htmlRenderer.render(this.scene, this.camera);
    }

    const semanticControl = this.getControl();
    if (this.semanticControls && !this.props.uneditable) {
      this.semanticControls.forEach((control) => {
        control.getConfig = this.props.getConfig;
        control.isDark = this.props.isDark;
      });

      if (this.heightControl) {
        const heightControlPosition = new THREE.Vector2(
          this.points.geometry.boundingBox.getSize(new THREE.Vector3()).x / 2,
          this.points.geometry.boundingBox.getSize(new THREE.Vector3()).y / 2
        );
        this.heightControl.setPosition(heightControlPosition, this.props.height);
      }

      if (this.trunkHeightControl) {
        this.trunkHeightControl.setPosition(
          new THREE.Vector2(this.props.position.x, this.props.position.y),
          this.props.trunkHeight
        );
      }

      if (this.girth1Control) {
        this.girth1Control.setPosition(this.props.position, this.props.girth1);
      }

      if (this.canopyControl) {
        this.canopyControl.setPosition(this.props.position, this.props.canopy);
        this.canopyControl.height = this.props.height;
      }

      if (this.canopyHeightControl) {
        // canopy height is not the Z where the canopy starts but the height of the canopy (meaning: HEIGHT - Z where canopy starts)
        this.canopyHeightControl.setDifference(this.props.height);
        this.canopyHeightControl.setPosition(
          new THREE.Vector2(
            this.points.geometry.boundingBox.getSize(new THREE.Vector3()).x / 2,
            this.points.geometry.boundingBox.getSize(new THREE.Vector3()).y / 2
          ),
          this.props.canopyHeight
        );
      }
    }

    if (!this.props.isUp) {
      this.centerHeight = this.camera?.position.z;
      editorInputGroup = (
        <>
          {this.heightControl && (
            <EditorInput
              label="Tree Height: "
              editing="height"
              setEditing={this.props.setEditing}
              zoom={this.camera?.zoom}
              value={parseFloat(this.props.height)}
              offset={parseFloat(this.props.position.z)}
              center={this.centerHeight}
              onHeightChange={this.props.onHeightChange}
              isDark={this.props.isDark}
              currentEditing={this.props.editing}
              controlObject={this.heightControl}
              repaint={this.repaint}
            />
          )}
          {this.trunkHeightControl && (
            <EditorInput
              label="First Bifurcation: "
              editing="trunkHeight"
              setEditing={this.props.setEditing}
              zoom={this.camera?.zoom}
              value={this.props.trunkHeight}
              offset={parseFloat(this.props.position.z)}
              center={this.centerHeight}
              onHeightChange={this.props.onTrunkHeightChange}
              isDark={this.props.isDark}
              currentEditing={this.props.editing}
              controlObject={this.trunkHeightControl}
              repaint={this.repaint}
            />
          )}
          {this.canopyHeightControl && (
            <EditorInput
              label="Crown height: "
              editing="canopyHeight"
              setEditing={this.props.setEditing}
              zoom={this.camera?.zoom}
              value={this.props.canopyHeight}
              offset={parseFloat(this.props.position.z)}
              center={this.centerHeight}
              onHeightChange={this.props.onCanopyHeightChange}
              inverse
              isDark={this.props.isDark}
              currentEditing={this.props.editing}
              diff={this.canopyHeightControl?.diff}
              controlObject={this.canopyHeightControl}
              repaint={this.repaint}
            />
          )}
        </>
      );
    } else if (this.props.editing === "girth1") {
      upLabel = (
        <RadiusInput
          label="Girth: "
          treeValue={this.props.girth1}
          setValue={this.props.onGirth1Change}
          calculateCircumference={this.props.calculateGirth}
          top="45vh"
        />
      );
    } else if (this.props.editing === "canopy") {
      upLabel = /* (
        <RadiusInput
          label="Canopy: "
          treeValue={this.props.canopy}
          setValue={this.props.onCanopyChange}
          calculateCircumference={this.props.calculateGirth}
          top="5vh"
        />
      ) */ undefined;
    }

    return (
      <>
        {this.state.actionMode === ValidationTool.View && editorInputGroup}
        {upLabel}
        <div
          className={`tree-mover ${this.props.editorName}`}
          id={this.scene && this.scene.uuid}
          ref={(e) => (this.container = e)}
          onPointerDown={(e) => this.handlePointerDown(e, semanticControl)}
          onPointerMove={(event) => this._onPointerMove(event, semanticControl)}
          onPointerUp={(e) => this.handlePointerUp(e, semanticControl)}
          onDoubleClick={this.handleOnDoubleClick}
          style={style}
        >
          <canvas
            ref={(e) => (this.canvas = e)}
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
            }}
          />
        </div>
      </>
    );
  }
}

export default SemanticMover;
export const VIEW = {
  FIRST_BIFURCATION: "first-bifurcation",
  DEFAULT: "default",
};
