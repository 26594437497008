import { matchPath } from 'react-router';
import * as _ from "lodash";

interface Props {
    trees: any[];
    history: any;
    currentTreeId: string;
    managedAreaId: string;
    direction: 1 | -1;
    skippedTrees: string[];
}

export const navigateValidationTrees = ({ trees, history, currentTreeId, managedAreaId, direction, skippedTrees }: Props) => {
    let filteredTrees = _.cloneDeep(trees);

    if (direction === 1 && skippedTrees?.length && skippedTrees?.length < trees?.length) {
        filteredTrees = filteredTrees.filter(t => !skippedTrees.includes(t?.id));
    } else {
        filteredTrees = _.cloneDeep(trees)
    }

    const step = getCurrentValidationStepFromPath();
    const currentIndex = filteredTrees?.findIndex((item: any) => String(item.id) === currentTreeId);
    let nextIndex = Math.min(Math.max(0, currentIndex + direction), filteredTrees?.length);
    if (currentIndex === filteredTrees?.length - 1 && direction === 1) {
        nextIndex = 0;
    } else if (currentIndex === 0 && direction === -1) {
        nextIndex = filteredTrees?.length - 1;
    }
    const nextTree = filteredTrees?.[nextIndex];

    history.push(`/validation/${managedAreaId}/${step}/${nextTree?.id}`);
};

const getCurrentValidationStepFromPath = () => {
    const pathname = window.location.pathname;
    const path: any = matchPath(pathname, '/validation/:MA/:step/:id?');
    return path?.params?.step;
};
