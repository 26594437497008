import { useEffect, useState } from 'react';
import { CardProps } from '../../../@types/CardProps';
import ValidationAction from '../../../enums/ValidationAction';
import useStore from '../../../store/useStore';
import AttributeCard from '../../Card/AttributeCard';
import { PlainNumberInput } from '../../inputs/PlainNumberInput';
import SemanticActionsColorMap from '../SemanticActionsColorMap';
import { METRICS_NAMES, METRICS_VALUE_LIMITS } from './TrunkDetailsCard/Constants';
import * as _ from 'lodash';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Tooltip } from '@mui/material';

const TO_FIXED = 2;

const TreeHeightCard = ({ disabled, alerts, setAlerts }: CardProps) => {
  const draggedTool = useStore((s) => s.actions.draggedTool);
  const hasLoadedDeltaCorrection = useStore((s) => s.validation.hasLoadedDeltaCorrection);
  const { state, setState, setVisibility, visibility } = useStore((s) => s.actions.height);
  const { activeTool, setActiveTool } = useStore((s) => ({ activeTool: s.actions.activeTool, setActiveTool: s.actions.setActiveTool }));
  const viewerPositionZ = useStore((s) => s.tree.viewerPosition.z);
  const resetSectionTargetToDefault = useStore((s) => s.pointCloud.actions.section.resetSectionTargetToDefault);
  const setDefaultDepth = useStore((s) => s.pointCloud.actions.section.setDefaultDepth);
  const setIsTreeHeightCompleted = useStore((s) => s.validation.actions.setIsTreeHeightCompleted);
  const isTreeHeightCompleted = useStore((s) => s.validation.isTreeHeightCompleted);

  const min = useStore((s) => s.pointCloud.laz?.geometry.boundingBox?.min.z);
  const max = useStore((s) => s.pointCloud.laz?.geometry.boundingBox?.max.z);

  const alert = alerts.find((a) => a.name === METRICS_NAMES.TREE_HEIGHT);

  const [height, setHeight] = useState(state.toFixed(TO_FIXED));

  useEffect(() => {
    if (draggedTool !== ValidationAction.Height) return;
    setHeight((state - viewerPositionZ).toFixed(TO_FIXED));
    // Adjust height with location delta when height tool is dragged
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, viewerPositionZ]);

  useEffect(() => {
    if (!hasLoadedDeltaCorrection) return;
    setHeight((state - viewerPositionZ).toFixed(TO_FIXED));
    // Correct height with coordinate system delta after initial load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLoadedDeltaCorrection, viewerPositionZ]);

  useEffect(() => {
    const isHeightInRange = _.inRange(
      Number(height),
      METRICS_VALUE_LIMITS[METRICS_NAMES.TREE_HEIGHT].min,
      METRICS_VALUE_LIMITS[METRICS_NAMES.TREE_HEIGHT].max
    );
    if (!isHeightInRange) {
      const alreadyAlerted = alerts.find((a) => a.name === METRICS_NAMES.TREE_HEIGHT);
      
      if (!alreadyAlerted?.name) {
        setAlerts((prev) => [
          ...prev,
          {
            name: METRICS_NAMES.TREE_HEIGHT,
            message: `Tree height should be between ${METRICS_VALUE_LIMITS[METRICS_NAMES.TREE_HEIGHT].min} and ${
              METRICS_VALUE_LIMITS[METRICS_NAMES.TREE_HEIGHT].max
            } ${METRICS_VALUE_LIMITS[METRICS_NAMES.TREE_HEIGHT].unit}`,
          },
        ]);
      }
    } else {
      setAlerts((prev) => prev.filter((a) => a.name !== METRICS_NAMES.TREE_HEIGHT));
    }
  }, [height]);

  return (
    <AttributeCard
      title='Tree height'
      color={SemanticActionsColorMap[ValidationAction.Height]}
      isExpanded={visibility}
      onExpand={setVisibility}
      setCompleted={setIsTreeHeightCompleted}
      disabled={disabled}
      completed={isTreeHeightCompleted}
      isActive={activeTool === ValidationAction.Height}
      setIsActive={() => {
        setActiveTool(ValidationAction.Height);
        setDefaultDepth();
        resetSectionTargetToDefault();
      }}
    >
      <div className={'body-content'}>
        <div className='card-content'>
          <div className='detail-title'>
            <p>Height from ground</p>
            {alert?.message && (
              <Tooltip placement='left' title={alert?.message}>
                <WarningAmberOutlinedIcon color='warning' />
              </Tooltip>
            )}
          </div>
          <span className='number-input'>
            <PlainNumberInput
              value={parseFloat(height)}
              step={0.01}
              onChange={(value: number) => {
                if (disabled) return;
                const newState = value + viewerPositionZ;
                setHeight(value.toFixed(TO_FIXED));
                setState(newState);
              }}
              min={Number(min?.toFixed(TO_FIXED))}
              max={Number(((max ?? 0) - viewerPositionZ).toFixed(TO_FIXED))}
            />
            <span>{' m'}</span>
          </span>
        </div>
      </div>
    </AttributeCard>
  );
};

export default TreeHeightCard;
