type Props = {
  placeholder?: string;
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  prefix?: string;
  outputConverter?: (value: number) => number;
  inputConverter?: (value: string) => number;
  customeStyle?: React.CSSProperties;
};

export const PlainNumberInput = ({
  placeholder,
  value,
  onChange,
  min,
  max,
  step,
  prefix,
  inputConverter,
  outputConverter,
  customeStyle,
}: Props) => {
  const minValue: number = min && !Number.isNaN(min) ? min : 0;
  const maxValue: number = max && !Number.isNaN(max) ? max : Number.POSITIVE_INFINITY;

  return (
    <>
      {prefix && <span>{prefix}</span>}
      <input
        className='reset-input number-input'
        style={customeStyle}
        placeholder={placeholder ?? '?'}
        type='number'
        step={step ?? 1}
        value={outputConverter ? outputConverter(value) : value}
        min={minValue}
        max={maxValue}
        onChange={(e) => {
          const val = inputConverter ? inputConverter(e.target.value) : parseFloat(e.target.value);
          if (val > maxValue || val < minValue) return;
          onChange(val);
        }}
      />
    </>
  );
};
