import { createBrowserHistory } from 'history';
import { useMemo } from 'react';

import appConfig from '../config';
import { getProjectName } from '../core/projectSelector/projectSelector';
import { useUser } from '../providers/user';

const isAd = !['1', 'true'].includes(appConfig.auth.disableAD);

const SERVER = appConfig.server.endpoint;

export const handleRequest =
  (token: string, rtmsToken: string | null, allowThrowError = false, redirectIfError = true) =>
  (url: string, opts = {}) => {
    const { url: outUrl, opts: outOpts } = createRequest(token, rtmsToken)(url, opts);

    return fetch(outUrl, outOpts)
      .then(async (response) => {
      if (!response.ok) {
        if (allowThrowError) {
          throw new Error(await response.text());
        }

        const history = createBrowserHistory({ forceRefresh: true });

        if (redirectIfError) {
          if (response.status === 500) {
            history.push('/error-occurred');
          } else if (response.status === 401) {
            response.text().then((body) => {
              if (body === 'Invalid RTMS token.') {
                history.push('/error-multi-login');
              }
            });
          }
        }

        return {
          json: () => ({ error: true, status: response.status }),
        };
      }
      return response;
    }).catch((err) => {
        return {
          json: () => ({ error: true, status: 500 }),
        };
      })
  };

const createRequest = (token: string, rtmsToken: string | null) => (url: string, opts: any) => {
  return {
    url: getUrl(token)(url),
    opts: {
      ...opts,
      headers: {
        ...(opts.headers || {}),
        ...generateHeaders(token, rtmsToken),
      },
    },
  };
};

export const getUrl = (token: string) => (url: string) => {
  const urlWithDomain = url.match(/^https?:\/\//) ? url : getUrlForEndpoint(url);

  const urlObject = new URL(urlWithDomain.trim());

  if (!isAd) urlObject.searchParams.set('key', token?.trim());

  return urlObject.toString().trim();
};

export const getUrlForEndpoint = (endpoint: string) => {
  return SERVER + (endpoint.match(/^\//) ? '' : '/') + endpoint;
};

export const generateHeaders = (token: string, rtmsToken: string | null) => {
  const adRelatedHeaders = isAd
    ? {
        Authorization: `Bearer ${token}`,
        ...(rtmsToken && { 'X-RTMS-Token': rtmsToken }),
      }
    : {};

  const projectName = getProjectName();

  return { ...adRelatedHeaders, project: projectName };
};

const useApi = () => {
  const { token, rtmsToken } = useUser() as { token: string; rtmsToken: string };

  return {
    handleRequest: useMemo(() => handleRequest(token, rtmsToken), [token, rtmsToken]),
    requestHeader: useMemo(() => generateHeaders(token, rtmsToken), [token, rtmsToken]),
    getUrl: useMemo(() => getUrl(token), [token]),
  };
};

export default useApi;
