import { useCallback } from 'react';
import Tree from '../../../@types/Tree';
import UndoableAction from '../../../@types/UndoableAction';
import ValidationJobAction from '../../../enums/ValidationJobAction';
import useStore from '../../../store/useStore';
import { calculateGirth } from '../ValidationUtils';

import { Vector3 } from 'three';
import { radToDeg } from 'three/src/math/MathUtils';
import TreeStatus from '../../../enums/TreeStatus';
import { convertXYZToPostGisPoint } from '../../../utils/postgisHelper';
import TreeFlowStatus from '../../../enums/TreeFlowStatus';

const useTreeActions = (
  updateTree: (id: any, change: any, ...rest: any) => void = () => {},
  onAction: (action: UndoableAction, target: any, from?: any, to?: any) => void = () => {}
) => {
  const store = useStore((s) => s.actions);
  const { treeIndex, setTreeIndex, setTree, treeTrunks, viewerPosition, trees, location } = useStore((s) => ({
    treeIndex: s.tree.treeIndex,
    setTreeIndex: s.tree.actions.setTreeIndex,
    setTree: s.tree.actions.setTree,
    treeTrunks: s.tree.treeTrunks,
    viewerPosition: s.tree.viewerPosition,
    trees: s.tree.todoTrees,
    location: s.tree.location,
  }));

  const handleSave = useCallback(
    async (tree: Tree) => {
      const locationVector = new Vector3().fromArray(location);
      const localFirstBifPoint = new Vector3().copy(store.firstBifurcationPoint.state).sub(viewerPosition);
      const worldFirstBifPoint = new Vector3().copy(localFirstBifPoint).add(locationVector);

      const allGirths = treeTrunks.map((trunk) => trunk.girths).flat();
      const allGirthsOrdered = allGirths.sort((a, b) => calculateGirth(b) - calculateGirth(a));
      const largestEllipseGirth = allGirthsOrdered[0];

      const updateTreeData = {
        ...(largestEllipseGirth && {
          girth_1_0m: calculateGirth(largestEllipseGirth),
          girth_1_0m_offset_x: largestEllipseGirth.dX,
          girth_1_0m_offset_y: largestEllipseGirth.dY,
          girth_1_0m_ellipse_a: largestEllipseGirth.rX,
          girth_1_0m_ellipse_b: largestEllipseGirth.rY,
          girth_1_0m_ellipse_direction: largestEllipseGirth.rotation,
        }),
        are_girth_axes_valid: true,
        height: Number(store.height.state) - viewerPosition.z,
        crown_height: Number(store.canopyHeight.state),
        trunk_height: localFirstBifPoint.z,
        canopy_ellipse_a: store.canopy.state?.rX,
        canopy_ellipse_b: store.canopy.state?.rY,
        canopy_ellipse_direction: radToDeg(store.canopy.state?.rotation),
        crown_excentricity: [store.canopy.state?.dX, store.canopy.state?.dY],
        first_bifurcation_point: convertXYZToPostGisPoint(worldFirstBifPoint),
        ...(tree.status && {
          status: tree.status,
        }),
        ...(tree.tree_flow_status && {
          tree_flow_status: tree.tree_flow_status,
        }),
        trunks: treeTrunks,
      };

      await updateTree(tree.id, updateTreeData);
    },
    [
      location,
      store.canopy.state?.dX,
      store.canopy.state?.dY,
      store.canopy.state?.rX,
      store.canopy.state?.rY,
      store.canopy.state?.rotation,
      store.canopyHeight.state,
      store.firstBifurcationPoint.state,
      store.height.state,
      treeTrunks,
      updateTree,
      viewerPosition,
    ]
  );

  const handleComment = useCallback(
    (tree: Tree, comment: Comment) => {
      const lastCommentsAsArray = tree?.comment ? tree?.comment : [];
      onAction(
        ValidationJobAction.Update,
        tree?.id || tree?.id,
        { comment: lastCommentsAsArray },
        { comment: [...lastCommentsAsArray, comment] }
      );
      updateTree(tree?.id || tree?.id, {
        comment: [...lastCommentsAsArray, comment],
      });
    },
    [onAction, updateTree]
  );

  const handleRemove = useCallback(
    (tree: Tree) => {
      if (!tree) return;
      onAction(ValidationJobAction.Update, tree?.id, {
        status: TreeStatus.SemanticValidationTodo,
        tree_flow_status: TreeFlowStatus.MeasurementValidationQueued,
      });
      updateTree(tree.id, { status: TreeStatus.SemanticValidationDeleted, tree_flow_status: TreeFlowStatus.MeasurementValidationDeleted});
    },
    [onAction, updateTree]
  );

  const handleResume = useCallback(
    async (tree: Tree) => {
      if (!tree) return;
      onAction(ValidationJobAction.Update, tree?.id, {
        status: TreeStatus.SemanticValidationDone,
        tree_flow_status: TreeFlowStatus.MeasurementValidationDone,
      });
    await updateTree(tree.id, { status: TreeStatus.SemanticValidationTodo, tree_flow_status: TreeFlowStatus.MeasurementValidationQueued });
      const updatedTree = { ...tree, status: TreeStatus.SemanticValidationTodo, tree_flow_status: TreeFlowStatus.MeasurementValidationQueued };
      setTree(updatedTree as Tree);
    },
    [onAction, setTree, updateTree]
  );

  const handleField = useCallback(
    (tree: Tree, comment: Comment) => {
      if (!tree) return;
      const lastCommentsAsArray = tree?.comment ? tree?.comment : [];
      onAction(ValidationJobAction.Update, tree?.id, {
        status: TreeStatus.SemanticValidationTodo,
        tree_flow_status: TreeFlowStatus.MeasurementValidationQueued
      });
      updateTree(tree.id, {
        status: TreeStatus.SemanticValidationSentToField,
        tree_flow_status: TreeFlowStatus.SentToField,
        comment: [...lastCommentsAsArray, comment],
      });
    },
    [onAction, updateTree]
  );

  const handleSkip = useCallback(
    (direction = 1) => {
      onAction(ValidationJobAction.Skip, null, treeIndex);

      if (treeIndex === null) {
        return;
      }

      setTreeIndex((treeIndex! + direction + trees.length) % trees.length);
    },
    [onAction, setTreeIndex, treeIndex, trees.length]
  );

  const handleComplete = useCallback(
    async (tree: Tree) => {
      if (!tree) return;

      // onAction(ValidationJobAction.Update, tree?.id, {
      //   status: TreeStatus.SemanticValidationTodo,
      // });

      await handleSave({ ...tree, status: TreeStatus.SemanticValidationDone, tree_flow_status: TreeFlowStatus.MeasurementValidationDone });

      handleSkip(1);
    },
    [onAction, handleSave, handleSkip]
  );

  const handleSkipAndRemove = useCallback(
    async (tree: Tree) => {
      if (!tree) return;
      onAction(ValidationJobAction.SkipAndUpdate, tree?.id, {
        status: TreeStatus.SemanticValidationTodo,
        tree_flow_status: TreeFlowStatus.MeasurementValidationQueued,
        index: treeIndex
      });

      await updateTree(tree.id, { status: TreeStatus.SemanticValidationDeleted, tree_flow_status: TreeFlowStatus.MeasurementValidationDeleted});
      treeIndex && setTreeIndex((treeIndex + 1 + trees.length) % trees.length);
    },
    [onAction, setTreeIndex, treeIndex, trees.length, updateTree]
  );

  const handleBadSegmentation = useCallback(
    async (tree: Tree, changes: Partial<Tree>) => {
      if (!tree) return;

      await updateTree(tree?.id, changes);
      handleSkip(1);
    },
    [onAction, treeIndex, trees.length, setTreeIndex, updateTree]
  );

  return {
    handleField,
    handleComment,
    handleRemove,
    handleSave,
    handleSkip,
    handleResume,
    handleComplete,
    handleSkipAndRemove,
    handleBadSegmentation,
  };
};

export default useTreeActions;
