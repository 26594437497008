import { Lens } from '@dhmk/zustand-lens';
import produce from 'immer';
import { Vector2, Vector3 } from 'three';
import Ellipse from '../@types/Ellipse';
import ValidationAction from '../enums/ValidationAction';
import { Girth } from './TreeSlice';
import { BoundStoreType } from './useStore';

export type ActionSlice<T> = {
  state: T;
  setState: (newState: T) => void;
  setPrev: (newState: T) => void;
  prev: T | null;
  timeout: NodeJS.Timeout | null;
  visibility: boolean;
  setVisibility: (newState: boolean) => void;
};

export type EllipseWithNormalAndHeight = Ellipse & { normal: Vector3; height: number };

export type ActionSlices = {
  [ValidationAction.Height]: ActionSlice<number>;
  [ValidationAction.CanopyHeight]: ActionSlice<number>;
  [ValidationAction.FirstBifurcationPoint]: ActionSlice<Vector3>;
  [ValidationAction.Girth]: ActionSlice<Girth | null>;
  [ValidationAction.Canopy]: ActionSlice<EllipseWithNormalAndHeight>;
  [ValidationAction.LeaningVector]: ActionSlice<{ leanVector: Vector3 | null; startVector: Vector3 | null }>;

  updateRadius: (key: ValidationAction, axis: 'rX' | 'rY', newRadius: number) => EllipseWithNormalAndHeight;

  updateCenter: (key: ValidationAction, newPos: Vector2) => EllipseWithNormalAndHeight;

  updateHeight: (key: ValidationAction, height: number) => EllipseWithNormalAndHeight;

  updateRotation: (key: ValidationAction, newRotation: number) => EllipseWithNormalAndHeight;

  activeTool: ValidationAction | null;
  setActiveTool: (key: ValidationAction | null) => void;

  draggedTool: ValidationAction | null;
  setDraggedTool: (key: ValidationAction | null) => void;

  resetToolVisibility: () => void;
};

const createSlice = <T>(key: ValidationAction, set: any, get: any, defaultState: T): ActionSlice<T> => ({
  state: defaultState,

  // TODO: Use an event stack instead
  setPrev: (newState) => {
    set((state: BoundStoreType) =>
      produce(state, (draft: ActionSlices) => {
        draft[key].prev = newState as any;
      })
    );
  },

  setState: (newState) => {
    // Set prev state to current state
    get()[key].setPrev(get()[key].state);

    // Set new state
    set((state: BoundStoreType) =>
      produce(state, (draft: ActionSlices) => {
        draft[key].state = newState as any;
      })
    );
  },

  visibility: true,

  setVisibility: (visibility) => {
    set((state: BoundStoreType) =>
      produce(state, (draft: ActionSlices) => {
        draft[key].visibility = visibility;
      })
    );
  },

  prev: null,

  timeout: null,
});

const createActionSlices: Lens<ActionSlices> = (set, get) => ({
  activeTool: null,

  setActiveTool: (key: ValidationAction | null) => {
    set((state: ActionSlices) =>
      produce(state, (draft: ActionSlices) => {
        if (draft.activeTool === key) return;
        draft.activeTool = key;
      })
    );
  },

  draggedTool: null,

  setDraggedTool: (key: ValidationAction | null) => {
    set((state: ActionSlices) =>
      produce(state, (draft: ActionSlices) => {
        if (draft.draggedTool === key) return;
        draft.draggedTool = key;
      })
    );
  },

  resetToolVisibility: () => {
    const values = Object.values(ValidationAction);
    produce(get(), (draft: ActionSlices) => {
      values.forEach((key) => {
        draft[key].setVisibility(true);
      });
    });
  },

  height: createSlice<number>(ValidationAction.Height, set, get, 3),

  canopyHeight: createSlice<number>(ValidationAction.CanopyHeight, set, get, 1),

  firstBifurcationPoint: createSlice<Vector3>(ValidationAction.FirstBifurcationPoint, set, get, new Vector3()),

  girth: createSlice<Girth | null>(ValidationAction.Girth, set, get, null),

  canopy: createSlice<EllipseWithNormalAndHeight>(ValidationAction.Canopy, set, get, {
    dX: 0,
    dY: 0,
    rX: 1.8,
    rY: 2.2,
    rotation: 0,
    diameter: null,
    normal: new Vector3(0, 0, 1),
    height: 1,
  }),

  leaningVector: createSlice<{ leanVector: Vector3 | null; startVector: Vector3 | null }>(ValidationAction.LeaningVector, set, get, {
    leanVector: null,
    startVector: null,
  }),

  updateRadius: (key: ValidationAction, axis: 'rX' | 'rY', newRadius: number) => {
    const slice = get()[key] as ActionSlice<EllipseWithNormalAndHeight>;

    slice.setState(
      produce(slice.state as EllipseWithNormalAndHeight, (draft) => {
        draft[axis] = newRadius;
      })
    );
    return get()[key].state as EllipseWithNormalAndHeight;
  },

  updateHeight: (key: ValidationAction, height: number) => {
    const slice = get()[key] as ActionSlice<EllipseWithNormalAndHeight>;
    slice.setState(
      produce(slice.state as EllipseWithNormalAndHeight, (draft) => {
        draft.height = height;
      })
    );
    return get()[key].state as EllipseWithNormalAndHeight;
  },

  updateCenter: (key: ValidationAction, newPos: Vector2) => {
    const slice = get()[key] as ActionSlice<EllipseWithNormalAndHeight>;
    slice.setState(
      produce(slice.state as EllipseWithNormalAndHeight, (draft) => {
        draft.dX = newPos.x;
        draft.dY = newPos.y;
      })
    );
    return {
      ...slice.state,
      dX: newPos.x,
      dY: newPos.y,
    };
  },

  updateRotation: (key: ValidationAction, newRotation: number) => {
    const slice = get()[key] as ActionSlice<EllipseWithNormalAndHeight>;
    slice.setState(
      produce(slice.state, (draft) => {
        draft.rotation = newRotation;
      })
    );
    return slice.state;
  },
});

export default createActionSlices;
