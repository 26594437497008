import LineSlider from './LineSlider';

type PointSizeSliderProps = {
  pointSize?: number;
  setPointSize?: (ps: number) => void;
};

const PointSizeSlider = ({ pointSize, setPointSize }: PointSizeSliderProps) => {
  return (
    <div>
      <div className='slider-label'>
        <span>Point size</span>
        <span>{pointSize} pt</span>
      </div>

      <LineSlider value={pointSize} onChange={setPointSize} min={0.5} max={3} step={0.5} sx={{ padding: '7px' }} />
    </div>
  );
};

export default PointSizeSlider;
