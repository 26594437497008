import { Canvas } from '@react-three/fiber';
import { Suspense, useMemo, useRef } from 'react';
import { Color, MathUtils, Mesh, Vector2, Vector3, WebGLRenderer } from 'three';
import XZYCoords from '../../../@types/XZYCoords';
import ValidationAction from '../../../enums/ValidationAction';
import { Girth } from '../../../store/TreeSlice';
import useStore from '../../../store/useStore';
import { convertValueBetweenRanges, mergeXYZValues } from '../../../utils/mathUtils';
import { AxesHelper } from '../../ThreeHelperObjects/HelperObjects';
import DragPoint from '../Gizmos/DragPoint';
import EllipseGizmo from '../Gizmos/EllipseGizmo';
import SemanticActionsColorMap from '../SemanticActionsColorMap';
import TreePointCloud from '../TreePointCloud/TreePointCloud';
import SectionCameraControls from '../ValidationControls/SectionCameraControls';
import { calculateGirth, parseGirth } from '../ValidationUtils';
import ViewBackground from '../ViewBackground/ViewBackground';
import config from '../config';
import useDefaultZoom from '../hooks/useDefaultZoom';

const LEAN_START_POINT_COLORS = {
  point: new Color('#222D31'),
  border: new Color('#ffffff'),
  hover: new Color(SemanticActionsColorMap[ValidationAction.LeaningVector]),
};

const FIRST_BIF_START_POINT_COLORS = {
  point: new Color('#222D31'),
  border: new Color('#ffffff'),
  hover: new Color(SemanticActionsColorMap[ValidationAction.FirstBifurcationPoint]),
};

const GIRT_ELLIPSE_COLORS = {
  dragPoint: new Color('#222D31'),
  dragPointBorder: new Color('#ffffff'),
  dragPointHover: new Color(SemanticActionsColorMap[ValidationAction.Girth]),
  ellipseBody: new Color(SemanticActionsColorMap[ValidationAction.Girth]),
};

const MIN_MAX_ZOOM = {
  min: 20,
  max: 1500,
};

type SectionViewProps = {
  disabled: boolean;
  minZoom?: number;
  maxZoom?: number;
};

const SectionView = (props: SectionViewProps) => {
  const zoom = useDefaultZoom();

  const { depth, lookAt, lookFrom, target, normal } = useStore((s) => s.pointCloud.section);
  const { tree, viewerPosition } = useStore((s) => s.tree);
  const { girth, girthVisibility, isGirthActive } = useStore((s) => ({
    girth: s.actions.girth.state,
    girthVisibility: s.actions.girth.visibility,
    isGirthActive: s.actions.activeTool === ValidationAction.Girth,
  }));
  const { leanStartVector, setLeanState, leanAngleVisibility, isLeanAngleActive } = useStore((s) => ({
    leanStartVector: s.actions.leaningVector.state.startVector,
    setLeanState: s.actions.leaningVector.setState,
    leanAngleVisibility: s.actions.leaningVector.visibility,
    isLeanAngleActive: s.actions.activeTool === ValidationAction.LeaningVector,
  }));
  const { firstBifurcationPoint, setFirstBifurcationPoint, isFirstBifVisible, isFirstBifActive } = useStore((s) => ({
    firstBifurcationPoint: s.actions.firstBifurcationPoint.state,
    setFirstBifurcationPoint: s.actions.firstBifurcationPoint.setState,
    isFirstBifVisible: s.actions.firstBifurcationPoint.visibility,
    isFirstBifActive: s.actions.activeTool === ValidationAction.FirstBifurcationPoint,
  }));
  const { isLeanAngleCompleted, isFirstBifurcationMidPointCompleted, isTrunkDetailsCompleted } = useStore((s) => s.validation);
  const updateRadius = useStore((s) => s.actions.updateRadius);
  const updateCenter = useStore((s) => s.actions.updateCenter);
  const updateRotation = useStore((s) => s.actions.updateRotation);
  const updateGirth = useStore((s) => s.tree.actions.trunkActions.updateGirth);

  const leanStartPointRef = useRef<Mesh>(null);
  const firstBifPointRef = useRef<Mesh>(null);

  const scaledInitialZoom = useMemo(() => {
    if (!tree?.girth_1_0m) return zoom * 4;

    const girth = calculateGirth(parseGirth(tree));
    const scaledZoom = convertValueBetweenRanges({ min: 0, max: girth + 1 }, { min: props.minZoom || MIN_MAX_ZOOM.min, max: props.maxZoom || MIN_MAX_ZOOM.max }, girth);

    return MathUtils.clamp(scaledZoom, props.minZoom || MIN_MAX_ZOOM.min, props.maxZoom || MIN_MAX_ZOOM.max);
  }, [tree, zoom, girth]);

  const changeLeanStartPoint = (vector: XZYCoords) => {
    const startVector = new Vector3().copy(mergeXYZValues(leanStartVector!, vector));
    const leanVector = new Vector3().subVectors(firstBifurcationPoint, startVector);
    setLeanState({ leanVector, startVector });
  };

  const changeFirstBifPoint = (vector: XZYCoords) => {
    const firstBif = new Vector3().copy(mergeXYZValues(firstBifurcationPoint, vector));
    setFirstBifurcationPoint(new Vector3().copy(firstBif));
  };

  const updateGirthRadius = (axis: 'rX' | 'rY', radius: number) => {
    const updatedGirth = updateRadius(ValidationAction.Girth, axis, radius);
    updateGirth(updatedGirth as Girth);
    return updatedGirth;
  };

  const updateGirthCenter = (vector: Vector2) => {
    const updatedGirth = updateCenter(ValidationAction.Girth, vector);
    updateGirth(updatedGirth as Girth);
    return updatedGirth;
  };

  const updateGirthRotation = (rotation: number) => {
    const updatedGirth = updateRotation(ValidationAction.Girth, rotation);
    updateGirth(updatedGirth as Girth);
    return updatedGirth;
  };

  return (
    <div style={{ display: 'flex', flex: 1, position: 'relative' }}>
      <Canvas
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
        }}
        orthographic
        linear
        camera={{
          near: config.nearPlane,
          up: new Vector3(0, 0, 1),
          far: config.farPlane,
          zoom: scaledInitialZoom,
        }}
        gl={(canvas) => {
          const renderer = new WebGLRenderer({ canvas });
          renderer.localClippingEnabled = true;
          return renderer;
        }}
      >
        <ViewBackground />
        {config.isAxesHelperVisible && <AxesHelper size={15} />}

        <Suspense>
          <DragPoint
            name={'tree-location-point'}
            initialPosition={viewerPosition}
            color={new Color('red')}
            draggable={false}
            visibility={true}
            handleSize={0.1}
          />
          <DragPoint
            ref={leanStartPointRef}
            name={'lean-start-point'}
            initialPosition={leanStartVector!}
            onDrag={changeLeanStartPoint}
            colors={LEAN_START_POINT_COLORS}
            visibility={leanAngleVisibility && isLeanAngleActive}
            restrictAxis={{ z: true }}
            draggable={!props.disabled && leanAngleVisibility && isLeanAngleActive && !isLeanAngleCompleted}
            handleSize={0.15}
          />
          <DragPoint
            ref={firstBifPointRef}
            name={'first-bif-point'}
            initialPosition={firstBifurcationPoint}
            onDrag={changeFirstBifPoint}
            colors={FIRST_BIF_START_POINT_COLORS}
            visibility={isFirstBifVisible && isFirstBifActive}
            restrictAxis={{ z: true }}
            draggable={!props.disabled && isFirstBifVisible && isFirstBifActive && !isFirstBifurcationMidPointCompleted}
            handleSize={0.15}
          />
          {girth && isGirthActive && (
            <EllipseGizmo
              name={ValidationAction.Girth}
              ellipse={girth}
              colors={GIRT_ELLIPSE_COLORS}
              visible={girthVisibility && isGirthActive}
              disabled={Boolean(!isGirthActive || props.disabled || isTrunkDetailsCompleted)}
              handleSize={0.15}
              updates={{
                updateRadius: updateGirthRadius,
                updateCenter: updateGirthCenter,
                updateRotation: updateGirthRotation,
              }}
            />
          )}

          {(lookAt || lookFrom) && <TreePointCloud section={{ depth, lookAt, lookFrom }} />}
        </Suspense>
        <SectionCameraControls target={target} normal={normal} minZoom={props.minZoom || MIN_MAX_ZOOM.min} maxZoom={props.maxZoom || MIN_MAX_ZOOM.max} />
      </Canvas>
    </div>
  );
};

export default SectionView;
