import { useEffect, useState } from 'react';
import { CardProps } from '../../../@types/CardProps';
import ValidationAction from '../../../enums/ValidationAction';
import useDebounce from '../../../hooks/useDebounce';
import { ToastType } from '../../../store/ToastSlice';
import useStore from '../../../store/useStore';
import AttributeCard from '../../Card/AttributeCard';
import { PlainNumberInput } from '../../inputs/PlainNumberInput';
import SemanticActionsColorMap from '../SemanticActionsColorMap';
import { METRICS_NAMES, METRICS_VALUE_LIMITS } from './TrunkDetailsCard/Constants';
import * as _ from 'lodash';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Tooltip } from '@mui/material';

const TO_FIXED = 2;

const CrownHeightCard = ({ disabled, alerts, setAlerts }: CardProps) => {
  const draggedTool = useStore((s) => s.actions.draggedTool);
  const { state, setState, visibility, setVisibility } = useStore((s) => s.actions.canopyHeight);
  const addToast = useStore((s) => s.toast.addToast);
  const debouncedToast = useDebounce(addToast, 250);

  const height = useStore((s) => s.actions.height.state);
  const locationZ = useStore((s) => s.tree.viewerPosition.z);
  const { activeTool, setActiveTool } = useStore((s) => ({ activeTool: s.actions.activeTool, setActiveTool: s.actions.setActiveTool }));
  const setIsCrownHeightCompleted = useStore((s) => s.validation.actions.setIsCrownHeightCompleted);
  const hasLoadedDeltaCorrection = useStore((s) => s.validation.hasLoadedDeltaCorrection);
  const resetSectionTargetToDefault = useStore((s) => s.pointCloud.actions.section.resetSectionTargetToDefault);
  const setDefaultDepth = useStore((s) => s.pointCloud.actions.section.setDefaultDepth);

  const isCrownHeightCompleted = useStore((s) => s.validation.isCrownHeightCompleted);

  const [value, setValue] = useState(0);

  const alert = alerts.find((a) => a.name === METRICS_NAMES.CROWN_HEIGHT);

  useEffect(() => {
    if (!hasLoadedDeltaCorrection) return;
    setValue(Number(state.toFixed(TO_FIXED)));
    // Load value only when tree is loaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLoadedDeltaCorrection, locationZ]);

  useEffect(() => {
    if (ValidationAction.CanopyHeight !== draggedTool) return;
    setValue(Number(state.toFixed(TO_FIXED)));
  }, [draggedTool, state]);

  useEffect(() => {
    const isHeightInRange = _.inRange(
      Number(value),
      METRICS_VALUE_LIMITS[METRICS_NAMES.CROWN_HEIGHT].min,
      METRICS_VALUE_LIMITS[METRICS_NAMES.CROWN_HEIGHT].max
    );
    if (!isHeightInRange) {
      const alreadyAlerted = alerts.find((a) => a.name === METRICS_NAMES.CROWN_HEIGHT);

      if (!alreadyAlerted?.name) {
        setAlerts((prev) => [
          ...prev,
          {
            name: METRICS_NAMES.CROWN_HEIGHT,
            message: `Crown height must be between ${METRICS_VALUE_LIMITS[METRICS_NAMES.CROWN_HEIGHT].min} and ${
              METRICS_VALUE_LIMITS[METRICS_NAMES.CROWN_HEIGHT].max
            } ${METRICS_VALUE_LIMITS[METRICS_NAMES.CROWN_HEIGHT].unit}`,
          },
        ]);
      }
    } else {
      setAlerts((prev) => prev.filter((a) => a.name !== METRICS_NAMES.CROWN_HEIGHT));
    }
  }, [value]);

  return (
    <AttributeCard
      title='Crown Height'
      color={SemanticActionsColorMap[ValidationAction.CanopyHeight]}
      isExpanded={visibility}
      onExpand={setVisibility}
      setCompleted={setIsCrownHeightCompleted}
      disabled={disabled}
      completed={isCrownHeightCompleted}
      isActive={activeTool === ValidationAction.CanopyHeight}
      setIsActive={() => {
        setDefaultDepth();
        resetSectionTargetToDefault();
        setActiveTool(ValidationAction.CanopyHeight);
      }}
    >
      <div className={'body-content'}>
        <div className='card-content'>
          <div className='detail-title'>
            <p>Canopy lower bound</p>
            {alert?.message && (
              <Tooltip placement='left' title={alert?.message}>
                <WarningAmberOutlinedIcon color='warning' />
              </Tooltip>
            )}
          </div>
          <span className='number-input'>
            <PlainNumberInput
              value={value}
              step={0.01}
              onChange={(value) => {
                if (disabled) return;

                const actualHeight = height - value;
                if (actualHeight > height || actualHeight < locationZ) {
                  debouncedToast('Value cannot be lower than the location and higher than the tree height', ToastType.ERROR);
                  return;
                }

                setValue(value);
                setState(value);
              }}
              min={Number(0)}
              max={Number(1000)}
            />
            <span>{' m'}</span>
          </span>
        </div>
      </div>
    </AttributeCard>
  );
};

export default CrownHeightCard;
