import Icon from "../Icon";
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Button } from "../inputs";

interface AlertModalProps {
  isOpen: boolean;
  alerts: any[];
  handleClose: () => void;
  handleCompleteClick: () => Promise<void>
}

const AlertModal = ({ isOpen, alerts, handleClose, handleCompleteClick }: AlertModalProps) => {
  if (!isOpen || !alerts?.length) return null;

  const renderAlerts = () => {
    return alerts.map((alert, index) => {
      return (
        <div className="alert" key={index}>
          <WarningAmberOutlinedIcon color='warning' /><p>{alert.message}</p>
        </div>
      )
    })
  };

  return (
    <div className="modalWrapper">
      <div className="alertModal">
        <div className="header">
        <h3>Attribute Alerts</h3>
            <i className='close-button' onClick={handleClose}>
              <Icon icon='removeX' />
            </i>
        </div>
        <div className="body">
          {renderAlerts()}
        </div>
        <div className="footer">
          <div className="buttons">
            <Button 
              onClick={handleClose} 
              label="Go back and edit"
              style={{ backgroundColor: '#FFC30E', padding: '1vh 1vw', width: 'fit-content' }}
              leadingIcon={'undo-alt'}
            />
            <Button 
              onClick={handleCompleteClick} 
              label="Complete"
              style={{ backgroundColor: '#058B62', padding: '1vh 1vw', width: 'fit-content' }}
              leadingIcon={'checkmark'}
            />
          </div>    
        </div>
      </div>
    </div>
  )
};

export default AlertModal;