import { useMemo, useState } from 'react';
import { PointCloudViewType } from '../../../enums/PointCloudViewType';
import TabSelector from '../../TabSelector';
import PerspectiveToolbar from '../Toolbars/PerspectiveToolbar';
import SectionToolbar from '../Toolbars/SectionToolbar';
import ToolBarWrapper from '../Toolbars/ToolBarWrapper';
import CanopyView from './CanopyView';
import PanoramaView from './PanoramaView';
import PerspectiveView from './PerspectiveView';
import SectionView from './SectionView';

const VIEW_DROPDOWN_OPTIONS = [
  {
    id: 'Perspective',
    value: PointCloudViewType.PERSPECTIVE,
    label: '',
    iconName: 'pointcloud_360',
  },
  {
    id: 'Top Down',
    value: PointCloudViewType.TOP_DOWN,
    label: '',
    iconName: 'top_down',
  },
  {
    id: 'Section',
    value: PointCloudViewType.SECTION,
    label: '',
    iconName: 'sectionView',
  },
  {
    id: 'Panoramic',
    value: PointCloudViewType.PANORAMIC,
    label: '',
    iconName: 'panorama',
  },
];

type ViewWrapperProps = {
  initialView: PointCloudViewType;
  disabled: boolean;
  minZoom?: number;
  maxZoom?: number;
  hideTabSelector?: boolean;
  configItems: Record<string, string>;
  setConfigItems: React.Dispatch<React.SetStateAction<Record<string, string>>>;
};

const ViewWrapper = ({ initialView, disabled, minZoom, maxZoom, hideTabSelector, configItems, setConfigItems }: ViewWrapperProps) => {
  const [view, setView] = useState<PointCloudViewType>(initialView);

  const viewMap = useMemo(
    () => ({
      Perspective: {
        component: <PerspectiveView disabled={disabled} />,
        toolbar: (
          <ToolBarWrapper>
            <TabSelector
              onSelect={(param) => setView(param.value as PointCloudViewType)}
              tabs={VIEW_DROPDOWN_OPTIONS}
              active={view}
              style={{ zIndex: 1, position: 'absolute' }}
            />
            <PerspectiveToolbar configItems={configItems} setConfigItems={setConfigItems} />
          </ToolBarWrapper>
        ),
      },
      'Top Down': {
        component: <CanopyView disabled={disabled} />,
        toolbar: (
          <ToolBarWrapper>
            <TabSelector
              onSelect={(param) => setView(param.value as PointCloudViewType)}
              tabs={VIEW_DROPDOWN_OPTIONS}
              active={view}
              style={{ zIndex: 1, position: 'absolute' }}
            />
          </ToolBarWrapper>
        ),
      },
      Section: {
        component: <SectionView disabled={disabled} minZoom={minZoom} maxZoom={maxZoom} />,
        toolbar: (
          <ToolBarWrapper>
              <TabSelector
                onSelect={(param) => setView(param.value as PointCloudViewType)}
                tabs={VIEW_DROPDOWN_OPTIONS}
                active={view}
                style={{ zIndex: 1 }}
                hideTabSelector={hideTabSelector}
              />
            <SectionToolbar disabled={disabled} />
          </ToolBarWrapper>
        ),
      },
      Panoramic: {
        // component: <PanoramaView />,
        component: <PanoramaView />,
        toolbar: (
          <ToolBarWrapper>
            <TabSelector
              onSelect={(param) => setView(param.value as PointCloudViewType)}
              tabs={VIEW_DROPDOWN_OPTIONS}
              active={view}
              style={{ zIndex: 1 }}
            />
          </ToolBarWrapper>
        ),
      },
    }),
    [disabled, view]
  );

  return (
    <div style={{ display: 'flex', flex: 1, position: 'relative', overflow: 'visible' }}>
      {viewMap[view].toolbar && viewMap[view].toolbar}
      {viewMap[view].component}
    </div>
  );
};

export default ViewWrapper;
