import { Canvas } from '@react-three/fiber';
import { Suspense, useEffect, useMemo, useRef } from 'react';
import { Color, MathUtils, Mesh, Vector3 } from 'three';
import ValidationAction from '../../../enums/ValidationAction';
import useStore from '../../../store/useStore';
import { convertValueBetweenRanges, mergeXYZValues } from '../../../utils/mathUtils';
import { AxesHelper } from '../../ThreeHelperObjects/HelperObjects';
import DragPoint from '../Gizmos/DragPoint';
import HeightGizmo from '../Gizmos/HeightGizmo';
import LeanGizmo from '../Gizmos/LeanGizmo';
import SemanticActionsColorMap from '../SemanticActionsColorMap';
import TreePointCloud from '../TreePointCloud/TreePointCloud';
import ValidationControls from '../ValidationControls/ValidationControls';
import ViewBackground from '../ViewBackground/ViewBackground';
import config from '../config';

const MIN_MAX_ZOOM = {
  min: 20,
  max: 500,
};

const PerspectiveView = ({ disabled }: { disabled: boolean }) => {
  const { section, laz } = useStore((s) => s.pointCloud);
  const locationVectorDelta = useStore((s) => s.tree.viewerPosition);
  const actions = useStore((s) => s.actions);
  const {
    setSectionDefaultTarget,
    setSectionDefaultNormal,
    setSectionTarget,
    setSectionNormal,
    sectionDefaultTarget,
    sectionDefaultNormal,
  } = useStore((s) => ({
    setSectionDefaultTarget: s.pointCloud.actions.section.setDefaultTarget,
    setSectionDefaultNormal: s.pointCloud.actions.section.setDefaultNormal,
    setSectionTarget: s.pointCloud.actions.section.setTarget,
    setSectionNormal: s.pointCloud.actions.section.setNormal,
    sectionDefaultTarget: s.pointCloud.section.defaultTarget,
    sectionDefaultNormal: s.pointCloud.section.defaultNormal,
  }));

  const { isTreeHeightCompleted, isCrownHeightCompleted, isFirstBifurcationCompleted, hasLoadedDeltaCorrection } = useStore(
    (s) => s.validation
  );

  const heightGizmo = useRef<Mesh>(null);
  const canopyGizmo = useRef<Mesh>(null);
  const firstBifHeightGizmo = useRef<Mesh>(null);
  const sectionHeightHelper = useRef<Mesh>(null);
  const heightRef = useRef<number | null>(null);

  const scaledInitialZoom = useMemo(() => {
    const boundingDiameter = laz?.geometry.boundingSphere?.radius ? laz?.geometry.boundingSphere?.radius * 2 : 1;
    const scaledZoom = convertValueBetweenRanges(
      { min: 0, max: boundingDiameter + 1 },
      { min: MIN_MAX_ZOOM.min, max: MIN_MAX_ZOOM.max },
      boundingDiameter
    );
    return MathUtils.clamp(scaledZoom, MIN_MAX_ZOOM.min, MIN_MAX_ZOOM.max)  * .8;
  }, [laz?.geometry.boundingSphere?.radius]);

  const onTreeHeightChange = (z: number) => {
    if (!canopyGizmo.current || !heightGizmo.current) return;
    const heightDiff = z - (heightRef.current ?? heightGizmo.current.position.z);
    const delta = heightDiff + canopyGizmo.current.position.z;
    canopyGizmo.current.position.z = delta;
    actions.height.setState(z);
    heightRef.current = z;
  };

  useEffect(() => {
    if (!canopyGizmo.current || !hasLoadedDeltaCorrection) return;
    canopyGizmo.current.position.z = actions.height.state - actions.canopyHeight.state;
    // Position canopy gizmo height once tree and delta correction is loaded
    // Canopy height is a delta value subtracted from tree height
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLoadedDeltaCorrection, canopyGizmo.current]);

  return (
    <>
      <Canvas
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
        }}
        orthographic
        linear
        gl={{ sortObjects: true }}
        camera={{
          near: config.nearPlane,
          up: new Vector3(0, 0, 1),
          far: config.farPlane,
          zoom: scaledInitialZoom,
        }}
      >
        <ViewBackground />
        {config.isAxesHelperVisible && <AxesHelper size={15} />}

        <Suspense>
          <TreePointCloud />

          <DragPoint
            name={'tree-location-point'}
            initialPosition={locationVectorDelta}
            color={new Color('red')}
            draggable={false}
            visibility={true}
            handleSize={0.1}
          />

          <HeightGizmo
            ref={heightGizmo}
            name={ValidationAction.Height}
            color={SemanticActionsColorMap[ValidationAction.Height]}
            positionZ={actions.height.state ?? 0}
            visible={actions.height.visibility}
            draggable={!disabled && !isTreeHeightCompleted}
            viewerPosition={locationVectorDelta}
            activeTool={actions.activeTool as string}
            grabbed={actions.draggedTool}
            setGrabbed={(name) => {
              if (actions.draggedTool) return;

              actions.setActiveTool(ValidationAction.Height);
              actions.setDraggedTool(name as ValidationAction);
            }}
            onChange={onTreeHeightChange}
          />
          <HeightGizmo
            allowBelowZeroZ={true}
            ref={canopyGizmo}
            name={ValidationAction.CanopyHeight}
            color={SemanticActionsColorMap[ValidationAction.CanopyHeight]}
            positionZ={0}
            visible={actions.canopyHeight.visibility}
            draggable={!disabled && !isCrownHeightCompleted}
            activeTool={actions.activeTool as string}
            viewerPosition={locationVectorDelta}
            grabbed={actions.draggedTool}
            setGrabbed={(name) => {
              if (actions.draggedTool) return;

              actions.setActiveTool(name as ValidationAction);
              actions.setDraggedTool(name as ValidationAction);
            }}
            onChange={(height) => {
              actions.canopyHeight.setState(actions.height.state - height);
              if (!canopyGizmo.current) return;
              canopyGizmo.current.position.z = height;
            }}
          />
          <HeightGizmo
            ref={firstBifHeightGizmo}
            name={ValidationAction.FirstBifurcationPoint}
            color={SemanticActionsColorMap[ValidationAction.FirstBifurcationPoint]}
            positionZ={actions.firstBifurcationPoint.state.z ?? 0}
            activeTool={actions.activeTool as string}
            visible={actions.firstBifurcationPoint.visibility}
            draggable={!disabled && !isFirstBifurcationCompleted}
            viewerPosition={locationVectorDelta}
            grabbed={actions.draggedTool}
            setGrabbed={(name) => {
              if (actions.draggedTool) return;

              if (name && actions.activeTool !== ValidationAction.FirstBifurcationPoint) {
                actions.setActiveTool(name as ValidationAction);
                setSectionTarget(sectionDefaultTarget!);
                setSectionNormal(sectionDefaultNormal!);
              }

              actions.setDraggedTool(name as ValidationAction);
            }}
            onChange={(value) => {
              const mergedFirstBif = mergeXYZValues(actions.firstBifurcationPoint.state, { z: value });
              actions.firstBifurcationPoint.setState(mergedFirstBif);
              setSectionDefaultTarget(mergedFirstBif);
              setSectionDefaultNormal(new Vector3(0, 0, 1));
              setSectionTarget(mergedFirstBif);
              setSectionNormal(new Vector3(0, 0, 1));
            }}
          />
          <HeightGizmo
            ref={sectionHeightHelper}
            name={'section-height-helper'}
            color='#FFFFFF'
            positionZ={section.target?.z ?? 0}
            draggable={false}
            label={'Section view height'}
            depth={section.depth}
            visible={section.visible}
            viewerPosition={locationVectorDelta}
          />
          <LeanGizmo handleSize={0.1} />
        </Suspense>

        <ValidationControls
          minPolarAngle={Math.PI / 2}
          maxPolarAngle={Math.PI / 2}
          minZoom={MIN_MAX_ZOOM.min}
          maxZoom={MIN_MAX_ZOOM.max}
          name='height'
        />
      </Canvas>
    </>
  );
};

export default PerspectiveView;
