import { Tooltip, useTheme as useMuiTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Vector3 } from 'three';
import { CardProps } from '../../../../@types/CardProps';
import ValidationAction from '../../../../enums/ValidationAction';
import { Girth, TrunkWithGirths } from '../../../../store/TreeSlice';
import useStore from '../../../../store/useStore';
import { radiusFromCircumference } from '../../../../utils/mathUtils';
import AttributeCard from '../../../Card/AttributeCard';
import Icon from '../../../Icon';
import { PlainNumberInput } from '../../../inputs/PlainNumberInput';
import { calculateGirth } from '../../ValidationUtils';
import { MAX_GIRTH_PER_TRUNK, METRICS_NAMES, METRICS_VALUE_LIMITS } from './Constants';
import { getDefaultGirth } from '../../../../routes/Validation/SemanticValidation';
import { ToastType } from '../../../../store/ToastSlice';
import useDebounce from '../../../../hooks/useDebounce';
import * as _ from 'lodash';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

/* Removed on PRD-515  */
/* const getDefaultTrunk = () => {
  return {
    local_id: Date.now().toString(36) + Math.random().toString(36).substring(2),
    girths: [
      {
        local_id: Date.now().toString(36) + Math.random().toString(36).substring(2),
        ...DEFAULT_ELLIPSE,
      },
    ],
  };
}; */

const TrunkDetailsCard = (props: CardProps): JSX.Element => {
  const muiTheme = useMuiTheme();

  const girth = useStore((s) => s.actions.girth);
  const updateRadius = useStore((s) => s.actions.updateRadius);

  const addToast = useStore((s) => s.toast.addToast);
  const debouncedToast = useDebounce(addToast, 250);

  const trunks = useStore((s) => s.tree.treeTrunks);
  const viewerPosition = useStore((s) => s.tree.viewerPosition);
  /* Removed on PRD-515  */
  const { /* addTreeTrunk, removeTreeTrunk, */ addGirthToTrunk, removeGirth, updateGirth } = useStore((s) => s.tree.actions.trunkActions);
  const { activeTool, setActiveTool } = useStore((s) => ({ activeTool: s.actions.activeTool, setActiveTool: s.actions.setActiveTool }));
  const isToolActive = useMemo(() => activeTool === ValidationAction.Girth, [activeTool]);
  const hasLoadedParams = useStore((s) => s.validation.hasLoadedParams);
  const addTreeTrunk = useStore((s) => s.tree.actions.trunkActions.addTreeTrunk);

  const setIsTrunkDetailsCompleted = useStore((s) => s.validation.actions.setIsTrunkDetailsCompleted);
  const isTrunkDetailsCompleted = useStore((s) => s.validation.isTrunkDetailsCompleted);
  const setDefaultDepth = useStore((s) => s.pointCloud.actions.section.setDefaultDepth);

  const { setSectionTarget, setSectionNormal } = useStore((s) => ({
    setSectionTarget: s.pointCloud.actions.section.setTarget,
    setSectionNormal: s.pointCloud.actions.section.setNormal,
  }));

  const firstTrunk = trunks?.length ? trunks?.[0] : undefined;

  const [selectedTrunk, setSelectedTrunk] = useState<TrunkWithGirths | null>(firstTrunk || props?.defaultTrunk || null);

  /* Removed on PRD-515  */
  // const selectedTrunkIndex = useMemo(() => trunks.findIndex((t) => t.local_id === selectedTrunk?.local_id), [selectedTrunk, trunks]);

  const [selectedGirth, setSelectedGirth] = useState<Girth | null>(trunks[0]?.girths?.[0] || props?.defaultTrunk?.girths?.[0] || null);

  const isAddGirthActionValid = selectedTrunk && selectedTrunk.girths.length < MAX_GIRTH_PER_TRUNK;
  const isRemoveGirthActionValid = selectedTrunk && selectedGirth && selectedTrunk.girths.length > 0;

  const alert = props.alerts.find((a) => a.name === METRICS_NAMES.NUMBER_OF_STEMS);

  useEffect(() => {
    if (!props?.tree?.id || !props.setNumberOfStems) return;

    if (!props?.tree?.number_of_stems) props.setNumberOfStems(0);

    props.setNumberOfStems(props.tree.number_of_stems as number);
  }, [props?.tree?.id, props?.tree?.number_of_stems, props?.setNumberOfStems]);

  useEffect(() => {
    const isNumberOfStemsIsInRange = _.inRange(
      Number(props.numberOfStems),
      METRICS_VALUE_LIMITS[METRICS_NAMES.NUMBER_OF_STEMS].min,
      METRICS_VALUE_LIMITS[METRICS_NAMES.NUMBER_OF_STEMS].max
    );
    if (!isNumberOfStemsIsInRange) {
      const alreadyAlerted = props.alerts.find((a) => a.name === METRICS_NAMES.NUMBER_OF_STEMS);
      
      if (!alreadyAlerted?.name) {
        props.setAlerts((prev) => [
          ...prev,
          {
            name: METRICS_NAMES.NUMBER_OF_STEMS,
            message: `Number of stems should be between ${METRICS_VALUE_LIMITS[METRICS_NAMES.NUMBER_OF_STEMS].min} and ${
              METRICS_VALUE_LIMITS[METRICS_NAMES.NUMBER_OF_STEMS].max
            } ${METRICS_VALUE_LIMITS[METRICS_NAMES.NUMBER_OF_STEMS].unit}`,
          },
        ]);
      }
    } else {
      props.setAlerts((prev) => prev.filter((a) => a.name !== METRICS_NAMES.NUMBER_OF_STEMS));
    }
  }, [props?.numberOfStems]);

  /* Removed on PRD-515  */
  /*   const isTrunkAdditionDisabled = trunks.length >= MAX_TRUNKS || props.disabled;
  const isTrunkRemovalDisabled = !selectedTrunk || !trunks.length || props.disabled; */

  const setEllipse = (ellipse: Girth | null) => {
    girth.setState(null);
    setTimeout(() => {
      if (!ellipse) return;

      setSectionTarget(new Vector3(viewerPosition.x + ellipse.dX, viewerPosition.y + ellipse.dY, viewerPosition.z + ellipse.height));
      setSectionNormal(ellipse?.normal);
      girth.setState(ellipse);
    }, 1);
  };

  const setSelectedTrunkAndFirstGirth = (trunk: TrunkWithGirths | null) => {
    setSelectedTrunk(trunk ? trunk : null);
    if (!trunk?.girths.length) {
      setSectionTarget(new Vector3(viewerPosition.x, viewerPosition.y, viewerPosition.z + 1));
      setSectionNormal(new Vector3(0, 0, 1));
    } else {
      setSelectedGirth(trunk ? trunk.girths[0] : null);
      setEllipse(trunk ? trunk.girths[0] : null);
    }
  };

  const onToolActivation = () => {
    if (activeTool === ValidationAction.Girth) return;

    setDefaultDepth();
    setActiveTool(ValidationAction.Girth);

    if (!trunks?.length) {
      setSectionTarget(new Vector3(viewerPosition.x, viewerPosition.y, viewerPosition.z + 1));
      setSectionNormal(new Vector3(0, 0, 1));
    } else if (!selectedTrunk) {
      setSelectedTrunkAndFirstGirth(trunks[0]);
    } else {
      setSelectedTrunkAndFirstGirth(selectedTrunk);
    }
  };

  /* Removed on PRD-515  */
  /*   const onTrunkClick = (trunk: TrunkWithGirths) => {
    if (trunk.local_id === selectedTrunk?.local_id) {
      setSelectedTrunkAndFirstGirth(null);
    } else {
      setSelectedTrunkAndFirstGirth(trunk);
    }
  };

  const addTrunk = () => {
    if (isTrunkAdditionDisabled) return;
    const initTrunk = getDefaultTrunk();
    addTreeTrunk(initTrunk);
    setSelectedTrunkAndFirstGirth(initTrunk);
  };

  const removeTrunk = () => {
    if (isTrunkRemovalDisabled) return;
    const indexBeforeRemoved = trunks.findIndex((t) => t.local_id === selectedTrunk!.local_id);
    const selectIndex = indexBeforeRemoved > 0 ? indexBeforeRemoved - 1 : 0;
    setSelectedTrunkAndFirstGirth(trunks[selectIndex]);
    removeTreeTrunk(selectedTrunk!.local_id);
  }; */

  const onGirthClick = (girth: Girth) => {
    if (girth.local_id === selectedGirth?.local_id) {
      setSelectedGirth(null);
      setEllipse(null);
    } else {
      setSelectedGirth(girth);
      setEllipse(girth);
    }
  };

  const addGirthValue = () => {
    if (!isAddGirthActionValid || props.disabled || !selectedTrunk) return;
    const initGirth = getDefaultGirth();
    addGirthToTrunk(selectedTrunk?.local_id, initGirth);
    setSelectedGirth(initGirth);
    setEllipse(initGirth);
  };

  const removeGirthValue = () => {
    if (!isRemoveGirthActionValid || props.disabled) return;
    removeGirth(selectedTrunk?.local_id, selectedGirth);
    setSelectedGirth(null);
    setEllipse(null);
  };

  useEffect(() => {
    if (!hasLoadedParams) return;
    setSelectedTrunk(trunks?.[0] || props.defaultTrunk || null);
    if (!trunks?.length && props.defaultTrunk) addTreeTrunk(props.defaultTrunk);
    // On tree load select first trunk
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLoadedParams, viewerPosition]);

  const editGirth = (event: any, girth: Girth) => {
    if (girth === selectedGirth) event.stopPropagation();
  };

  const updateGirthRadius = (radius: number) => {
    updateRadius(ValidationAction.Girth, 'rX', radius);
    updateRadius(ValidationAction.Girth, 'rY', radius);
  };

  return (
    <AttributeCard
      title='Trunk details'
      color='#DB8100'
      setCompleted={setIsTrunkDetailsCompleted}
      isExpanded={girth.visibility}
      onExpand={girth.setVisibility}
      completed={isTrunkDetailsCompleted}
      disabled={props.disabled}
      isActive={isToolActive}
      setIsActive={onToolActivation}
    >
      <div className='trunk-details-card-body-wrapper'>
        {/* Removed on PRD-515  */}
        {/*         <div className='heading'>
          <p>Trunks</p>
          <div className='actions-wrapper'>
            <i className={isTrunkAdditionDisabled ? 'disabled' : ''} onClick={addTrunk}>
              <Icon
                icon='plusWithCircle'
                style={{ color: isTrunkAdditionDisabled ? muiTheme.palette.text.disabled : muiTheme.palette.text.primary }}
              />
            </i>

            <i className={isTrunkRemovalDisabled ? 'disabled' : ''} onClick={removeTrunk}>
              <Icon
                icon='minusWithCircle'
                style={{ color: isTrunkRemovalDisabled ? muiTheme.palette.text.disabled : muiTheme.palette.text.primary }}
              />
            </i>
          </div>
        </div>
        <ul className='stem-list'>
          {trunks.map((trunk, i) => (
            <li
              key={trunk.id + 'stem' + i}
              className={`stem-list-item ${selectedTrunkIndex === i ? 'selected' : ''}`}
              onClick={() => onTrunkClick(trunk)}
              style={{
                outline: selectedTrunkIndex === i ? '1px solid ' + muiTheme.palette.primary.main : 'none',
              }}
            >
              <p> Stem {i + 1}</p>
            </li>
          ))}
        </ul> */}
        {selectedTrunk && (
          <>
            <div className='heading'>
              <p>Girths</p>
              <div className='actions-wrapper'>
                <i className={isAddGirthActionValid ? '' : 'disabled'} onClick={addGirthValue}>
                  <Icon
                    icon='plusWithCircle'
                    style={{ color: isAddGirthActionValid ? muiTheme.palette.text.primary : muiTheme.palette.text.disabled }}
                  />
                </i>

                <i className={isRemoveGirthActionValid ? '' : 'disabled'} onClick={removeGirthValue}>
                  <Icon
                    icon='minusWithCircle'
                    style={{ color: isRemoveGirthActionValid ? muiTheme.palette.text.primary : muiTheme.palette.text.disabled }}
                  />
                </i>
              </div>
            </div>
            <ul className='girth-list'>
              {selectedTrunk?.girths?.map((girth, i) => {
                return (
                  <li
                    key={'girth-' + i}
                    className={`girth-list-item ${girth.local_id === selectedGirth?.local_id && 'selected'}`}
                    onClick={() => onGirthClick(girth)}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row', width: '500px' }}>
                      <p>Stem {girth ? girth.height.toFixed(2) + ' ' : '??? '}m:</p>
                      {girth && (
                        <span className='number-input' onClick={(event) => editGirth(event, girth)}>
                          <PlainNumberInput
                            value={parseFloat(calculateGirth(girth).toFixed(2))}
                            step={0.1}
                            onChange={(value: number) => {
                              if (props.disabled && selectedGirth?.local_id === girth.local_id) return;

                              const radius = Math.max(radiusFromCircumference(value), 0.01);
                              updateGirth({
                                rX: radius,
                                rY: radius,
                                diameter: value,
                                height: girth.height,
                                local_id: girth.local_id,
                                normal: girth.normal,
                                dX: girth.dX,
                                dY: girth.dY,
                                rotation: girth.rotation,
                              });
                              updateGirthRadius(radius);
                            }}
                            min={0.02}
                          />
                          <span>{' m'}</span>
                        </span>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </>
        )}
        <div className='number-of-stems-container' onClick={(event) => event.stopPropagation()}>
          <div className='input-row'>
            <p>Number of stems (qty):</p>
            {alert?.message && (
              <Tooltip placement='left' title={alert?.message}>
                <WarningAmberOutlinedIcon color='warning' />
              </Tooltip>
            )}
            <span className='number-input'>
              <PlainNumberInput
                value={props.numberOfStems || 0}
                step={1}
                onChange={(value) => {
                  if (props?.disabled || !props?.setNumberOfStems) return;

                  if (value < 0 || value > 15) {
                    debouncedToast('Value cannot be lower than 1 and higher than 15', ToastType.ERROR);
                    return;
                  }

                  props.setNumberOfStems(value);
                }}
                min={Number(0)}
                max={Number(15)}
              />
            </span>
          </div>
        </div>
      </div>
    </AttributeCard>
  );
};
export default TrunkDetailsCard;
