import { useTheme as useMuiTheme } from '@mui/material/styles';
import { CSSProperties, useMemo, useState } from 'react';
import { UndoMicroclimateFunctions } from '../../../@types/MicroclimateAttributes';
import { MicroclimateParams } from '../../../@types/MicroclimateValidation';
import TreeFlowStatus from '../../../enums/TreeFlowStatus';
import { Comment, GetGenusesOptions, GetSpeciesOptions } from '../../../hooks/betaHooks/beta-use-tree';
import Attributes from '../../Icon/Attributes';
import Button from '../../inputs/Button';
import AttributesTab from './AttributeTab';

const buttonStyle: CSSProperties = {
  padding: 6,
  fontWeight: 700,
  fontSize: 13,
  lineHeight: '20px',
};

type AttributesInputProps = {
  tree: any;
  /* updateTree: (id: any, change: any, postPatch?: any) => void; */
  updateTree: (tree_flow_status: TreeFlowStatus.SpeciesValidationQueued | TreeFlowStatus.SpeciesValidationDone) => Promise<void>;
  undo: UndoMicroclimateFunctions;
  style?: React.CSSProperties;
  isToolbarVisible: boolean;
  managedAreaId: string;
  setGenusAndSpecies: (genusId: number | null, speciesId?: number | null) => Promise<any>;
  updateMicroclimate: (options: MicroclimateParams) => Promise<any>;
  addTreeComment: (comment: Comment) => void;
  activeTabs: Record<'left' | 'right', string>;
  moveToNeighbour: (direction: number) => {
    index: number;
    nextIndex: number;
    treeId: string | undefined;
  };
  reloadTrees: () => Promise<any[] | undefined>;
  getGenuses: (options: GetGenusesOptions) => Promise<any[]>;
  getSpecies: (options: GetSpeciesOptions) => Promise<any[]>;
  reloadManagedArea: () => Promise<{ trees: any[]; managedArea: any; pipeline: any; } | undefined>;
};

const AttributesInput = (props: AttributesInputProps): JSX.Element => {
  const muiTheme = useMuiTheme();

  const [genusAndSpeciesModified, setGenusAndSpeciesModified] = useState(false);

  const isTreeCompleted = useMemo(() => props?.tree?.microclimate?.completed, [props.tree])

  const toggleUncertain = async (note: string) => {
    const uncertain = props.tree.microclimate.uncertain ? true : false;
    await props.updateMicroclimate({ ...props.tree.microclimate, uncertain: !uncertain });
  };

  const handleMarkComplete = async () => {
    const treeFlowStatus = !props.tree.microclimate.completed ? TreeFlowStatus.SpeciesValidationDone : TreeFlowStatus.SpeciesValidationQueued;
    await props.updateMicroclimate({ ...props.tree.microclimate, completed: !props.tree.microclimate.completed });
    await props.updateTree(treeFlowStatus)
    await props.reloadManagedArea();
    if (!props.tree.microclimate.completed) {
      props.moveToNeighbour(1);
    }
  };

  const canMarkComplete = useMemo(() => {
    return Boolean(props.tree.microclimate.uncertain) === false && props.tree.species_id && !genusAndSpeciesModified;
  }, [props.tree.species_id, props.tree.microclimate.uncertain, genusAndSpeciesModified]);

  return (
    <div className={`attributes-wrapper`} style={{ backgroundColor: '#202324' }}>
      <div className={`attributes-container`} style={{ ...props.style, backgroundColor: muiTheme.palette.background.default }}>
        <div className='attributes-panel'>
          <p className='panel-title'>
            <Attributes color={muiTheme.palette.text.primary} /> Attributes
          </p>
          <AttributesTab
            tree={props.tree}
            undo={props.undo}
            reloadTrees={props.reloadTrees}
            updateMicroclimate={props.updateMicroclimate}
            getGenuses={props.getGenuses}
            getSpecies={props.getSpecies}
            setGenusAndSpecies={props.setGenusAndSpecies}
            disableGenusAndSpecies={isTreeCompleted}
            onCardStateChange={setGenusAndSpeciesModified}
          />
        </div>
      </div>
      <div className='attributes-actions-wrapper'>
        {!props?.tree?.microclimate?.uncertain && (
          <Button
            id='uncertainButton'
            label='Uncertain'
            primary={true}
            destructive={false}
            disabled={false}
            leadingIcon={'questionMark'}
            style={buttonStyle}
            iconStyle={{ color: '#333' }}
            onClick={toggleUncertain}
          />
        )}
        {isTreeCompleted ? (
          <Button
            id='revertCompleteButton'
            label='Revert Complete'
            primary={true}
            destructive={true}
            leadingIcon={'undo-alt'}
            style={buttonStyle}
            classNames='destructive'
            iconStyle={{ color: 'white' }}
            onClick={handleMarkComplete}
          />
        ) : (
          <Button
            id='markCompleteButton'
            label={props.tree.microclimate.completed ? 'Marked as completed' : 'Mark complete'}
            primary={true}
            destructive={false}
            disabled={!canMarkComplete}
            leadingIcon={'checkmark'}
            style={buttonStyle}
            classNames='success'
            iconStyle={{ color: canMarkComplete ? 'white' : 'rgba(51, 51, 51, 0.5)' }}
            onClick={handleMarkComplete}
          />
        )}
      </div>
    </div>
  );
};

export default AttributesInput;
