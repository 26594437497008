import useStore from '../../../store/useStore';
import ColorPickerCogWheel from '../../ColorPicker/ColorPickerCogWheel';
import RoundCheckbox from '../../inputs/RoundCheckbox';
import PointSizeSlider from '../../Slider/PointSizeSlider';

interface Props {
  configItems: Record<string, string>;
  setConfigItems: React.Dispatch<React.SetStateAction<Record<string, string>>>;
}

const PerspectiveToolbar = ({configItems, setConfigItems}: Props) => {
  const { isEnvVisible, pointClassStyles, pointSize } = useStore((s) => s.pointCloud);
  const { setClassOpacity, setPointSize, setEnvVisible } = useStore((s) => s.pointCloud.actions);

  return (
    <div style={{ position: 'absolute', right: 0, zIndex: 1, padding: 10, display: 'flex', gap: 16, alignItems: 'center' }}>
      <RoundCheckbox
        label='Canopy'
        value={pointClassStyles.canopy.opacity}
        onChange={() => setClassOpacity('canopy', !pointClassStyles.canopy.opacity)}
        disabled={false}
        color='#08CC91'
      />
      <RoundCheckbox
        label='Trunk'
        value={pointClassStyles.trunk.opacity}
        onChange={() => setClassOpacity('trunk', !pointClassStyles.trunk.opacity)}
        disabled={false}
        color='#DB8100'
      />
      <RoundCheckbox
        label='Environment'
        value={isEnvVisible}
        onChange={() => {
          setEnvVisible(!isEnvVisible);
          setClassOpacity('environment', !pointClassStyles.environment.opacity);
        }}
        disabled={false}
        color='#73BCEC'
      />
      <PointSizeSlider pointSize={pointSize} setPointSize={setPointSize} />
      <ColorPickerCogWheel configItems={configItems} setConfigItems={setConfigItems} />
    </div>
  );
};

export default PerspectiveToolbar;
