import useStore from '../../../store/useStore';
import Panorama from '../../Panorama';
import { Vector3 } from 'three';

const PanoramaView = (props: any) => {
  const tree = useStore((s) => s.tree.tree);

  return (
    <>
      <Panorama
        treeId={tree?.id}
        managedAreaCode={tree?.managed_area}
        optionalPosition={tree?.location_local ? new Vector3().fromArray(tree?.location_local) : null}
        enabledMiniMap={false}
        enableGoogleMapsButton={false}
        enableAddTree={false}
      />
    </>
  );
};

export default PanoramaView;
