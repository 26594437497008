import { useEffect, useState } from 'react';
import { Vector3 } from 'three';
import { CardProps } from '../../../@types/CardProps';
import ValidationAction from '../../../enums/ValidationAction';
import useStore from '../../../store/useStore';
import { mergeXYZValues } from '../../../utils/mathUtils';
import AttributeCard from '../../Card/AttributeCard';
import { PlainNumberInput } from '../../inputs/PlainNumberInput';
import SemanticActionsColorMap from '../SemanticActionsColorMap';
import * as _ from 'lodash';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Tooltip } from '@mui/material';
import { METRICS_NAMES, METRICS_VALUE_LIMITS } from './TrunkDetailsCard/Constants';

const TO_FIXED = 2;

const FirstBifurcationCard = ({ disabled, alerts, setAlerts }: CardProps) => {
  const draggedTool = useStore((s) => s.actions.draggedTool);
  const { firstBifVisibility, setFirstBifVisibility, setFirstBif, firstBif } = useStore((s) => ({
    setFirstBifVisibility: s.actions.firstBifurcationPoint.setVisibility,
    setFirstBif: s.actions.firstBifurcationPoint.setState,
    firstBif: s.actions.firstBifurcationPoint.state,
    firstBifVisibility: s.actions.firstBifurcationPoint.visibility,
  }));
  const hasLoadedBif = useStore((s) => s.validation.hasLoadedBif);
  const viewerPositionZ = useStore((s) => s.tree.viewerPosition.z);
  const { setSectionDefaultTarget, setSectionDefaultNormal, setSectionTarget, setSectionNormal } = useStore((s) => ({
    setSectionDefaultTarget: s.pointCloud.actions.section.setDefaultTarget,
    setSectionDefaultNormal: s.pointCloud.actions.section.setDefaultNormal,
    setSectionTarget: s.pointCloud.actions.section.setTarget,
    setSectionNormal: s.pointCloud.actions.section.setNormal,
  }));
  const min = useStore((s) => s.pointCloud.laz?.geometry.boundingBox?.min.z);
  const max = useStore((s) => s.pointCloud.laz?.geometry.boundingBox?.max.z);
  const { activeTool, setActiveTool } = useStore((s) => ({ activeTool: s.actions.activeTool, setActiveTool: s.actions.setActiveTool }));
  const setIsFirstBifurcationCompleted = useStore((s) => s.validation.actions.setIsFirstBifurcationCompleted);
  const isFirstBifurcationCompleted = useStore((s) => s.validation.isFirstBifurcationCompleted);
  const setDefaultDepth = useStore((s) => s.pointCloud.actions.section.setDefaultDepth);

  const [firstBifHeight, setFirstBifHeight] = useState(firstBif.z.toFixed(TO_FIXED));

  const alert = alerts.find((a) => a.name === METRICS_NAMES.FIRST_BIFURCATION_HEIGHT);

  useEffect(() => {
    if (draggedTool !== ValidationAction.FirstBifurcationPoint) return;
    setFirstBifHeight((firstBif.z - viewerPositionZ).toFixed(TO_FIXED));
  }, [draggedTool, firstBif.z, viewerPositionZ]);

  useEffect(() => {
    if (!hasLoadedBif) return;
    setFirstBifHeight((firstBif.z - viewerPositionZ).toFixed(TO_FIXED));
    // Correct height with coordinate system delta after inital load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLoadedBif, viewerPositionZ]);

  const setValue = (value: number) => {
    if (disabled) return;
    setFirstBifHeight(value.toFixed(TO_FIXED));
    const updated = new Vector3().copy(mergeXYZValues(firstBif, { z: value + viewerPositionZ }));
    setFirstBif(updated);
    setSectionTarget(updated);
    setSectionNormal(new Vector3(0, 0, 1));
    setSectionDefaultTarget(updated);
    setSectionDefaultNormal(new Vector3(0, 0, 1));
  };

  const setActiveToolTo = () => {
    if (activeTool === ValidationAction.FirstBifurcationPoint) return;

    setDefaultDepth();
    setSectionTarget(firstBif);
    setSectionNormal(new Vector3(0, 0, 1));
    setActiveTool(ValidationAction.FirstBifurcationPoint);
  };

  useEffect(() => {
    const isHeightInRange = _.inRange(
      Number(firstBifHeight),
      METRICS_VALUE_LIMITS[METRICS_NAMES.FIRST_BIFURCATION_HEIGHT].min,
      METRICS_VALUE_LIMITS[METRICS_NAMES.FIRST_BIFURCATION_HEIGHT].max
    );
    if (!isHeightInRange) {
      const alreadyAlerted = alerts.find((a) => a.name === METRICS_NAMES.FIRST_BIFURCATION_HEIGHT);

      if (!alreadyAlerted?.name) {
        setAlerts((prev) => [
          ...prev,
          {
            name: METRICS_NAMES.FIRST_BIFURCATION_HEIGHT,
            message: `First Bifurcation height must be between ${METRICS_VALUE_LIMITS[METRICS_NAMES.FIRST_BIFURCATION_HEIGHT].min} and ${
              METRICS_VALUE_LIMITS[METRICS_NAMES.FIRST_BIFURCATION_HEIGHT].max
            } ${METRICS_VALUE_LIMITS[METRICS_NAMES.FIRST_BIFURCATION_HEIGHT].unit}`,
          },
        ]);
      }
    } else {
      setAlerts((prev) => prev.filter((a) => a.name !== METRICS_NAMES.FIRST_BIFURCATION_HEIGHT));
    }
  }, [firstBifHeight]);

  return (
    <AttributeCard
      title='First Bifurcation: Height'
      color={SemanticActionsColorMap[ValidationAction.FirstBifurcationPoint]}
      isExpanded={firstBifVisibility}
      onExpand={setFirstBifVisibility}
      setCompleted={setIsFirstBifurcationCompleted}
      disabled={disabled}
      completed={isFirstBifurcationCompleted}
      isActive={activeTool === ValidationAction.FirstBifurcationPoint}
      setIsActive={setActiveToolTo}
    >
      <div className={'body-content'}>
        <div className='card-content'>
          <div className='detail-title'>
            <p>Vertical position</p>
            {alert?.message && (
              <Tooltip placement='left' title={alert?.message}>
                <WarningAmberOutlinedIcon color='warning' />
              </Tooltip>
            )}
          </div>
          <span className='number-input'>
            <PlainNumberInput
              value={parseFloat(firstBifHeight)}
              step={0.01}
              onChange={setValue}
              min={Number(min?.toFixed(TO_FIXED))}
              max={Number(max?.toFixed(TO_FIXED))}
            />
            <span>{' m'}</span>
          </span>
        </div>
      </div>
    </AttributeCard>
  );
};

export default FirstBifurcationCard;
