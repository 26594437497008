import { OrbitControls, OrbitControlsProps } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import { useRef } from 'react';
import { MOUSE } from 'three';
import { OrbitControls as OrbitControlsImpl } from 'three-stdlib';
import useStore from '../../../store/useStore';
import config from '../config';

type Props = { name?: string } & Pick<
  OrbitControlsProps,
  'minZoom' | 'maxZoom' | 'minPolarAngle' | 'maxPolarAngle' | 'minAzimuthAngle' | 'maxAzimuthAngle' | 'target'
>;

const ValidationControls = (props: Props) => {
  const camera = useThree((s) => s.camera);
  const controls = useThree((s) => s.controls);
  const pointCloud = useStore((s) => s.pointCloud.laz);
  const rotationRef = useRef(false);

  const azimuthAngle = useStore((s) => s.validation.azimuthAngle);
  const setAzimuthAngle = useStore((s) => s.validation.actions.setAzimuthAngle);

  useFrame(({ controls }) => {
    if (rotationRef.current) return;

    const orbit = controls as OrbitControlsImpl;
    if (azimuthAngle.current?.toFixed(10) === orbit.getAzimuthalAngle()?.toFixed(10)) return;

    orbit.setAzimuthalAngle(azimuthAngle.current);
    orbit.update();
  });

  return (
    <OrbitControls
      enablePan={true}
      rotateSpeed={config.rotateSpeed}
      minZoom={props.minZoom ?? config.minZoomDefault}
      maxZoom={props.maxZoom ?? config.maxZoomDefault}
      minPolarAngle={props.minPolarAngle}
      maxPolarAngle={props.maxPolarAngle}
      minAzimuthAngle={props.minAzimuthAngle}
      maxAzimuthAngle={props.maxAzimuthAngle}
      dampingFactor={1}
      makeDefault
      onChange={() => {
        if (!controls || !rotationRef.current) return;
        const orbit = controls as OrbitControlsImpl;
        setAzimuthAngle(orbit.getAzimuthalAngle());
      }}
      onStart={() => {
        rotationRef.current = true;
      }}
      onEnd={() => (rotationRef.current = false)}
      camera={camera}
      target={props.target ?? pointCloud?.geometry.boundingSphere?.center}
      mouseButtons={{
        RIGHT: MOUSE.ROTATE,
        MIDDLE: MOUSE.DOLLY,
      }}
    />
  );
};

export default ValidationControls;
