import { useMemo, useRef, useState, useCallback, useEffect } from 'react';
import SkeletonWithManagedAreaMap from '../../layout/SkeletonWithManagedAreaMap';
import TreePopup from '../../components/TreePopup';
import MicroclimateValidation from './MicroclimateValidation';
import { isTreeDone } from './isTreeDone';
import { exportCsv } from './csvExport';
import useApi from '../../hooks/api';
import { useMicroClimateTrees } from '../../hooks/useMicroClimateTrees';
import TreeSelector from '../../components/HeaderBar/TreeSelector';
import { Provider as SavedMicroclimateValuesProvider } from '../../providers/savedMicroclimateValues';
import { useRouteMatch } from 'react-router';
import { BetaManagedAreasContextProvider, useBetaManagedAreasContext } from '../../hooks/betaHooks/beta-managed-areas-context';
import { BetaManagedAreaContextProvider, useBetaManagedAreaContext } from '../../hooks/betaHooks/beta-managed-area-context';
import { navigateMicroclimateTrees } from '../Validation/libs/navigate-microclimate-trees';
import TreeFlowStatus from '../../enums/TreeFlowStatus';

//! Important:
//! We will need to delete Completed status from this list when the full flow is implemented
const MICROCLIMATE_TODO_TREE_STATUSES = [
  TreeFlowStatus.SpeciesValidationQueued,
  TreeFlowStatus.SpeciesValidationRunning,
  TreeFlowStatus.SpeciesValidationDone,
  TreeFlowStatus.Completed, // for a while we will display completed trees as well
];

const generateTabs = ({ path, onPathChange = () => {}, onChangeTree, currentTree, managedArea }) => [
  {
    type: 'link',
    path: `/microclimate-input`,
    active: /\/microclimate-input/.test(path),
    icon: 'map',
  },
  {
    type: 'link',
    active: /\/microclimate-input\/\d*/.test(path),
    onClick: () => onPathChange(''),
    title: managedArea?.code,
  },
  {
    type: 'component',
    active: /validation\/\d/.test(path),
    params: {
      onChangeTree,
      currentTree,
    },
    component: TreeSelector,
  },
];

const generatePhases = (currentTree) => {
  if (!currentTree) return [];
  return [
    {
      status: 'current',
      progress: isTreeDone(currentTree) ? 'complete' : 'incomplete',
      title: 'ESS',
      onClick: () => {},
    },
  ];
};

const layerFilter = [
  {
    id: 'validation_todo',
    source: 'trees',
    type: 'circle',
    statuses: [TreeFlowStatus.SpeciesValidationQueued, TreeFlowStatus.SpeciesValidationRunning],
  },
  {
    id: 'validation_done',
    source: 'trees',
    type: 'circle',
    statuses: [TreeFlowStatus.SpeciesValidationDone, TreeFlowStatus.Completed],
  },
];

const generateTableColumns = (exportCsvCallback, sortTrees, jumpToTree) => (selection) => {
  return selection
    ? [
        {
          title: 'Tree ID',
          key: 'id',
          resolver: 'id',
          sortable: true,
          format: 'ESS_ID',
          onSort: sortTrees,
          clickNavigation: (entry) => jumpToTree(entry),
        },
        {
          title: 'Genus and Species',
          resolver: (t) => `${t.genus_name ?? ''} ${t.species_name ?? ''}`,
          key: 'genus_and_species',
          sortable: true,
          onSort: sortTrees,
        },
        {
          title: 'Genus Confidence',
          resolver: (t) => t.genus_confidence,
          key: 'genus_confidence',
          sortable: true,
          onSort: sortTrees,
          format: 'CONFIDENCE',
        },
        {
          title: 'Species Confidence',
          resolver: (t) => t.species_confidence,
          key: 'species_confidence',
          sortable: true,
          onSort: sortTrees,
          format: 'CONFIDENCE',
        },
        {
          title: 'Crown light exposure',
          resolver: (t) => t.microclimate?.crown_light_exposure ?? '-',
          key: 'crown_light_exposure',
          sortable: true,
          onSort: sortTrees,
        },
        {
          title: 'Status',
          key: 'status',
          resolver: (t) => (isTreeDone(t) ? 'Done' : 'To Do'),
          sortable: true,
          format: 'ESS_STATUS',
          onSort: sortTrees,
        },
        {
          title: 'Completed',
          key: 'completed',
          resolver: (t) => t.microclimate.completed ?? false,
          sortable: true,
          format: 'COMPLETED',
          onSort: sortTrees,
        },
        {
          title: 'Uncertain',
          key: 'uncertain',
          resolver: (t) => t.microclimate.uncertain ?? false,
          sortable: true,
          format: 'UNCERTAIN',
          onSort: sortTrees,
        },
      ]
    : [
        {
          title: 'Area code',
          key: 'code',
          resolver: 'code',
          format: 'CODE',
          sortable: true,
        },
        {
          title: 'OIC',
          key: 'officer_in_charge',
          resolver: (ma) => (ma.oic_full_name ? ma.oic_full_name : ma.oic_user_name) || '-',
          sortable: true,
        },
        {
          title: 'Number of trees',
          key: 'microclimate_params_trees_all',
          resolver: 'microclimate_params_trees_all',
          sortable: true,
        },
        {
          title: 'Todo',
          key: 'microclimate_params_trees_todo',
          resolver: 'microclimate_params_trees_todo',
          sortable: true,
        },
        {
          title: 'Done',
          key: 'microclimate_params_trees_ok',
          resolver: 'microclimate_params_trees_ok',
          sortable: true,
        },
        {
          title: '%',
          key: 'progress',
          format: 'PERCENTAGE',
          resolver: (ma) => ma.microclimate_params_trees_ok / ma.microclimate_params_trees_all || 0,
          sortable: true,
        },
        {
          title: 'Status',
          key: 'status',
          resolver: (ma) => (ma.microclimate_params_trees_ok === ma.microclimate_params_trees_all ? '✅' : 'TODO'),
          format: 'STATUS',
          sortable: true,
        },
        {
          title: 'Download CSV',
          subTitle: 'Dowload all CSVs',
          subTitleAction: generateAllReadyCSVs(exportCsvCallback),
          key: 'download',
          resolver: generateDownloadButton(exportCsvCallback),
          format: 'STATUS',
        },
      ];
};

const generateAllReadyCSVs = (exportCsvCallback) => (mas) => {
  const allReadyToExportMas = mas.filter(
    (ma) => ma.semantic_validation === 'done' && ma.microclimate_params_trees_ok === ma.microclimate_params_trees_all
  );

  exportCsvCallback(allReadyToExportMas);
};
const generateDownloadButton = (exportCsvCallback) => (ma) => {
  const isSemanticDone = ma.semantic_validation === 'done';
  const isMicroclimateDone = ma.microclimate_params_trees_ok === ma.microclimate_params_trees_all;
  return (
    <div
      style={{ cursor: 'copy' }}
      onClick={(event) => {
        event.stopPropagation();
        exportCsvCallback([ma]);
      }}
    >
      💾
    </div>
  );
  /*Remove PRD-370*/
  /*if (isSemanticDone && isMicroclimateDone) {
    return (
      <div
        style={{ cursor: 'copy' }}
        onClick={(event) => {
          event.stopPropagation();
          exportCsvCallback([ma]);
        }}
      >
        💾
      </div>
    );
  } else {
    const semanticState = `Semantic validation: ${doneOrTodo(isSemanticDone)}`;
    const microClimateState = `Microclimate data: ${doneOrTodo(isMicroclimateDone)}`;

    const hint = `${semanticState}\n${microClimateState}`;

    return (
      <div
        title={hint}
        style={{ cursor: 'help' }}
        onClick={(e) => {
          e.stopPropagation();
          alert(hint);
        }}
      >
        Not ready ℹ️
      </div>
    );
  }*/
};

const doneOrTodo = (isDone) => (isDone ? 'done' : 'todo');

const MicroclimateInputComponent = ({ location, history, match }) => {
  const managedAreaId = match.params?.MA ? parseInt(match.params?.MA, 10) : null;
  const mapRef = useRef(null);
  const { handleRequest } = useApi();

  const betaMASCtx = useBetaManagedAreasContext();
  const betaMACtx = useBetaManagedAreaContext();

  useEffect(() => {
    betaMASCtx.reset();
    betaMASCtx.fetch();
  }, [managedAreaId]);

  useEffect(() => {
    betaMACtx.reset();

    if (!managedAreaId) return;

    betaMACtx.fetch();
  }, [managedAreaId]);

  const [selectedTreeId, setSelectedTreeId] = useState(null);
  const [activeTabs, setActiveTabs] = useState({
    left: 'pointcloud',
    right: 'panoramic',
  });

  const pipeline = betaMACtx?.pipeline;
  const managedArea = betaMACtx?.managedArea;
  const trees = betaMACtx?.sortedTrees?.length ? betaMACtx.sortedTrees : betaMACtx.trees;
  // const tree = betaTree.tree || betaMACtx.trees.find(t => t.id === selectedTreeId) || betaMACtx?.trees?.[0];
  const tree = trees?.find((t) => t.id === selectedTreeId) || trees?.[0];

  const betaMASCtxLoading = betaMASCtx.loading;

  const betaMACtxLoading = betaMACtx.loading || betaMACtx.loadingManagedAreaAndPipeline || betaMACtx.loadingTrees;

  const contextsLoadingState = betaMASCtxLoading || betaMACtxLoading;

  const selection = useMemo(() => [managedArea, managedArea?.id], [managedArea?.id]);

  const { moveToNeighbor, selectTree } = useMicroClimateTrees(selectedTreeId, trees);

  const todoTrees = trees.filter(
    (t) => !t?.microclimate?.completed && t?.tree_flow_status && MICROCLIMATE_TODO_TREE_STATUSES.includes(t.tree_flow_status)
  );

  const isActionAvailable = !!pipeline && pipeline?.microclimate_params_trees_todo > 0;

  const _handleTab = async (path) => {
    const navigateTo = `/microclimate-input/${managedAreaId}${path?.length ? `/${path}` : ''}?left=${activeTabs.left}&${activeTabs.right}`;
    managedAreaId && history.push(navigateTo);
  };

  const _handleSkip = async (direction) => {
    if (direction === 1) {
      await betaMACtx.skipTree(tree?.id);
    } else {
      await betaMACtx.removeSkippedTree(tree?.id);
    }
    await navigateMicroclimateTrees({
      trees: todoTrees,
      history,
      currentTreeId: tree?.id,
      managedAreaId: betaMACtx.managedArea?.id,
      direction,
      activeTabs,
      skippedTrees: JSON.parse(localStorage.getItem('skippedTrees') || '[]'),
    });
  };

  /* removed filters in PRD-690 */
  const _handleFilter = useCallback(({ microclimate_params_trees_all, /* location_proposal, semantic_extraction */ }) => {
    return microclimate_params_trees_all > 0 /* && location_proposal !== 'error' && semantic_extraction !== 'error' */;
  }, []);

  const tableFilter = useMemo(() => (!managedAreaId ? _handleFilter : null), [_handleFilter, managedAreaId]);

  const layerFilters = useMemo(() => {
    return layerFilter.map((layer) => ({
      ...layer,
    }));
  }, []);

  const treePopup = useCallback(
    ({ feature, ...props }) => {
      const clickedTree = trees?.find((tree) => Number(tree.id) === Number(feature.id));
      const isMavenTree = feature.properties.status === undefined;

      let treeData;
      if (isMavenTree) {
        treeData = feature;
      } else {
        treeData = {
          ...feature,
          properties: {
            ...feature.properties,
            manual_scientific_name: clickedTree?.manual_scientific_name,
            maven_tree_id: clickedTree?.tree_id,
            spsc_nm: clickedTree?.spsc_nm,
          },
        };
      }

      return <TreePopup tree={treeData} {...props} />;
    },
    [trees]
  );

  const exportCsvCallback = useCallback(
    (managedAreas) => {
      exportCsv(managedAreas, handleRequest);
    },
    [handleRequest]
  );

  const jumpToTree = (tree) => {
    history.push(window.location.pathname.split('/').slice(0, 4).join('/') + '/validation/' + tree.id);
    selectTree(tree);
  };

  const tableColumns = generateTableColumns(exportCsvCallback, trees.sortTreesByDirection, jumpToTree);

  useEffect(() => {
    const storedManagedAreaId = localStorage.getItem('managedAreaId');

    if (storedManagedAreaId && managedArea?.id && storedManagedAreaId.toString() !== managedArea.id.toString()) {
      localStorage.removeItem('skippedTrees');
    }
  }, [managedArea?.id]);

  return (
    <SavedMicroclimateValuesProvider>
      <SkeletonWithManagedAreaMap
        location={location}
        history={history}
        match={match}
        basePath='microclimate-input'
        title='Microclimate input'
        selection={selection}
        trees={trees}
        pipelines={betaMASCtx.filteredPipelines?.length ? betaMASCtx.filteredPipelines : betaMASCtx.pipelines}
        managedAreas={betaMASCtx.filteredManagedAreas?.length ? betaMASCtx.filteredManagedAreas : betaMASCtx.managedAreas}
        pipeline={betaMACtx.pipeline}
        managedArea={managedArea}
        onTreeSelection={setSelectedTreeId}
        contextsLoadingState={contextsLoadingState}
        mapRef={mapRef}
        customColumns={tableColumns}
        tableFilter={tableFilter}
        pipelineFilter={_handleFilter}
        treePopup={treePopup}
        moveToNeighbour={moveToNeighbor}
        activeTabs={activeTabs}
        setActiveTabs={setActiveTabs}
        //currentTree={currentTree}
        useNewHeader={true}
        isToolBarEnabled={true}
        phases={generatePhases(tree)}
        newTabs={generateTabs({
          path: location.pathname,
          location,
          managedArea,
          onPathChange: _handleTab,
          selectedTreeId,
          isActionAvailable,
          currentTree: tree,
          onChangeTree: _handleSkip,
        })}
        screens={[
          {
            title: `${managedArea?.code} | Microclimate Validation`,
            path: '/microclimate-input/:MA/validation/:tree?',
            Component: MicroclimateValidation,
          },
        ]}
        layerFilter={layerFilters}
        startAction={{
          isAvailable: isActionAvailable,
          step: pipeline && 'validation',
          treeId: tree?.id,
        }}
        isMicroClimate={true}
        todoTrees={todoTrees}
      />
    </SavedMicroclimateValuesProvider>
  );
};

const MicroclimateInput = (props) => {
  const match = useRouteMatch();
  const managedAreaId = match.params?.MA ? parseInt(match.params?.MA, 10) : null;

  return (
    <BetaManagedAreasContextProvider isMicroclimate={true}>
      <BetaManagedAreaContextProvider managedAreaId={managedAreaId} isMicroclimate={true}>
        <MicroclimateInputComponent {...props} />
      </BetaManagedAreaContextProvider>
    </BetaManagedAreasContextProvider>
  );
};

export default MicroclimateInput;
