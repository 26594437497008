import { useState, useEffect, useCallback, useMemo } from 'react';
import { Switch, useHistory, useParams } from 'react-router-dom';
import { Skeleton, Route } from '.';
import ManagedAreaMap from './MASelectorMap';
import { useBetaManagedAreaContext } from '../hooks/betaHooks/beta-managed-area-context';
import { useBetaManagedAreasContext } from '../hooks/betaHooks/beta-managed-areas-context';
import getHeaderTitle from '../utils/getHeaderTitle';
import useBetaTableSorter from '../hooks/betaHooks/beta-use-table-sorter';
import { useBetaQueryParams } from '../hooks/betaHooks/beta-use-query-params';

const generateTabs = ({ basePath, isMASelected, onPathChange = () => {}, tabs = [], path }) => [
  {
    title: 'Map',
    icon: 'singapore',
    path: `/${basePath}`,
    exact: true,
    type: 'component',
  },
  {
    title: 'Managed Area',
    icon: 'map-marked-alt',
    active: tabs.filter((_) => !!_).every(({ active }) => active === false) && path.includes(`/${basePath}/`),
    disabled: !isMASelected,
    onClick: () => onPathChange(''),
    type: 'link',
  },
];

const SkeletonWithManagedAreaMap = ({
  location,
  history,
  match,
  basePath = '',
  tabs = [],
  screens = [],
  title,
  mapRef,
  pipelineFilter,
  layerFilter,
  managedAreaFilters,
  layerSources = [
    { id: 'trees', source: 'trees', name: 'Trees' },
    { id: 'mas', source: 'managed_areas', name: 'Managed Areas' },
  ],
  sliders,
  onShouldReload,
  startAction,
  treePopup,
  onTreeSelection,
  controls = [],
  tableHeader,
  treeFilterForTable,
  customColumns = null,
  treeStatusFilter,
  maStatusFilter,
  tableFilter,
  managedArea,
  moveToNeighbour,
  currentTree,
  useNewHeader,
  newTabs,
  phases,
  isToolBarEnabled = false,
  screenType,
  trees,
  managedAreas,
  pipelines,
  pipeline,
  selection,
  setTree,
  treeId,
  updateTree,
  contextsLoadingState,
  onTreesReloadRequest,
  activeTabs,
  setActiveTabs,
  setOfficerInCharge,
  isMicroClimate,
  reloadTrees,
  reFetchManagedAreaAndTrees,
  todoTrees,
  skipTree
}) => {
  const betaMASCtx = useBetaManagedAreasContext();
  const betaMACtx = useBetaManagedAreaContext();
  const {
    sortedPipelines,
    sortedTrees,
    tableSort, 
    sortTable
  } = useBetaTableSorter({
    pipelines, trees, managedAreaId: managedArea?.id
  })

  const [currentTreeId, setCurrentTreeId] = useState(currentTree?.id || null)

  useEffect(() => {
    betaMASCtx.filterPipelinesByStatus(treeStatusFilter, maStatusFilter);
  }, [treeStatusFilter, maStatusFilter])

  const [sortByProximityFirst, setSortByProximityFirst] = useState(false);
  const [proximityAlertTreeIDs, setProximityAlertTreeIDs] = useState([]);
  const [isToolbarVisible, setIsToolbarVisible] = useState(true);
  const [deletedTrees, setDeletedTrees] = useState([]);


  const updateCurrentTreeId = useCallback(
    (id) => {
      setCurrentTreeId(id);
      onTreeSelection?.(id);
    },
    [onTreeSelection]
  );

  const _handleTab = async (path) => {
    const { getQueryParams } = useBetaQueryParams()

    const params = await getQueryParams();

    console.log("params", params);

    return managedArea?.id && history.push(`/${basePath}/${managedArea?.id}/${path}`);
  };

  const _handleMAFocus = () => {
    const ps = sortedPipelines?.length ? sortedPipelines : pipelines;
    const bbox = ps.map((pipeline) => JSON.parse(pipeline?.bbox)?.coordinates);
    if (bbox && bbox.length > 1) mapRef.current.focusOnBBox(bbox);
  };

  const _handleMapFocus = () => {
    if (!mapRef.current) return;
    if (managedArea?.id) mapRef.current.focusOnBBox(managedArea?.frontend_aoi_bbox?.coordinates);
    else _handleMAFocus();
  };

  useEffect(() => {
    _handleMapFocus();
  }, [pipelines?.length, managedArea?.id]);

  const _handleTreeDelete = useCallback((tree) => setDeletedTrees([...deletedTrees, tree?.id || tree]), [deletedTrees]);

  return (
    <Skeleton
      header={{
        user: {},
        title: `${title} ${(managedArea || '') && ' – ' + managedArea.code}${currentTreeId ? ` - ${currentTreeId}` : ''}`,
        headerTitle: isMicroClimate ? title : pipeline?.current_manual_step ? getHeaderTitle(pipeline?.current_manual_step) : title,
        currentTree,
        managedArea: managedArea,
        currentTreeId,
        useNewHeader,
        trees: sortedTrees?.length ? sortedTrees : trees,
        navTabs: newTabs,
        isToolbarVisible,
        setIsToolbarVisible,
        isToolBarEnabled,
      }}
      tabs={[
        ...generateTabs({
          history,
          basePath,
          isMASelected: !!managedArea?.id,
          selectedMA: managedArea?.id,
          pipeline,
          onPathChange: _handleTab,
          tabs,
          path: location.pathname,
        }),
        ...tabs,
      ]}
      phases={phases}
      managedArea={managedArea}
      pipeline={pipeline}
    >
      <Switch>
        {screens
          .filter((screen) => !!screen)
          .map((screen) => (
            <Route
              key={screen.path}
              title={screen.title}
              path={screen.path}
              render={(props) => (
                <screen.Component
                  {...{
                    setCurrentTreeId: updateCurrentTreeId,
                    ...props,
                    trees: sortedTrees?.length ? sortedTrees : trees,
                    managedAreas,
                    pipelines: sortedPipelines?.length ? sortedPipelines : pipelines,
                    startPostValidation: betaMACtx.startPostValidation,
                    selection,
                    moveToNeighbour,
                    currentTree,
                    mapRef,
                    layerSources,
                    sortByProximityFirst,
                    proximityAlertTreeIDs,
                    ...screen.props,
                    isToolbarVisible,
                    setIsToolbarVisible,
                    loaded: props.loaded,
                    managedArea,
                    setTree,
                    treeId,
                    contextsLoadingState,
                    activeTabs,
                    setActiveTabs,
                    setOfficerInCharge,
                    reFetchManagedAreaAndTrees,
                    reloadTrees,
                    isMicroClimate,
                    todoTrees
                  }}
                />
              )}
            />
          ))}

        <Route
          title={`${managedArea?.code || managedArea?.id || 'Overview'} | ${title}`}
          path={`/${basePath}/:MA?`}
          render={(props) => (
            <ManagedAreaMap
              {...{
                setCurrentTreeId: updateCurrentTreeId,
                ...props,
                trees: sortedTrees?.length ? sortedTrees : trees,
                pipeline,
                pipelines: sortedPipelines?.length ? sortedPipelines : pipelines,
                managedArea,
                tableFilter,
                treeStatusFilter,
                managedAreaFilters,
                customColumns,
                maStatusFilter,
                basePath,
                deletedTrees,
                onDelete: _handleTreeDelete,
                startPostValidation: betaMACtx.startPostValidation,
                focusOnMA: _handleMapFocus,
                selection,
                mapRef,
                resetFocus: _handleMAFocus,
                pipelineFilter,
                layerFilter,
                layerSources,
                sliders,
                startAction,
                onShouldReload,
                treePopup,
                controls,
                tableHeader,
                treeFilterForTable,
                setSortByProximityFirst,
                proximityAlertTreeIDs,
                setProximityAlertTreeIDs,
                fullHeight: true,
                screenType,
                contextsLoadingState,
                onTreesReloadRequest,
                isMicroClimate,
                sortTable: (sortableProperty) => sortTable({sortableProperty}),
                tableSort: tableSort,
                todoTrees,
              }}
            />
          )}
        />
      </Switch>
    </Skeleton>
  );
};

export default SkeletonWithManagedAreaMap;
