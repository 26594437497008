import { useFrame, useThree } from '@react-three/fiber';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { Color, DoubleSide, Mesh, MeshBasicMaterial, Vector3 } from 'three';
import { DragControls } from 'three/examples/jsm/controls/DragControls';
import XZYCoords from '../../../@types/XZYCoords';
import config from '../config';
import useForwardedRef from '../hooks/useForwardedRef';
import useScale from '../hooks/useScale';

type Props = {
  name: string;
  initialPosition: Vector3;
  onDrag?: (vector: XZYCoords) => void;
  restrictAxis?: { x?: boolean; y?: boolean; z?: boolean };
  colors?: { point: Color; border: Color; hover: Color };
  color?: Color;
  draggable?: boolean;
  visibility?: boolean;
  handleSize?: number;
  renderOrder?: number;
};

const DragPoint = forwardRef<Mesh, Props>(
  (
    { name, initialPosition, onDrag, restrictAxis, colors, color, handleSize, draggable = true, visibility = true, renderOrder }: Props,
    forwardRef
  ) => {
    const ref = useForwardedRef<Mesh>(forwardRef);
    const ringRef = useRef<Mesh>(null);
    const isDragRef = useRef<boolean>(false);
    const [hover, setHover] = useState(false);
    const { camera, gl } = useThree();
    const { getScaledValue } = useScale();

    const baseSize = handleSize ?? config.minHandleSize;

    const setMaterialColor = (material: MeshBasicMaterial, color?: Color) => (material.color = color ?? new Color('white'));

    const dragHandler = (e: any) => {
      const xzyCoords: XZYCoords = {};
      if (!restrictAxis?.x) xzyCoords.x = e.object.position.x;
      if (!restrictAxis?.y) xzyCoords.y = e.object.position.y;
      if (!restrictAxis?.z) xzyCoords.z = e.object.position.z;

      ref.current!.position.set(e.object.position.x, e.object.position.y, e.object.position.z);
      ringRef.current!.position.set(e.object.position.x, e.object.position.y, e.object.position.z);

      onDrag?.(xzyCoords);
    };

    const onDragEnd = (e: any) => {
      const xzyCoords: XZYCoords = {};
      if (!restrictAxis?.x) xzyCoords.x = e.object.position.x;
      if (!restrictAxis?.y) xzyCoords.y = e.object.position.y;
      if (!restrictAxis?.z) xzyCoords.z = e.object.position.z;

      ref.current!.position.set(e.object.position.x, e.object.position.y, e.object.position.z);
      ringRef.current!.position.set(e.object.position.x, e.object.position.y, e.object.position.z);

      onDrag?.(xzyCoords);
      isDragRef.current = false;
    };

    const onDragStart = () => {
      if (isDragRef.current) return;
      isDragRef.current = true;
    };

    // Set drag event listeners
    useEffect(() => {
      if (!ref.current || !draggable) return;
      const controls = new DragControls([ref.current], camera, gl.domElement);

      controls.addEventListener('drag', dragHandler);
      controls.addEventListener('dragstart', onDragStart);
      controls.addEventListener('dragend', onDragEnd);

      return () => controls.dispose();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [draggable]);

    // Change position
    useEffect(() => {
      if (ref.current) ref.current.position.copy(initialPosition);
      if (ringRef.current) ringRef.current.position.copy(initialPosition);
    }, [initialPosition, ref]);

    // Set position and camera rotation
    useFrame(() => {
      if (ref.current) {
        ref.current.quaternion.copy(camera.quaternion);
        ref.current.position.copy(initialPosition);
      }
      if (ringRef.current) {
        ringRef.current.quaternion.copy(camera.quaternion);
        ringRef.current.position.copy(initialPosition);
      }
    });

    // Set scale
    useFrame(({ camera }) => {
      let scaledValue = getScaledValue(camera.zoom);
      if (hover && draggable) scaledValue *= 1.4;
      if (ref.current) ref.current.scale.set(scaledValue, scaledValue, 1);
      if (ringRef?.current) ringRef.current.scale.set(scaledValue, scaledValue, 1);
    });

    return (
      <group name={name} visible={visibility}>
        <mesh
          ref={ref}
          position={initialPosition}
          name={name}
          onPointerEnter={() => {
            if (!draggable) return;
            setHover(true);
            setMaterialColor(ref.current!.material as MeshBasicMaterial, colors?.hover);
          }}
          onPointerLeave={() => {
            if (!draggable) return;
            if (!isDragRef.current) setHover(false);
            setMaterialColor(ref.current!.material as MeshBasicMaterial, colors?.point);
          }}
        >
          <circleGeometry args={[baseSize, 20]} />
          <meshBasicMaterial color={colors?.point ?? color} depthTest={false} depthWrite={false} transparent side={DoubleSide} />
        </mesh>
        <mesh ref={ringRef} position={initialPosition}>
          <ringGeometry args={[baseSize, baseSize + baseSize * 0.2, 20, 20]} />
          <meshBasicMaterial color={colors?.border ?? color} depthTest={false} depthWrite={false} transparent />
        </mesh>
      </group>
    );
  }
);

export default DragPoint;
