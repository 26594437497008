import { useTheme as useMuiTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { AttributeCardStates, AttributeStateMap } from '../../@types/AttributeCard';
import { IconType } from '../../@types/Icon';
import CarretDown from '../Icon/CarretDown';
import RoundCheckbox from '../inputs/RoundCheckbox';

const ATTRIBUTE_STATE_MAP: AttributeStateMap = {
  empty: 'accepted',
  accepted: 'empty',
};

const ICON_MAP: { [key in AttributeCardStates]: IconType } = {
  accepted: 'check-circle',
  empty: null,
};

const iconStyle = { height: 16, minWidth: 16 };

type CardProps = {
  children?: React.ReactNode;
  icon?: JSX.Element;
  title: string;
  color: string;
  onExpand?: (value: boolean) => void;
  isExpanded: boolean;
  setCompleted: (value: boolean | null) => void;
  disabled: boolean;
  completed: boolean | null;
  isActive: boolean;
  setIsActive: () => void;
};

const AttributeCard = (props: CardProps): JSX.Element => {
  const theme = useMuiTheme();
  const [attributeState, setAttributeState] = useState<AttributeCardStates>('empty');
  const [clicked, setClicked] = useState<boolean>(false);

  const memoizedStateChange = useMemo(() => ATTRIBUTE_STATE_MAP[attributeState], [attributeState]);

  useEffect(() => {
    if (clicked) {
      setClicked(false);
    } else {
      props.completed ? setAttributeState('accepted') : setAttributeState('empty');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.completed]);

  const handleChange = () => {
    let completed = null;
    if (memoizedStateChange === 'accepted') completed = true;
    props.setCompleted(completed);
    setClicked(true);
    setAttributeState(memoizedStateChange);
  };

  return (
    <div className={`attribute-card ${props.isActive ? 'active' : 'inactive'}`} onClick={props.setIsActive}>
      <div className='header'>
        <RoundCheckbox
          value={false}
          onPureChange={handleChange}
          color={props.color}
          disabled={props.disabled}
          icon={ICON_MAP[attributeState]}
        />
        <p className='title'>{props.title}</p>
        <i
          style={iconStyle}
          className={`expand-button ${props.isExpanded ? 'expanded' : 'collapsed'}`}
          onClick={() => props.onExpand?.(!props.isExpanded)}
        >
          <CarretDown color={theme.palette.text.primary} />
        </i>
      </div>
      {props.isExpanded && <div className='body'>{props.children}</div>}
    </div>
  );
};

export default AttributeCard;
