import { useMemo } from 'react';
import { clamp } from 'three/src/math/MathUtils';
import useStore from '../../../store/useStore';
import config from '../config';

const useDefaultZoom = () => {
  const laz = useStore((s) => s.pointCloud?.laz);
  const zoom = useMemo(() => clamp(laz?.geometry?.boundingSphere?.radius as any, config.minZoomDefault, config.maxZoomDefault), [laz]);

  return zoom;
};

export default useDefaultZoom;
