import { useCursor } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Color, Euler, Mesh, Vector3 } from 'three';
import { DragControls } from 'three/examples/jsm/controls/DragControls';
import config from '../../config';
import useForwardedRef from '../../hooks/useForwardedRef';
import Label from '../Label';
import DepthRect from './DepthRect';

type Props = {
  name: string;
  color: string;
  positionZ: number;
  onChange?: (height: number) => void;
  draggable: boolean;
  grabbed?: string | null;
  activeTool?: string;
  setGrabbed?: React.Dispatch<React.SetStateAction<string | null>>;
  lineWidth?: number;
  label?: string;
  visible?: boolean;
  depth?: number;
  viewerPosition?: Vector3;
  allowBelowZeroZ?: boolean;
};

const HeightGizmo = forwardRef<Mesh, Props>(
  (
    {
      name,
      color,
      visible = true,
      positionZ,
      onChange,
      draggable,
      activeTool,
      grabbed = '',
      setGrabbed,
      label,
      lineWidth,
      depth,
      viewerPosition,
      allowBelowZeroZ
    },
    fwdRef
  ) => {
    const { camera, gl } = useThree();
    const ref = useForwardedRef(fwdRef);
    const controlsRef = useRef<DragControls>();
    const outlineRef = useRef<Mesh>(null);
    const isGrabbedRef = useRef<string | null>(grabbed);
    const [hover, setHover] = useState(false);

    // Change pointer style on grab
    useCursor(hover, 'grab', 'auto');

    // Update grabbed ref
    useEffect(() => {
      isGrabbedRef.current = grabbed;
    }, [grabbed, name]);

    // Attach drag event listener
    useEffect(() => {
      if (!ref.current || !draggable) return;

      const controlledObject = outlineRef.current ? [outlineRef.current] : [ref.current];
      controlsRef.current = new DragControls(controlledObject, camera, gl.domElement);

      controlsRef.current.addEventListener('drag', onDrag);
      controlsRef.current.addEventListener('dragstart', onDragStart);
      controlsRef.current.addEventListener('dragend', () => setGrabbed?.(null));

      return () => controlsRef?.current?.dispose();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [draggable]);

    // Setting height on state update
    useEffect(() => {
      if (!ref.current) return;
      ref.current.position.z = positionZ ?? 0;
    }, [positionZ, ref]);

    // Rotate the plane to face the camera
    useFrame(() => {
      if (!ref.current) return;
      ref.current.quaternion.copy(camera.quaternion);
    });

    useFrame(() => {
      if (!ref.current) return;
      ref.current.visible = visible;

      if (!outlineRef.current) return;
      outlineRef.current.position.copy(ref.current.position);
      outlineRef.current.quaternion.copy(ref.current.quaternion);
    });

    const onDrag = (e: any) => {
      if (isGrabbedRef.current !== name) return;
      if (!ref.current) return;

      if (e.object.position.z <= viewerPosition?.z!) {
        if (!allowBelowZeroZ) {
          ref.current.position.z = viewerPosition?.z!;
          return;
        }
      }

      e.object.position.x = 0;
      e.object.position.y = 0;
      ref.current.position.z = e.object.position.z;

      onChange && onChange(e.object.position.z);
    };

    const onDragStart = (e: any) => {
      if (!controlsRef.current) return;
      if (isGrabbedRef.current === name) return;

      controlsRef.current.enabled = false;
    };

    const zVector3 = new Vector3(viewerPosition?.x ?? 0, viewerPosition?.y ?? 0, positionZ ?? 0);

    useFrame(({ camera }) => {
      if (!ref.current) return;
      const scale = (1 / camera.zoom) * 50;
      ref.current.scale.set(1, scale, 1);

      if (!outlineRef.current) return;
      outlineRef.current?.scale.set(1, scale, 1);
    });

    const gizmoLineWidth = lineWidth ?? config.heightLineWidth;
    const outlineLineWidth = gizmoLineWidth * 4;

    return (
      <>
        <group name='grouped-strike'>
          <mesh ref={ref} name={name} renderOrder={999} rotation={new Euler(Math.PI / 2, 0, 0)} frustumCulled={false}>
            <planeGeometry args={[300, gizmoLineWidth]} />
            <meshBasicMaterial color={new Color(color)} depthTest={false} depthWrite={false} />
          </mesh>
          <mesh
            visible={draggable && (hover || name === activeTool)}
            ref={outlineRef}
            renderOrder={999}
            rotation={new Euler(Math.PI / 2, 0, 0)}
            onPointerEnter={() => {
              if (!draggable && !isGrabbedRef.current) return;
              setHover(true);
            }}
            onPointerDown={() => {
              if (!draggable) return;

              setGrabbed?.(name);
              controlsRef.current!.enabled = true;
            }}
            onPointerLeave={() => {
              if (!draggable && isGrabbedRef.current === name) return;
              setHover(false);
            }}
          >
            <planeGeometry args={[300, outlineLineWidth]} />
            <meshBasicMaterial transparent opacity={0.1} />
          </mesh>
        </group>
        {label && <Label text={label} position={zVector3} visible={visible} />}
        {depth && <DepthRect position={zVector3} depth={depth} visible={visible} />}
      </>
    );
  }
);

export default HeightGizmo;
