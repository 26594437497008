import { useMemo, useState } from 'react';
import Tree from '../../@types/Tree';
import * as _ from 'lodash';
import TreeFlowStatus from '../../enums/TreeFlowStatus';

export type TodoStatus =
  | TreeFlowStatus.LocationValidationQueued
  | TreeFlowStatus.SegmentationValidationQueued
  | TreeFlowStatus.MeasurementValidationQueued
  | TreeFlowStatus.SentToOnlineAnnotationQueued;

export interface GetManuallySelectedTreeProps {
  selectedTreeId: string;
  trees: Partial<Tree>[];
}

export interface BetaTreeIndexValue {
  currentTree: Partial<Tree> | null;
  relevantTrees: Partial<Tree>[];
  setCurrentTreeId: React.Dispatch<React.SetStateAction<string>>;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number | null>>;
  selectTree: (selectedTreeId: string) => void;
  moveToNeighbour: (direction?: number) => {
    index: number;
    nextIndex: number;
    treeId: string | undefined;
  };
}

export interface UseBetaTreeIndexProps {
  trees: Partial<Tree>[];
  selectedTreeId: string;
  todoStatuses?: TodoStatus[];
  setTree: React.Dispatch<React.SetStateAction<Partial<Tree> | null>>;
  proximityAlertTreeIDs?: string[];
  sortByProximityFirst?: boolean;
  useTodoTreesOnly?: boolean;
  isMicroclimate?: boolean;
  skippedTrees?: string[];
}

export const useBetaTreeIndex = ({
  trees,
  selectedTreeId,
  todoStatuses,
  setTree,
  proximityAlertTreeIDs,
  sortByProximityFirst = false,
  useTodoTreesOnly = true,
  isMicroclimate = false,
  skippedTrees,
}: UseBetaTreeIndexProps): BetaTreeIndexValue => {
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [currentTreeId, setCurrentTreeId] = useState<string>(selectedTreeId);

  if (currentTreeId === null) {
    throw new Error(`Could not find a beta tree with id ${currentTreeId}`);
  }

  const relevantTrees = useMemo(() => {
    let rTrees: Partial<Tree>[] = trees || [];

    if (isMicroclimate) {
      rTrees = trees.filter((tree) => !tree.microclimate?.completed);
    } else if (useTodoTreesOnly && todoStatuses?.length) {
      rTrees = trees.filter((tree) => tree?.tree_flow_status && todoStatuses.includes(tree.tree_flow_status as TodoStatus));
    }

    if (sortByProximityFirst && proximityAlertTreeIDs) {
      rTrees = [
        ...rTrees.filter((it) => it?.id && proximityAlertTreeIDs.includes(it.id)),
        ...rTrees.filter((it) => it?.id && !proximityAlertTreeIDs.includes(it.id)),
      ];
    }

    return rTrees;
  }, [useTodoTreesOnly, todoStatuses?.length, trees, sortByProximityFirst, proximityAlertTreeIDs, isMicroclimate]);

  let currentTree: Partial<Tree> | null = null;

  if (selectedTreeId && currentIndex === null) {
    // Manual selection
    const tree = trees?.find((tree) => tree.id === selectedTreeId);

    if (!tree) {
      throw new Error(`Could not find a beta tree with id ${selectedTreeId}`);
    }

    currentTree = tree;
  }

  if (!currentTree) {
    if (relevantTrees?.length > 0) {
      // Next/first relevant tree
      currentTree = currentIndex && relevantTrees?.[currentIndex] ? relevantTrees[currentIndex] : relevantTrees[0];
    } else {
      currentTree = trees[0];
    }
  }

  const moveToNeighbour = (direction = 1) => {
    let rTrees = _.cloneDeep(relevantTrees);
    if (direction === 1 && skippedTrees?.length && skippedTrees?.length !== relevantTrees?.length) {
      rTrees = rTrees.filter((t) => !skippedTrees?.includes(t?.id as string));
    } else rTrees = _.cloneDeep(relevantTrees);

    const index = rTrees.findIndex((tree) => tree.id === currentTree?.id) ?? currentIndex;

    const nextIndex = index !== null ? (index + direction + rTrees.length) % rTrees.length : 0;

    const treeId = rTrees[nextIndex]?.id;

    setCurrentIndex(nextIndex);
    setTree(rTrees[nextIndex]);

    return { index, nextIndex, treeId };
  };

  const selectTree = (selectedTreeId: string) => {
    const index = relevantTrees.findIndex((tree) => Number(tree.id) === Number(selectedTreeId));

    if (index > -1) {
      setCurrentIndex(index);
      setTree(relevantTrees[index]);
    }
  };

  return {
    currentTree,
    relevantTrees,

    setCurrentTreeId,
    setCurrentIndex,
    selectTree,
    moveToNeighbour,
  };
};
