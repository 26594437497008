import { Vector3 } from 'three';
import { CardProps } from '../../../@types/CardProps';
import ValidationAction from '../../../enums/ValidationAction';
import useStore from '../../../store/useStore';
import getTrunkLeaningDegree from '../../../utils/getTrunkLeaningDegree';
import AttributeCard from '../../Card/AttributeCard';
import SemanticActionsColorMap from '../SemanticActionsColorMap';
import { METRICS_NAMES, METRICS_VALUE_LIMITS } from './TrunkDetailsCard/Constants';
import { useEffect } from 'react';
import * as _ from 'lodash';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Tooltip } from '@mui/material';

const FirstBifurcationMidpointCard = ({ disabled, alerts, setAlerts }: CardProps) => {
  const { visibility, setVisibility, state: firstBif } = useStore((s) => s.actions.firstBifurcationPoint);
  const leanStartVector = useStore((s) => s.actions.leaningVector.state.startVector);
  const { setSectionTarget, setSectionNormal } = useStore((s) => ({
    setSectionTarget: s.pointCloud.actions.section.setTarget,
    setSectionNormal: s.pointCloud.actions.section.setNormal,
  }));
  const { activeTool, setActiveTool } = useStore((s) => ({ activeTool: s.actions.activeTool, setActiveTool: s.actions.setActiveTool }));
  const setIsFirstBifurcationMidPointCompleted = useStore((s) => s.validation.actions.setIsFirstBifurcationMidPointCompleted);
  const isFirstBifurcationMidPointCompleted = useStore((s) => s.validation.isFirstBifurcationMidPointCompleted);
  const setDefaultDepth = useStore((s) => s.pointCloud.actions.section.setDefaultDepth);

  const leanDegree = leanStartVector ? getTrunkLeaningDegree(leanStartVector, firstBif) : null;

  const alert = alerts.find((a) => a.name === METRICS_NAMES.LEANING_ANGLE);

  const setActiveToolTo = () => {
    if (activeTool === ValidationAction.FirstBifurcationPoint) return;

    setDefaultDepth();
    setSectionTarget(firstBif);
    setSectionNormal(new Vector3(0, 0, 1));
    setActiveTool(ValidationAction.FirstBifurcationPoint);
  };

  useEffect(() => {
    const isLeaningAngleInRange = _.inRange(
      Number(leanDegree),
      METRICS_VALUE_LIMITS[METRICS_NAMES.LEANING_ANGLE].min,
      METRICS_VALUE_LIMITS[METRICS_NAMES.LEANING_ANGLE].max
    );
    if (!isLeaningAngleInRange) {
      const alreadyAlerted = alerts.find((a) => a.name === METRICS_NAMES.LEANING_ANGLE);
      
      if (!alreadyAlerted?.name) {
        setAlerts((prev) => [
          ...prev,
          {
            name: METRICS_NAMES.LEANING_ANGLE,
            message: `Leaning Angle must be between ${METRICS_VALUE_LIMITS[METRICS_NAMES.LEANING_ANGLE].min} and ${
              METRICS_VALUE_LIMITS[METRICS_NAMES.LEANING_ANGLE].max
            } ${METRICS_VALUE_LIMITS[METRICS_NAMES.LEANING_ANGLE].unit}`,
          },
        ]);
      }
    } else {
      setAlerts((prev) => prev.filter((a) => a.name !== METRICS_NAMES.LEANING_ANGLE));
    }
  }, [leanDegree]);

  return (
    <AttributeCard
      title='First Bifurcation: Midpoint'
      color={SemanticActionsColorMap[ValidationAction.FirstBifurcationPoint]}
      isExpanded={visibility}
      onExpand={setVisibility}
      setCompleted={setIsFirstBifurcationMidPointCompleted}
      disabled={disabled}
      completed={isFirstBifurcationMidPointCompleted}
      isActive={activeTool === ValidationAction.FirstBifurcationPoint}
      setIsActive={setActiveToolTo}
    >
      <div className={'body-content'}>
        <div className='card-content'>
          <div className='detail-title'>
            <p>Tree’s leaning angle</p>
            {alert?.message && (
              <Tooltip placement='left' title={alert?.message}>
                <WarningAmberOutlinedIcon color='warning' />
              </Tooltip>
            )}
          </div>
          <span className='number-input'>
            <p>{leanDegree ?? '???'} °</p>
          </span>
        </div>
      </div>
    </AttributeCard>
  );
};

export default FirstBifurcationMidpointCard;
