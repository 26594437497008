import { useTheme } from '@mui/material';
import { PlainNumberInput } from '../inputs/PlainNumberInput';
import LineSlider from './LineSlider';

type VerticalSliderProps = {
  name: string;
  value: number;
  setValue: (value: number) => void;
  step: number;
  min?: number;
  max?: number;
  disabled?: boolean;
  prefix?: string;
  outputConverter?: (value: number) => number;
  inputConverter?: (value: string) => number;
  customeStyle?: React.CSSProperties;
};

const VerticalSlider = ({
  name,
  value,
  setValue,
  step,
  min,
  max,
  disabled = false,
  prefix,
  outputConverter,
  inputConverter,
  customeStyle,
}: VerticalSliderProps) => {
  const theme = useTheme();
  return (
    <div
      className={'vertical-slider'}
      style={{ display: 'flex', flexDirection: 'column', margin: '0 8px', justifyContent: 'space-between' }}
    >
      <p className={'vertical-slider__title'} style={{ fontSize: '10px', alignSelf: 'center' }}>
        {name}
      </p>
      <LineSlider value={value} onChange={setValue} step={step} min={min} max={max} disabled={disabled} vertical />
      <div
        style={{
          backgroundColor: theme.palette.background.default,
          padding: '4px',
          borderRadius: '4px',
          display: 'flex',
          justifyContent: 'space-around',
          marginTop: '12px',
          minWidth: '50px',
        }}
      >
        <span>
          <PlainNumberInput
            value={value}
            step={step}
            onChange={setValue}
            min={min}
            max={max}
            prefix={prefix}
            outputConverter={outputConverter}
            inputConverter={inputConverter}
            customeStyle={customeStyle}
          />
          <span style={{ fontSize: '10px', alignItems: 'center' }}>{'cm'}</span>
        </span>
      </div>
    </div>
  );
};

export default VerticalSlider;
