import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useHotkeys } from 'react-hotkeys-hook';
import { IconType } from '../../@types/Icon';
import { TreeSelectorParams } from '../../@types/TreeSelector';
import useStore from '../../store/useStore';
import Icon from '../Icon';
import useTreeActions from '../SemanticsValidation/hooks/useTreeActions';
import IconButton from '../inputs/IconButton';

const TreeSelector = ({ currentTree, onChangeTree }: TreeSelectorParams): JSX.Element => {

  const selectedTree = currentTree;

  const handleBackwardStep = () => {
    onChangeTree(-1);
  };

  const handleForwardStep = () => {
    onChangeTree(1);
  };

  useHotkeys('left', handleBackwardStep, { keydown: true, enabled: true }, [handleBackwardStep]);
  useHotkeys('right', handleForwardStep, { keydown: true, enabled: true }, [handleForwardStep]);

  return (
    <div className={`tree-selector`}>
      <span>/</span>
      <IconButton icon={faAngleLeft as IconType} onClick={handleBackwardStep} tooltip='Previous tree' />
      <Icon icon='treeIcon' />
      <span>{selectedTree?.id}</span>
      <IconButton icon={faAngleRight as IconType} onClick={handleForwardStep} tooltip='Next tree' />
    </div>
  );
};

export default TreeSelector;
