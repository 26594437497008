import { useCallback, useMemo } from 'react';
import UndoableAction from '../../../@types/UndoableAction';
import { useActionStack } from '../../../core';
import ValidationJobAction from '../../../enums/ValidationJobAction';
import ValidationTool from '../../../enums/ValidationTool';
import useStore from '../../../store/useStore';

const useValidationUndo = (updateTree: (id: any, change: any) => void) => {
  const actions = useStore((s) => s.actions);
  const store = useStore((s) => ({
    setLocation: s.tree.actions.setLocation,
    setEditing: s.validation.actions.setEditing,
    setCurrentTreeIndex: s.tree.actions.setTreeIndex,
    reclassPoints: s.reclassify.points,
    reclassStack: s.reclassify.eventStack,
    handleErrorUndo: s.markError.actions.handleUndo,
    handleReclassUndo: s.reclassify.actions.handleUndo,
    errorPoints: s.markError.points,
    validationTool: s.validation.tool,
  }));

  const [las, originalLas, setLas] = useStore((s) => [s.pointCloud.laz, s.pointCloud.originalLaz, s.pointCloud.actions.setLaz]);

  const _handleUndo = (action: UndoableAction, target: any, from: any, to: any) => {
    if (action === ValidationJobAction.Skip) return store.setCurrentTreeIndex(from);
    if (action === ValidationJobAction.Update) return updateTree(target, from);
    if (action === ValidationJobAction.Position) return store.setLocation(from);
    if (action === ValidationJobAction.SkipAndUpdate) {
      const { index, ...partialTree } = from;
      updateTree(target, partialTree);
      return store.setCurrentTreeIndex(index);
    }
    store.setEditing(action);
    actions[action].setState(from);
    actions[action].prev = from;
  };

  const { onAction, onUndo: undo, isUndoAvailable } = useActionStack(_handleUndo);

  const onUndo = useCallback(() => {
    switch (store.validationTool) {
      case ValidationTool.Error:
        return store.handleErrorUndo();
      case ValidationTool.Reclassify:
        return store.handleReclassUndo(las, originalLas, setLas);
      default:
        return undo();
    }
  }, [las, originalLas, setLas, store, undo]);

  const canUndo = useMemo(() => {
    switch (store.validationTool) {
      case ValidationTool.Error:
        return !!store.errorPoints.length;
      case ValidationTool.Reclassify:
        return !!store.reclassStack.length || !!store.reclassPoints.length;
      default:
        return isUndoAvailable;
    }
  }, [isUndoAvailable, store.errorPoints.length, store.reclassPoints.length, store.reclassStack.length, store.validationTool]);

  return {
    onAction,
    canUndo,
    onUndo,
  };
};

export default useValidationUndo;
