import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as THREE from 'three';
import { Finished, ProgressControls, SegmentationEditor } from '../../components';
import appConfig from '../../config';
import { useActionStack } from '../../core';
import { validationActions } from '../../core/progressActions';
import useApi from '../../hooks/api';
import { useConfig } from '../../providers/config';
import { useModal } from '../../providers/modal';
import { useTheme } from '../../providers/theme';
import LASLoader from '../../utils/LASLoader';
import { useHistory } from 'react-router-dom';
import { LoaderWrapper } from '../../layout';
import { useBetaTreeIndex } from '../../hooks/betaHooks/beta-use-tree-index';
import { CommentTypes, useBetaTree } from '../../hooks/betaHooks/beta-use-tree';
import { useBetaManagedAreaContext } from '../../hooks/betaHooks/beta-managed-area-context';
import ValidationTool from '../../enums/ValidationTool';
import useStore from '../../store/useStore';
import useLoadLaz from '../../components/SemanticsValidation/hooks/useLoadLaz';
import ValidationAction from '../../enums/ValidationAction';
import TreeFlowStatus from '../../enums/TreeFlowStatus';
import TreeStatus from '../../enums/TreeStatus';

const treeLoadingManager = new THREE.LoadingManager();
const treeLazLoader = new LASLoader(treeLoadingManager);

const environmentLoadingManager = new THREE.LoadingManager();
const environmentLazLoader = new LASLoader(environmentLoadingManager);

const generateUrl = (id) => window.location.pathname.split('/').slice(0, 4).join('/') + '/' + id;

const calculateGirth = (girth) => 2 * Math.PI * Math.sqrt((Math.pow(girth.rX, 2) + Math.pow(girth.rY, 2)) / 2);

const defaultFirstBifurcationValue = `{"type":"Point","coordinates":[0,0,0],"isInitial":true}`;

const parseGirth = (tree) => {
  if (tree.girth_1_0m) {
    return {
      dX: tree.girth_1_0m_offset_x,
      dY: tree.girth_1_0m_offset_y,
      rX: tree.girth_1_0m_ellipse_a || 1,
      rY: tree.girth_1_0m_ellipse_b || 1,
      rotation: tree.girth_1_0m_ellipse_direction,
      diameter: null,
    };
  } else {
    const GIRTH_FALLBACK = 2;

    const radius = GIRTH_FALLBACK / (2 * Math.PI);
    return {
      dX: 0,
      dY: 0,
      rX: radius,
      rY: radius,
      rotation: 0,
      diameter: null,
    };
  }
};

export const RECLASS_TOOLS = {
  BRUSH: 'BRUSH',
  POLYGON: 'POLYGON',
};

export const ERROR_TOOLS = {
  POINT: 'POINT',
  LINE: 'LINE',
  POLYGON: 'POLYGON',
};

const showPointCloudChangeCountBeforeLimitValue = appConfig.maxUndo - 3;

const SegmentationValidation = ({
  trees,
  loaded,
  match,
  startPostValidation,
  selection,
  saveError,
  setCurrentTreeId,
  onTreeAdd,
  treeId,
  managedArea,
  contextsLoadingState,
  layerSources,
  reFetchManagedAreaAndTrees,
}) => {
  const betaTree = useBetaTree({
    treeId: match.params?.tree,
    managedAreaId: managedArea?.id,
    managedAreaCode: managedArea?.code,
  });

  const currentTree = betaTree?.tree;

  const betaManagedAreaContext = useBetaManagedAreaContext();

  const { getConfig } = useConfig();
  // Default states
  const { requestHeader, getUrl } = useApi();
  const history = useHistory();

  // TODO: refactor, move this from here
  const changeHandler = (setState, prev, timeout, action) => (val) => {
    // todo: this now commits the changes when the user stopped moving the mouse
    // for 240 ms, even if she still presses the button.
    // I think it would be better to have a 'changeInProgressHandler' and 'changeDoneHandler',
    // implemented in the lower levels, and also in ActionStack, so here we don't need
    // a lot of duplication

    setState((prevState) => {
      if (!prev.current) prev.current = prevState;

      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        onAction(action, null, prev.current);
        prev.current = val;
      }, 240);

      return val;
    });
  };
  // const [isMaFinished, setIsMaFinished] = useState(false);
  const [finished, setFinished] = useState(false);

  const [height, setHeight] = useState(3);
  const heightPrev = useRef(null);
  const heightTimeout = useRef(null);
  const heightHandler = changeHandler(setHeight, heightPrev, heightTimeout, 'height');

  const [canopyHeight, setCanopyHeight] = useState(1);
  const canopyHeightPrev = useRef(null);
  const canopyHeightTimeout = useRef(null);
  const canopyHeightHandler = changeHandler(setCanopyHeight, canopyHeightPrev, canopyHeightTimeout, 'canopyHeight');

  const [trunkHeight, setTrunkHeight] = useState(0.7);
  const trunkHeightPrev = useRef(null);
  const trunkHeightTimeout = useRef(null);
  const trunkHeightHandler = changeHandler(setTrunkHeight, trunkHeightPrev, trunkHeightTimeout, 'trunkHeight');

  const [firstBifurcationPoint, setFirstBifurcationPoint] = useState(defaultFirstBifurcationValue);
  const firstBifurcationPointPrev = useRef(null);
  const firstBifurcationPointTimeout = useRef(null);
  const firstBifurcationPointHandler = changeHandler(
    setFirstBifurcationPoint,
    firstBifurcationPointPrev,
    firstBifurcationPointTimeout,
    'firstBifurcationPoint'
  );

  const [girth1, setGirth1] = useState({
    dX: 0,
    dY: 0,
    rX: 1,
    rY: 2,
    rotation: 0,
    diameter: null,
  });
  const girth1Prev = useRef(null);
  const girth1Timeout = useRef(null);
  const girth1Handler = changeHandler(setGirth1, girth1Prev, girth1Timeout, 'girth1');

  const [canopy, setCanopy] = useState({
    dX: 0,
    dY: 0,
    rX: 1.8,
    rY: 2.2,
    rotation: 0.1,
    diameter: null,
  });
  const canopyPrev = useRef(null);
  const canopyTimeout = useRef(null);
  const canopyHandler = changeHandler(setCanopy, canopyPrev, canopyTimeout, 'canopy');

  const [panoramicLoading, setPanoramicLoading] = useState(false);
  const [position, setPosition] = useState([]);
  const [editing, setEditing] = useState('height');
  const [las, setLas] = useState(null);
  const [originalLas, setOriginalLas] = useState(null);
  const [environmentLas, setEnvironmentLas] = useState(null);
  const [isEnvironmentNotFound, setEnvironmentNotFound] = useState(false);
  const [isEnvironmentVisible, setIsEnvironmentVisible] = useState(false);
  const [rotationHandler, setRotationHandler] = useState(null);
  const { isDark } = useTheme();
  const init = useRef(false);
  const { presentModal, dismissModal } = useModal();
  const _setLas = useStore((s) => s.pointCloud.actions.setLaz);
  const { actions: treeAction } = useStore((s) => s.tree);
  const { setActiveTool } = useStore((s) => ({ setActiveTool: s.actions.setActiveTool }));
  const setDefaultDepth = useStore((s) => s.pointCloud.actions.section.setDefaultDepth);

  // Reclassify
  const [actionMode, setActionMode] = useState(ValidationTool.Inspection_View);
  const [reclassTool, setReclassTool] = useState(RECLASS_TOOLS.BRUSH);
  const [brushRadius, setBrushRadius] = useState(12);
  const [reclassPoints, setReclassPoints] = useState([]);
  const [reclassStates, setReclassStates] = useState([]);
  const [toasts, setToasts] = useState([]);

  const _handleTSEJob = () => {
    startPostValidation(selection[0], 'segmentation_validation');
    setFinished(true);
  };

  const showToast = (msg, type) => {
    setToasts((toasts) => [...toasts, { msg, type }]);
    setTimeout(() => setToasts((toasts) => toasts.slice(1)), 6000);
  };

  // Errors
  const [errorTool, setErrorTool] = useState(ERROR_TOOLS.POINT);
  const [errorPoints, setErrorPoints] = useState([]);

  useEffect(() => {
    if (!currentTree) return;

    setActiveTool(ValidationAction.Girth);
    setDefaultDepth();

    treeAction.setTree({
      ...currentTree,
      location: JSON.parse(currentTree?.location),
    });
  }, [treeAction, currentTree?.id]);

  useLoadLaz(setCurrentTreeId, managedArea?.code);

  const toDos = trees
    .filter(
      (tree) =>
        tree.tree_flow_status === TreeFlowStatus.SegmentationValidationQueued ||
        tree.tree_flow_status === TreeFlowStatus.SentToOnlineAnnotationQueued
    )
    .map((tree, index) => ({ ...tree, index }));

  const { setCurrentIndex, moveToNeighbour, selectTree } = useBetaTreeIndex({
    trees,
    currentTreeId: treeId,
    todoStatuses: [TreeFlowStatus.SegmentationValidationQueued, TreeFlowStatus.SentToOnlineAnnotationQueued],
    setTree: (tree) => history.push(`/validation/${managedArea.id}/segmentation/${tree.id}`),
  });

  useEffect(() => {
    setFirstBifurcationPoint(defaultFirstBifurcationValue);
  }, [currentTree?.id]);

  const _handleUndo = (action, target, from, to) => {
    if (action === 'SKIP') return setCurrentIndex(from);
    if (action === 'UPDATE') return betaTree.updateTree(from);
    if (action === 'POSITION') return setPosition(from);

    setEditing(action);

    if (action === 'height') {
      setHeight(from);
      heightPrev.current = from;
    }
    if (action === 'canopyHeight') {
      setCanopyHeight(from);
      canopyHeightPrev.current = from;
    }
    if (action === 'trunkHeight') {
      setTrunkHeight(from);
      trunkHeightPrev.current = from;
    }
    if (action === 'girth1') {
      setGirth1(from);
      girth1Prev.current = from;
    }
    if (action === 'canopy') {
      setCanopy(from);
      canopyPrev.current = from;
    }
    if (action === 'firstBifurcationPoint') {
      setFirstBifurcationPoint(from);
      firstBifurcationPointPrev.current = from;
    }
  };

  const handleErrorUndo = () => {
    if (errorPoints.length > 0) {
      setErrorPoints((val) => val.slice(0, -1));
    }
  };

  /*   const handleReclassUndo = () => {
    let geometry;

    if (reclassPoints.length) {
      setReclassPoints((curr) => curr.slice(0, -1));
    } else {
      switch (reclassStates.length) {
        case 0:
          return;
        case 1:
          geometry = originalLas.geometry;
          break;
        default:
          geometry = reclassStates[reclassStates.length - 2]?.state;
      }

      setLas({ ...las, geometry });
      const states = reclassStates.slice(0, -1);
      setReclassStates(states);
    }
  }; */

  const { onAction, /* onUndo ,*/ isUndoAvailable } = useActionStack(_handleUndo);

  /*   const handleUndo = () => {
    switch (actionMode) {
      case ValidationTool.Error:
        return handleErrorUndo();
      case ValidationTool.Reclassify:
        return handleReclassUndo();
      default:
        return onUndo();
    }
  }; */

  const canUndo = useMemo(() => {
    switch (actionMode) {
      case ValidationTool.Error:
        return !!errorPoints.length;
      case ValidationTool.Reclassify:
        return !!reclassStates.length || !!reclassPoints.length;
      default:
        return isUndoAvailable;
    }
  }, [actionMode, errorPoints.length, isUndoAvailable, reclassPoints.length, reclassStates.length]);

  const _handleLasLoad = () => {
    setLas(null);
    _setLas(null);
    setEditing('height');

    // const url = getUrl(
    //   `/v1/proxy/blob?url=tasks/${selection[0].code}/tree_segmentation_clips/${currentTree?.id}.laz`
    // );

    const url = getUrl(`/v1/proxy_v2/tree-data?url=${currentTree.tree_segmentation_laz}`);

    treeLazLoader.setRequestHeader(requestHeader);
    treeLazLoader.load(url, (pointcloud) => {
      setOriginalLas(pointcloud);
      setLas(pointcloud);
      _setLas(pointcloud);
    });
  };

  useEffect(() => {
    const _handleEnvironmentLasLoad = () => {
      // const url = getUrl(
      //   `/v1/proxy/blob?url=tasks/${selection[0].code}/tree_segmentation_clips/${currentTree?.id}_environment.laz`
      // );

      const url = getUrl(`/v1/proxy_v2/tree-data?url=${currentTree?.tree_segmentation_environment_laz}`);

      environmentLazLoader.setRequestHeader(requestHeader);
      environmentLazLoader.load(
        url,
        (pointcloud) => setEnvironmentLas({ id: currentTree.id, pointcloud }),
        () => {},
        () => setEnvironmentNotFound(true)
      );
    };

    if (environmentLas?.id !== currentTree?.id) {
      setEnvironmentLas(null);
      setEnvironmentNotFound(false);

      if (currentTree?.id && isEnvironmentVisible) {
        setEnvironmentLas({ id: currentTree?.id });
        _handleEnvironmentLasLoad();
      }
    }
  }, [
    currentTree?.id,
    currentTree?.tree_segmentation_environment_laz,
    environmentLas,
    getUrl,
    isEnvironmentVisible,
    requestHeader,
    selection,
  ]);

  const _handleInit = () => {
    init.current = true;
    if (!currentTree) window.history.pushState(null, null, generateUrl(currentTree?.id));
    else {
      const index = toDos.findIndex((tree) => String(tree.id) === String(currentTree));
      if (index >= 0) setCurrentIndex(index);
    }
  };

  useEffect(() => {
    if (currentTree?.id) setCurrentTreeId(currentTree.id);
    if (!selection[0]?.code) return;
    if (currentTree?.id && !init.current) _handleInit();
    else if (currentTree?.id && init.current) window.history.pushState(null, null, generateUrl(currentTree?.id));
    if (currentTree?.id) {
      _handleLasLoad();
      _handleTreeLoaded();
    }

    if (currentTree?.location_local) {
      setPosition(currentTree.location_local);
    }

    setActionMode(ValidationTool.Inspection_View);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTree?.id, selection[0]?.code]);

  const _handleTreeLoaded = () => {
    const tree = currentTree;

    heightPrev.current = tree.height;
    setHeight(tree.height);

    canopyHeightPrev.current = tree.crown_height;
    setCanopyHeight(tree.crown_height);

    trunkHeightPrev.current = tree.trunk_height;
    setTrunkHeight(tree.trunk_height);

    girth1Prev.current = parseGirth(tree);
    setGirth1(girth1Prev.current);

    const crownExcentricity = tree.crown_excentricity
      ? Array.isArray(tree.crown_excentricity)
        ? tree.crown_excentricity
        : JSON.parse(tree.crown_excentricity).coordinates
      : [0, 0];

    canopyPrev.current = {
      dX: crownExcentricity[0] || 0,
      dY: crownExcentricity[1] || 0,
      rX: tree.canopy_ellipse_a || 1.8,
      rY: tree.canopy_ellipse_b || 2.2,
      rotation: tree.canopy_ellipse_direction || 0.1,
      diameter: null,
    };
    setCanopy(canopyPrev.current);
  };

  const _handleSkip =
    (direction = 1) =>
    () => {
      const { index, nextIndex } = moveToNeighbour(direction);
      onAction('SKIP', null, index, nextIndex);
    };

  const updateReclassPoints = (points) => {
    setReclassPoints(points);
  };

  const addErrorPoint = (point) => {
    if (errorTool === ERROR_TOOLS.POINT) {
      setErrorPoints([point]);
    } else {
      setErrorPoints((points) => [...points, point]);
    }
  };

  const _handleSaveError = async (comment, azimuthAngle, polarAngle, zoom, worldPos, worldDir) => {
    const payload = {
      errorType: errorTool,
      points: errorPoints.map((p) => p.point),
      treeId: currentTree?.id,
      comment,
      azimuthAngle,
      polarAngle,
      cameraScale: zoom,
      cameraPosition: { x: worldPos.x, y: worldPos.y, z: worldPos.z },
      cameraDirectionVector: { x: worldDir.x, y: worldDir.y, z: worldDir.z },
    };
    try {
      await saveError(payload);
      dismissModal();
      setErrorPoints([]);
    } catch (err) {
      console.error('Error during tree error save:', err);
    }
  };

  const _handleCancelError = () => {
    setErrorPoints([]);
    dismissModal();
  };

  const updateGeometry = ({ geometry, changes }) => {
    if (!changes.indices.length) return;

    let shouldUpdateGeometry = true;
    setReclassStates((curr) => {
      curr.push({ state: geometry, changes });

      const changeLimitReached = curr.length >= appConfig.maxUndo;
      const overChangeLimit = curr.length > appConfig.maxUndo;
      if (changeLimitReached) {
        showToast(`Save changes before adding more!`, 'error');
        if (overChangeLimit) {
          curr.pop();
          shouldUpdateGeometry = false;
        }

        return curr;
      }

      const closeToChangeLimit = curr.length >= showPointCloudChangeCountBeforeLimitValue;
      if (closeToChangeLimit) {
        showToast(`Changes left before save: ${appConfig.maxUndo - curr.length}`, 'warning');
      }

      if (curr) return curr;
    });

    if (!shouldUpdateGeometry) return;

    setLas({ ...las, geometry, pc: { ...las.pc, geometry } });
    _setLas({ ...las, geometry, pc: { ...las.pc, geometry } });
  };

  const changeActionMode = (newMode, isSemantics) => {
    const mode = newMode;

    resetGeometry();

    setActionMode((current) =>
      !isSemantics && (!ValidationTool[mode] || current === ValidationTool[mode]) ? ValidationTool.Inspection_View : ValidationTool[mode]
    );
  };

  const changeReclassTool = (newTool) => {
    if (!RECLASS_TOOLS[newTool]) {
      setReclassTool(RECLASS_TOOLS.BRUSH);
    } else if (reclassTool === RECLASS_TOOLS[newTool]) {
      return;
    } else {
      setReclassTool(RECLASS_TOOLS[newTool]);
    }

    setReclassPoints([]);
  };

  const changeErrorTool = (newTool) => {
    if (!ERROR_TOOLS[newTool]) {
      setErrorTool(ERROR_TOOLS.POINT);
    } else if (errorTool === ERROR_TOOLS[newTool]) {
      return;
    } else {
      setErrorTool(ERROR_TOOLS[newTool]);
    }

    setErrorPoints([]);
    dismissModal();
  };

  const resetGeometry = () => {
    setReclassPoints([]);
    setReclassStates([]);
    setLas(originalLas);
    _setLas(originalLas);
  };

  const _handleSaveLAZ = async () => {
    const payload = {
      lastUrl: currentTree.tree_segmentation_laz,
      blobName: currentTree.tree_segmentation_laz,
      changes: reclassStates.map((rcs) => rcs.changes),
      treeId: currentTree?.id,
    };

    try {
      await betaTree.saveLaz(payload);
      setReclassPoints([]);
      setReclassStates([]);
      setOriginalLas(las);
      showToast('LAZ file saved successfully!');
    } catch (err) {
      showToast('Failed to save LAZ file!', 'error');
      console.error('Error during LAZ update:', err);
    }
  };

  const handleCompletedOnlineAnnotatedTrees = useCallback(async () => {
    if (toDos.length > 0) return null;

    trees.forEach(async (t) => {
      if (t.tree_flow_status === TreeFlowStatus.SentToOnlineAnnotationDone) {
        await betaTree.updateTreeWithId(t.id, { tree_flow_status: TreeFlowStatus.SegmentationValidationDone }, false);
      }
    });
  }, [toDos, betaTree, trees]);

  const environmentToggleProps = useMemo(() => {
    return {
      label: 'Show environment',
      value: isEnvironmentVisible,
      onPureChange: () => setIsEnvironmentVisible((prev) => !prev),
      disabled: isEnvironmentVisible && !environmentLas?.pointcloud && !isEnvironmentNotFound,
    };
  }, [environmentLas?.pointcloud, isEnvironmentNotFound, isEnvironmentVisible]);

  //? INFO: We are not setting 'isMaFinished' anywhere
  const isLoading = () => {
    return !las || !currentTree?.location || betaTree?.loading || contextsLoadingState || panoramicLoading;
  };

  if (!managedArea?.id) return null;

  return (
    <LoaderWrapper loading={!currentTree?.id || isLoading()}>
      <Finished
        finished={toDos.length === 0}
        onStart={_handleTSEJob}
        done={finished}
        managedAreaId={managedArea.id}
        reloadManagedAreas={reFetchManagedAreaAndTrees}
        handleCompletedOnlineAnnotatedTrees={handleCompletedOnlineAnnotatedTrees}
      >
        <div className='semantics-editor-wrapper'>
          <div className='viewers'>
            <SegmentationEditor
              // Cannot be moved to state (component receives it as prop)
              selection={selection}
              onTreeAdd={onTreeAdd}
              // Not needed props
              environmentToggleProps={environmentToggleProps} // this is used in index.js, calculate/memoize data there
              isDark={isDark} // useTheme can be called anywhere
              getConfig={getConfig} // useConfig hook can be called anywhere
              loading={las === null} // this check can be done from the store
              background={isDark ? 0x000000 : 0xffffff} // useTheme hook can be called anywhere
              lockView={!!errorPoints.length || !!reclassPoints.length} // can be calculated anywhere
              calculateGirth={calculateGirth} // simple function, only used inside a single mover (girth)
              // -> Moved to ValidationSlice
              setRotationHandler={setRotationHandler}
              actionMode={actionMode} // Renamed to ValidationTool
              editing={editing}
              setEditing={setEditing}
              // -> Moved to TreeSlice
              tree={currentTree}
              position={position}
              onPositionChange={setPosition} // same as setPosition
              // -> Moved to ActionSlices
              height={height}
              onHeightChange={heightHandler}
              trunkHeight={trunkHeight}
              onTrunkHeightChange={trunkHeightHandler}
              canopyHeight={canopyHeight}
              onCanopyHeightChange={canopyHeightHandler}
              canopy={canopy}
              onCanopyChange={canopyHandler}
              girth1={girth1}
              onGirth1Change={girth1Handler}
              firstBifurcationPoint={firstBifurcationPoint}
              onFirstBifurcationPointChange={firstBifurcationPointHandler}
              // -> Moved to PointCloudSlice
              pointcloud={las}
              environmentPointcloud={environmentLas?.pointcloud}
              isEnvironmentVisible={isEnvironmentVisible}
              // -> Moved to ReclassifySlice
              brushRadius={brushRadius}
              setBrushRadius={setBrushRadius}
              reclassTool={reclassTool}
              changeReclassTool={changeReclassTool}
              reclassPoints={reclassPoints}
              reclassStates={reclassStates}
              updateReclassPoints={updateReclassPoints}
              saveLAZ={_handleSaveLAZ}
              updateGeometry={updateGeometry}
              // -> Moved to MarkErrorSlice
              errorTool={errorTool}
              changeErrorTool={changeErrorTool}
              errorPoints={errorPoints}
              addErrorPoint={addErrorPoint}
              saveError={_handleSaveError}
              cancelError={_handleCancelError}
              handleErrorUndo={handleErrorUndo}
              toasts={toasts}
              showToast={showToast}
              // useModal should be refactored from Context to use Store and React.Portal
              presentModal={presentModal}
              dismissModal={dismissModal}
              selectTree={selectTree}
              setPanoramicLoading={setPanoramicLoading}
              layerSources={layerSources}
            />
          </div>
          <ProgressControls
            max={trees?.length}
            value={trees.length - toDos.length}
            hideProgressBar={false}
            actions={validationActions({
              // onUndo: handleUndo,
              isUndoAvailable: canUndo,
              tree: currentTree,
              markAsDone: async (tree, status) => {
                await betaTree.markAsDoneSegmentation();
                await betaTree.addComment({
                  comment: 'OK',
                  comment_type: CommentTypes.COMMENT,
                  isUndoAction: false,
                });
                await betaManagedAreaContext.fetchTrees();
                await moveToNeighbour(1);
              },
              updateTree: async (status, tree_flow_status) => {
                await betaTree.updateTree({ status, tree_flow_status });
                await betaManagedAreaContext.fetchTrees();
                await moveToNeighbour(1);
              },
              showTodoAction: true,
              commentTree: async (_, comment) => {
                await betaTree.addComment({
                  comment: comment.value,
                  comment_type: CommentTypes.COMMENT,
                  isUndoAction: false,
                });
              },
              removeTreeComment: async (cid) => {
                await betaTree.removeComment(cid);
              },
              removeTree: async (args, status) => {
                await betaTree.deleteTreeSegmentation();
                await betaManagedAreaContext.fetchTrees();
                moveToNeighbour(1);
              },
              sendToField: () => alert('Unimplemented feature'),
              onSkip: _handleSkip,
              onRotation: rotationHandler,
              numberOfTrees: toDos.length,
              validationTool: actionMode,
              changeTool: changeActionMode,
              dismissModal,
              presentModal,
              showComment: true,
              isSemantics: true,
              showDeleteConfirmation: true,
              onSetTmsCategory: async (category) => {
                if (category === 'l3') {
                  await betaTree.updateTree({
                    tms_category: 'l3',
                    status: TreeStatus.SentToField,
                    tree_flow_status: TreeFlowStatus.SentToField,
                  });
                } else {
                  await betaTree.setTMSCategory(category);
                }
                await betaManagedAreaContext.fetchTrees();

                if (category === 'l3') {
                  moveToNeighbour(1);
                }
              },
              showTmsButtons: true,
              showTmsL3Button: true,
              showNavigationArrows: false,
            })}
          />
        </div>
      </Finished>
    </LoaderWrapper>
  );
};

export default SegmentationValidation;
