import { useEffect, useState } from 'react';
import { CardProps } from '../../../@types/CardProps';
import ValidationAction from '../../../enums/ValidationAction';
import useStore from '../../../store/useStore';
import { circumferenceOfEllipse, radiusFromCircumference } from '../../../utils/mathUtils';
import AttributeCard from '../../Card/AttributeCard';
import { PlainNumberInput } from '../../inputs/PlainNumberInput';
import SemanticActionsColorMap from '../SemanticActionsColorMap';
import { METRICS_NAMES, METRICS_VALUE_LIMITS } from './TrunkDetailsCard/Constants';
import * as _ from 'lodash';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Tooltip } from '@mui/material';

const TO_FIXED = 2;

const CanopyCard = ({ disabled, alerts, setAlerts }: CardProps) => {
  const { state, visibility, setVisibility } = useStore((s) => s.actions.canopy);
  const updateRadius = useStore((s) => s.actions.updateRadius);

  const { activeTool, setActiveTool } = useStore((s) => ({ activeTool: s.actions.activeTool, setActiveTool: s.actions.setActiveTool }));
  const setIsCanopyCompleted = useStore((s) => s.validation.actions.setIsCanopyCompleted);
  const isCanopyCompleted = useStore((s) => s.validation.isCanopyCompleted);

  const resetSectionTargetToDefault = useStore((s) => s.pointCloud.actions.section.resetSectionTargetToDefault);
  const setDefaultDepth = useStore((s) => s.pointCloud.actions.section.setDefaultDepth);

  const [circumference, setCircumference] = useState(circumferenceOfEllipse(state?.rX ?? 0.1, state?.rY ?? 0.1));

  const alert = alerts.find((a) => a.name === METRICS_NAMES.CROWN_CIRCUMFERENCE);

  const diameterA = state?.rX * 2 ?? 0.01;
  const diameterB = state?.rY * 2 ?? 0.01;

  useEffect(() => {
    setCircumference(circumferenceOfEllipse(state?.rX ?? 0.01, state?.rY ?? 0.01));
  }, [state?.rX, state?.rY, circumference]);

  const setCircumferenceByDiameter = (diameter: number, axis: 'rX' | 'rY') => {
    const selectedRadius = diameter ? diameter / 2 : 0.01;

    const circumference = circumferenceOfEllipse(selectedRadius, axis === 'rX' ? state?.rX ?? 0.01 : state?.rY ?? 0.01);
    setCircumference(parseFloat(circumference.toFixed(TO_FIXED)));
    updateRadius(ValidationAction.Canopy, axis, selectedRadius);
  };
  return (
    <AttributeCard
      title='Canopy Size'
      color={SemanticActionsColorMap[ValidationAction.Canopy]}
      isExpanded={visibility}
      onExpand={setVisibility}
      setCompleted={setIsCanopyCompleted}
      disabled={disabled}
      completed={isCanopyCompleted}
      isActive={activeTool === ValidationAction.Canopy}
      setIsActive={() => {
        resetSectionTargetToDefault();
        setDefaultDepth();
        setActiveTool(ValidationAction.Canopy);
      }}
    >
      <div style={{ display: 'flex' }}>
        <p style={{ flexGrow: 1 }}>Circumference</p>
        <span className='number-input'>
          <PlainNumberInput
            value={parseFloat(circumference.toFixed(TO_FIXED))}
            step={0.1}
            onChange={(value: number) => {
              if (disabled) return;
              const radius = Math.max(radiusFromCircumference(value), 0.01);
              setCircumference(parseFloat(value.toFixed(TO_FIXED)));
              updateRadius(ValidationAction.Canopy, 'rX', radius);
              updateRadius(ValidationAction.Canopy, 'rY', radius);
            }}
            min={0.1}
          />
          <span>{' m'}</span>
        </span>
      </div>
      <div style={{ display: 'flex' }}>
        <p style={{ flexGrow: 1 }}>Diameter A</p>
        <span className='number-input'>
          <PlainNumberInput
            value={parseFloat(diameterA.toFixed(TO_FIXED))}
            step={0.1}
            onChange={(diameter: number) => {
              if (disabled) return;
              const correctedDiameter = Math.max(diameter, 0.01);
              setCircumferenceByDiameter(correctedDiameter, 'rX');
            }}
            min={0.01}
          />
          <span>{' m'}</span>
        </span>
      </div>
      <div style={{ display: 'flex' }}>
        <p style={{ flexGrow: 1 }}>Diameter B</p>
        <span className='number-input'>
          <PlainNumberInput
            value={parseFloat(diameterB.toFixed(TO_FIXED))}
            step={0.1}
            onChange={(diameter: number) => {
              if (disabled) return;
              const correctedDiameter = Math.max(diameter, 0.01);
              setCircumferenceByDiameter(correctedDiameter, 'rY');
            }}
            min={0.01}
          />
          <span>{' m'}</span>
        </span>
      </div>
    </AttributeCard>
  );
};

export default CanopyCard;
