import { useEffect } from "react";
import useStore from "../../../store/useStore";

const usePanorama = () => {
  const tree = useStore((s) => s.tree.tree);
  const viewPanorama = useStore((s) => s.panorama.actions.viewPanorama);
  const isPanoramic = useStore((s) => s.panorama.isPanoramic);

  useEffect(() => {
    if (!isPanoramic) return;
    if (tree?.id) viewPanorama(tree);
  }, [viewPanorama, isPanoramic, tree?.id]);
};

export default usePanorama;
