import { useMemo } from 'react';
import { Vector3 } from 'three';
import ValidationAction from '../../../enums/ValidationAction';
import { Girth } from '../../../store/TreeSlice';
import useStore from '../../../store/useStore';
import SectionControl from '../../Slider/SectionControl';

const SectionToolbar = ({ disabled = false }) => {
  const { depth, target } = useStore((s) => s.pointCloud.section);
  const { setSectionDepth } = useStore((s) => s.pointCloud.actions.section);
  const { setTarget } = useStore((s) => s.pointCloud.actions.section);
  const updateHeight = useStore((s) => s.actions.updateHeight);
  const activeGirth = useStore((s) => s.actions.girth.state);
  const updateGirth = useStore((s) => s.tree.actions.trunkActions.updateGirth);
  const viewerPositionZ = useStore((s) => s.tree.viewerPosition.z);

  const { isGirthActive, isFirstBifActive, isLeanAngleActive } = useStore((s) => ({
    isGirthActive: s.actions.activeTool === ValidationAction.Girth,
    isFirstBifActive: s.actions.activeTool === ValidationAction.FirstBifurcationPoint,
    isLeanAngleActive: s.actions.activeTool === ValidationAction.LeaningVector,
  }));

  const isHeightDisabled = useMemo(() => {
    if (isGirthActive) return false;
    if (!isGirthActive && !isLeanAngleActive && !isFirstBifActive) return false;
    return true;
  }, [isFirstBifActive, isGirthActive, isLeanAngleActive]);

  const updateGirthHeight = (height: number) => {
    if (!activeGirth) return;
    const updatedGirth = updateHeight(ValidationAction.Girth, height);
    updateGirth(updatedGirth as Girth);
  };

  const handleHeightUpdate = (height: number) => {
    const correctedHeight = height + viewerPositionZ;
    setTarget(new Vector3().set(target?.x ?? 0, target?.y ?? 0, correctedHeight));

    if (isGirthActive) {
      updateGirthHeight(height);
    }
  };

  return (
    <SectionControl
      height={target?.z! - viewerPositionZ ?? 0}
      depth={depth}
      setHeight={handleHeightUpdate}
      setDepth={setSectionDepth}
      disabled={{ height: disabled || isHeightDisabled, depth: disabled }}
    />
  );
};

export default SectionToolbar;
