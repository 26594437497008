import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import proj4 from 'proj4';
import { useHistory } from 'react-router';
import * as THREE from 'three';
import { Finished, Map, Panorama, ProgressControls, TreeMover } from '../../components';
import { validationActions } from '../../core/progressActions';
import { usePanoramaChangeOnMap } from '../../hooks';
import useApi from '../../hooks/api';
import { LoaderWrapper } from '../../layout';
import { useConfig } from '../../providers/config';
import { useModal } from '../../providers/modal';
import { useTheme } from '../../providers/theme';
import { useTrees } from '../../providers/data';
import LASLoader from '../../utils/LASLoader';
import { computeDistanceBetween } from 'spherical-geometry-js';
import { useBetaTreeIndex } from '../../hooks/betaHooks/beta-use-tree-index';
import { CommentTypes, useBetaTree } from '../../hooks/betaHooks/beta-use-tree';
import { useBetaManagedAreaContext } from '../../hooks/betaHooks/beta-managed-area-context';
import TreeStatus from '../../enums/TreeStatus';
import { TreeActions, useBetaTreeHistory } from '../../hooks/betaHooks/beta-use-tree-history';
import { clone } from 'lodash';
import { Vector3 } from 'three';
import TreeFlowStatus from '../../enums/TreeFlowStatus';

const CLOSEBY_TREE_THRESHOLD_IN_METERS = 5.1;

// Creating las loader
const loadingManager = new THREE.LoadingManager();
const loader = new LASLoader(loadingManager);

// Generate url
const generateUrl = (id) => window.location.pathname.split('/').slice(0, 4).join('/') + '/' + id;

const LocationValidation = ({
  selection = [],
  match,
  trees,
  updateTree,
  onTreeAdd,
  loaded,
  startPostValidation = () => {},
  setCurrentTreeId,
  layerSources,
  sortByProximityFirst,
  proximityAlertTreeIDs,
  updateTreeTmsCategory,
  treeId,
  managedArea,
  setTree,
  contextsLoadingState,
  reFetchManagedAreaAndTrees,
  // currentTree
}) => {
  const betaTree = useBetaTree({
    treeId: match.params?.tree,
    managedAreaId: managedArea?.id,
    managedAreaCode: managedArea?.code,
  });

  const betaTreeHistory = useBetaTreeHistory(async (action) => {
    const tid = clone(betaTree.tree?.id);

    if (action.action === TreeActions.UPDATE) {
      await betaTree.updateTreeWithId(action.treeId, action.payload?.prev);
    }

    if (action.action === TreeActions.COMMENT) {
      await betaTree.removeComment(action.payload);
    }

    if (action.action === TreeActions.REMOVE_COMMENT) {
      await betaTree.addCommentWithTreeId(
        {
          ...action.payload,
          comment: action.payload.value,
        },
        action.treeId
      );
    }

    await betaTree.fetch();

    history.push(`/validation/${managedArea.id}/location/${action.treeId}`);
  });

  const betaManagedAreaContext = useBetaManagedAreaContext();

  const currentTree = betaTree?.tree;

  const { getConfig } = useConfig();
  const { requestHeader, getUrl } = useApi();

  const history = useHistory();

  const [finished, setFinished] = useState(false);

  // Position of the tree
  const [position, setPosition] = useState({ hasChanged: false, coords: [] });
  const [previousPosition, setPreviousPosition] = useState(null);

  // Las data for pointcloud
  const [las, setLas] = useState(null);
  const [lasError, setLasError] = useState(null);

  const [panoramicLoading, setPanoramicLoading] = useState(false);

  // Panoramic view config
  const [panoramaImages, setPanoramaImages] = useState([]);
  const [isFlyingModeActive, setIsFlyingModeActive] = useState(false);

  const { needReload, setNeedReload } = useTrees(selection[0], false, '', true);

  // Using theming
  const { isDark } = useTheme();

  // Getting modal methods
  const { presentModal, dismissModal } = useModal();

  const { selectTree, moveToNeighbour, relevantTrees } = useBetaTreeIndex({
    trees,
    selectedTreeId: treeId,
    todoStatuses: [TreeFlowStatus.LocationValidationQueued],
    setTree: (tree) => history.push(`/validation/${managedArea.id}/location/${tree.id}`),
    proximityAlertTreeIDs,
    sortByProximityFirst,
  });

  const closeByTrees = useMemo(() => {
    if (!currentTree?.id) return undefined;
    if (!currentTree?.location) return undefined;
    const currentTreeLocation = {
      lat: JSON.parse(currentTree?.location)?.coordinates[1],
      lng: JSON.parse(currentTree?.location)?.coordinates[0],
    };

    return trees.filter((tree) => {
      if (JSON.parse(tree?.location)?.coordinates == null) {
        return false;
      }
      const treeLocation = { lat: JSON.parse(tree?.location)?.coordinates[1], lng: JSON.parse(tree?.location)?.coordinates[0] };
      const distance = computeDistanceBetween(currentTreeLocation, treeLocation);
      const isTreeCloseBy = distance <= CLOSEBY_TREE_THRESHOLD_IN_METERS;
      const isTreeSelected = currentTree && currentTree.id === tree.id;
      return isTreeCloseBy && !isTreeSelected;
    });
  }, [relevantTrees, currentTree?.id]);

  const onPositionChangeStartedHandler = () => {
    setPreviousPosition(position);
  };

  const onPositionChangeHandler = (pos) =>
    setPosition({
      hasChanged: true,
      coords: pos,
    });

  const onPositionChangeDoneHandler = (pos) => {
    const newPosition = {
      hasChanged: true,
      coords: pos,
    };

    setPosition(newPosition);
  };

  // Loading the las file
  const _handleLasLoad = () => {
    setLas(null);
    setLasError(null);
    const currentId = currentTree?.id;

    // const url = getUrl(`/v1/proxy/blob?url=tasks/${selection[0].code}/tree_location_validation_clips/${currentTree?.id}.laz`);
    const url = getUrl(`/v1/proxy_v2/tree-data?url=${currentTree.tree_location_validation_laz}`);

    loader.setRequestHeader(requestHeader);
    loader.load(
      url,
      (pointcloud) => {
        if (currentId !== currentTree?.id) return;
        setLas(pointcloud);
        setLasError(false);
      },
      () => void 0,
      () => {
        setLasError(true);
      }
    );
  };

  // Loading panorama config
  // const _handlePanorama = async () => {
  //   const panoramaImages = await getCapturePoint(currentTree);
  //   setPanoramaImages(panoramaImages);
  //   setCapturePoint({
  //     position: { coordinates: panoramaImages?.[0]?.origin.coordinates },
  //   });
  // };

  useEffect(() => {
    if (needReload) setNeedReload(false);
  }, [needReload, setNeedReload]);

  // Handling state update based on selected tree and MA data
  useEffect(() => {
    if (currentTree?.id) setCurrentTreeId(currentTree?.id);

    if (!selection[0]?.code) return;

    // window.history.pushState(null, null, generateUrl(currentTree?.id));

    if (currentTree?.id) _handleLasLoad();

    if (currentTree?.location) {
      const location = JSON.parse(currentTree.location)?.coordinates.slice(0);
      let position = proj4('EPSG:4326', 'localProjection', location || []);

      if (currentTree.location_local !== undefined) {
        position = currentTree.location_local;
      }

      setPosition({
        hasChanged: currentTree?.manual_treelocation_changes,
        coords: position,
      });
    }

    // Panorama
    // if (currentTree?.id) _handlePanorama();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTree?.id, selection[0]?.code]);

  const _handleSkip =
    (direction = 1) =>
    () => {
      const { index, nextIndex } = moveToNeighbour(direction);
    };

  const _handleTSEJob = async () => {
    await betaManagedAreaContext.startPostValidation(selection[0], 'location_validation');
    setFinished(true);
  };

  const _handleTreeAdd = useCallback(
    async (e) => {
      let p1 = proj4('localProjection', 'EPSG:4326', e.toArray());

      const newTree = await betaTree.addTree(
        p1[0],
        p1[1],
        betaManagedAreaContext.managedArea?.code,
        betaManagedAreaContext.managedArea?.id,
        betaManagedAreaContext.pipeline?.pipeline_id
      );
      setCurrentTreeId(newTree?.id);
      history.push(generateUrl('new/' + newTree?.id));
    },
    [
      history,
      onTreeAdd,
      selection,
      setCurrentTreeId,
      betaManagedAreaContext.managedArea?.code,
      betaManagedAreaContext.managedArea?.id,
      betaManagedAreaContext.pipeline?.id,
    ]
  );

  // --- minimap handling
  const mapRef = useRef(null);

  const maLayer = {
    id: 'mas',
    source: 'mas',
    type: 'fill',
    color: '#082',
    opacity: 0.32,
  };

  const treeLayers = [
    {
      id: TreeFlowStatus.LocationValidationQueued,
      source: 'trees',
      type: 'circle',
      filter: ['==', ['get', 'tree_flow_status'], TreeFlowStatus.LocationValidationQueued],
      color: '#ff0000',
    },
    {
      id: TreeFlowStatus.LocationValidationDone,
      source: 'trees',
      type: 'circle',
      filter: ['==', ['get', 'tree_flow_status'], TreeFlowStatus.LocationValidationDone],
      color: '#AFFF14',
    },
    {
      id: TreeFlowStatus.LocationValidationDeleted,
      source: 'trees',
      type: 'circle',
      filter: ['==', ['get', 'tree_flow_status'], TreeFlowStatus.LocationValidationDeleted],
      color: '#666',
    },
    {
      id: TreeFlowStatus.SentToField,
      source: 'trees',
      type: 'circle',
      filter: ['==', ['get', 'tree_flow_status'], TreeFlowStatus.SentToField],
      color: '#ffff00',
    },
  ].map((layer) => {
    const config = getConfig(`statuses.${layer.id}`);
    return {
      ...layer,
      onClick: (centre, feature, id) => {
        selectTree(feature.id);
      },
      label: config?.label || 'Unnamed',
      color: getConfig(`colors.${config?.color}`) || '#08f',
    };
  });

  const layers = [maLayer, ...treeLayers];
  // --- minimap handling

  const flyingModeProps = useMemo(() => {
    return {
      active: isFlyingModeActive,
      action: () => {
        setIsFlyingModeActive((prevState) => !prevState);
      },
    };
  }, [isFlyingModeActive]);

  if (!managedArea?.id) return null;
  if (!betaManagedAreaContext?.managedArea?.id || !betaManagedAreaContext?.pipeline?.id) return null;

  const isLoading = () => {
    return !currentTree?.location || betaTree?.loading || contextsLoadingState || panoramicLoading;
  };

  const actualTreePosition = currentTree?.location_local ? position?.coords : null;

  return (
    <LoaderWrapper loading={isLoading()}>
      <Finished 
        finished={relevantTrees?.length === 0} 
        onStart={_handleTSEJob} 
        done={finished}
        managedAreaId={managedArea.id}
        reloadManagedAreas={reFetchManagedAreaAndTrees}
      >
        <div className='location-validation-wrapper'>
          <div className='viewers'>
            <div className='panorama-view-wrapper'>
              {!!currentTree?.location && (
                <Panorama
                  onTreeChange={selectTree}
                  treeId={currentTree?.id}
                  managedAreaCode={selection[0]?.code}
                  optionalPosition={actualTreePosition ? new Vector3().fromArray(actualTreePosition) : null}
                  mapSources={layerSources}
                  mapLayers={layers}
                  mapRef={mapRef}
                  enabledMiniMap={true}
                  enableGoogleMapsButton={true}
                  enableAddTree={true}
                  onAddTree={_handleTreeAdd}
                  /*
                  selTree={
                      !!currentTree && {
                          ...currentTree,
                          location_local: currentTree.location_local ? position.coords : undefined,
                          geometry: {
                              ...currentTree?.geometry,
                              coordinates: position.coords,
                          },
                      }
                  }
                  pointcloud={las}
                  background={isDark ? 0x000000 : 0xf8f8f8}
                  capturePoint={capturePoint}
                  images={panoramaImages}
                  cameras={panoramaImages}
                  blockLoader={!loaded}
                  getConfig={getConfig}
                  setLoading={setPanoramicLoading}
                  onPositionChangeStarted={onPositionChangeStartedHandler}
                  onPositionChange={onPositionChangeHandler}
                  onPositionChangeDone={onPositionChangeDoneHandler}
                  enableTransformControls={true}
                  onViewChange={handlePanoramicViewChange}
                  loadMavens={false}
                  enableAddTree={true}
                  onAdd={_handleTreeAdd}
                  flyingModeButton={FlyingModeButton}
                  flyingModeProps={flyingModeProps}
                  selectedMA={selection[0]}
                  onTreeClick={selectTree}
                   */
                />
              )}
              {/*<Map
                minimap
                active={{
                  location_validation_todo: currentTree?.id,
                  location_validation_done: currentTree?.id,
                  mas: managedArea.id,
                }}
                _ref={mapRef}
                cameraPosition={cameraPosition}
                sources={layerSources}
                layers={layers}
                isMiniMapHiddenButton={true}
                needReload={needReload}
              />*/}
            </div>
            {!isFlyingModeActive && (
              <div className='vertical-stack'>
                <TreeMover
                  treeLoaded={loaded}
                  pointcloud={las}
                  error={lasError}
                  background={isDark ? 0x000000 : 0xf8f8f8}
                  position={position.coords}
                  closeByTrees={closeByTrees}
                  onPositionChangeStarted={onPositionChangeStartedHandler}
                  onPositionChange={onPositionChangeHandler}
                  onPositionChangeDone={onPositionChangeDoneHandler}
                />
              </div>
            )}
          </div>
          <ProgressControls
            max={trees?.length}
            value={trees?.length - relevantTrees?.length}
            actions={validationActions({
              onUndo: () => betaTreeHistory.undo(),
              isUndoAvailable: betaTreeHistory.isUndoAvailable,
              tree: currentTree,
              markAsDone: async (args, status) => {
                const oldTree = clone(betaTree.tree);

                await betaTree.markAsDoneLocation({
                  status: args.status,
                  location: position.coords,
                  manual_treelocation_changes: !!position.hasChanged,
                });

                betaTreeHistory.add(betaTree?.tree?.id, TreeActions.UPDATE, {
                  current: {
                    status: args.status,
                    location: position.coords,
                    manual_treelocation_changes: !!position.hasChanged,
                  },
                  prev: {
                    status: oldTree.status,
                    location: oldTree.location_local,
                    manual_treelocation_changes: false,
                  },
                });

                await betaManagedAreaContext.fetchTrees();
                moveToNeighbour();
              },
              commentTree: async (_, comment) => {
                const cResult = await betaTree.addComment({
                  comment: comment.value,
                  comment_type: CommentTypes.COMMENT,
                  isUndoAction: false,
                });

                await betaTreeHistory.add(betaTree?.tree?.id, TreeActions.COMMENT, cResult?.[0]?.id);
              },
              removeTreeComment: async (cid, comment) => {
                await betaTree.removeComment(cid);
                await betaTreeHistory.add(betaTree?.tree?.id, TreeActions.REMOVE_COMMENT, comment);
              },
              removeTree: async (args, status) => {
                const oldTree = clone(betaTree.tree);

                await betaTree.deleteTreeLocation();

                betaTreeHistory.add(betaTree?.tree?.id, TreeActions.UPDATE, {
                  current: {
                    status: TreeStatus.LocationValidationDeleted,
                  },
                  prev: {
                    status: oldTree.status,
                  },
                });

                await betaManagedAreaContext.fetch();
                moveToNeighbour();
              },
              sendToField: (...args) => alert('Unimplemented feature'),
              onSkip: _handleSkip,
              numberOfTrees: relevantTrees?.length,
              dismissModal,
              presentModal,
              showComment: true,
              // disabled: panoramicLoading || lasError === null,
              disabled: lasError === null,
              onSetTmsCategory: async (cat) => {
                const oldTree = clone(betaTree.tree);

                await betaTree.updateTree({
                  status: TreeStatus.LocationValidationSentToField,
                  tree_flow_status: TreeFlowStatus.SentToField,
                  tms_category: cat,
                });

                betaTreeHistory.add(betaTree?.tree?.id, TreeActions.UPDATE, {
                  current: {
                    status: TreeStatus.LocationValidationSentToField,
                    tree_flow_status: TreeFlowStatus.SentToField,
                    tms_category: cat,
                  },
                  prev: {
                    status: oldTree.status,
                    tree_flow_status: oldTree.status,
                    tms_category: oldTree.tms_category,
                  },
                });

                await betaManagedAreaContext.fetchTrees();
                moveToNeighbour();
              },
              showTmsButtons: false,
              showTmsL3Button: true,
              showNavigationArrows: false,
              isLocationVal: true,
            })}
          />
        </div>
      </Finished>
    </LoaderWrapper>
  );
};

export default LocationValidation;
